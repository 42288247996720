import React from "react";
import {dateFormatOptions} from "../../common/dateFormatterConfig";
import Pagination from "rc-pagination";
import {locale} from "../../common/table/paginatorConfig";
import { SortIcons } from "components/SortIcons/SortIcons";
import { IPageResponse, SortDirection } from "common/interfaces/IPageResponse";
import { IElectricityInvoice, IElectricityInvoiceSortFields } from "./models";
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";

interface IElectricInvoiceTableProps {
  electricityInvoices: IPageResponse<IElectricityInvoice>;
  onPageChange: (page: number, size: number) => void;
  onSort: (sortField: IElectricityInvoiceSortFields) => void;
  sortField: IElectricityInvoiceSortFields;
  sortDirection: SortDirection;
  getPdfPreview: (invoice: IElectricityInvoice) => void;
  pdfPreviewLoading?: number
}

export function ElectricityInvoicesTable(props: IElectricInvoiceTableProps) {
    const electricityInvoices: Array<IElectricityInvoice> = props.electricityInvoices.content;
    const pageResponse = props.electricityInvoices;

    const electricityInvoiceTableRows = electricityInvoices.map((electricityInvoice) =>
        <tr key={'row-' + electricityInvoice.id}>
            <td className="line-height-1">
                {electricityInvoice.customer.name}<br />
                <small className="text-dark">{electricityInvoice.customer.eic}</small>
            </td>
            <td className="text-nowrap">
                {electricityInvoice.invoiceNumber}
            </td>
            <td>
                {electricityInvoice.dateIssued ? new Intl.DateTimeFormat('et', dateFormatOptions).format(new Date(electricityInvoice.dateIssued)) : ""}
            </td>
            <td>
                {electricityInvoice.dateDue ? new Intl.DateTimeFormat('et', dateFormatOptions).format(new Date(electricityInvoice.dateDue)) : ""}
            </td>
            <td className="text-nowrap">
                {electricityInvoice.amountIncludingVat}
            </td>     
            <td className="text-nowrap">
                {electricityInvoice.amountExcludingVat}
            </td>
            <ProsumptionCell 
              primary={electricityInvoice.totalConsumption}
              secondary={electricityInvoice.totalProduction}
            />
            <ProsumptionCell 
              primary={electricityInvoice.consumptionAveragePrice}
              secondary={electricityInvoice.productionAveragePrice}
            />
            <td className="text-nowrap">
                {electricityInvoice.agreement ? electricityInvoice.agreement.contractNumber : electricityInvoice.invoiceGroup?.name}
            </td>
            <td>
                <div className="row__actions">
                    <OverlayTrigger delay={250}
                        overlay={
                            <Tooltip id={`tooltip-${electricityInvoice.id}`}>
                                <p style={{margin: '3px'}}>Vaata PDF faili</p>
                            </Tooltip>} placement={"left"}>
                        <Button 
                            size="sm"
                            variant="secondary"
                            className="d-flex align-items-center btn-outline-icon ml-1" 
                            onClick={() => props.getPdfPreview(electricityInvoice)}
                        >
                            {
                                props.pdfPreviewLoading === electricityInvoice.id ? (
                                    <Spinner animation="border" size="sm"/> 
                                ) : (
                                    <div className='icon__preview-file action-icon'/>
                                )
                            }
                        </Button>
                    </OverlayTrigger>
                </div>
            </td>
        </tr>
    );

    return (
        <table className='generic-table table table-responsive-md table-hover'>
            <thead>
            <tr>
                <th>Klient</th>
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(IElectricityInvoiceSortFields.INVOICE_NUMBER)}>
                        <span>Arve number</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={IElectricityInvoiceSortFields.INVOICE_NUMBER} />
                    </div>
                </th>
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(IElectricityInvoiceSortFields.DATE_ISSUED)}>
                        <span>Kuupäev</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={IElectricityInvoiceSortFields.DATE_ISSUED} />
                    </div>
                </th>
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(IElectricityInvoiceSortFields.DATE_DUE)}>
                        <span>Maksetähtaeg</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={IElectricityInvoiceSortFields.DATE_DUE} />
                    </div>
                </th>                
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(IElectricityInvoiceSortFields.AMOUNT_INCLUDING_VAT)}>
                        <span>Summa</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={IElectricityInvoiceSortFields.AMOUNT_INCLUDING_VAT} />
                    </div>
                </th>
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(IElectricityInvoiceSortFields.AMOUNT_EXCLUDING_VAT)}>
                        <span>Summa KM-ta</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={IElectricityInvoiceSortFields.AMOUNT_EXCLUDING_VAT} />
                    </div>
                </th>
                <th>Kogus</th>
                <th>Keskmine ühikhind</th>
                <th>Leping / Grupp</th>
            </tr>
            </thead>
            <tbody>
              {electricityInvoiceTableRows}
            </tbody>
            <tfoot>
            <tr>
                <td colSpan={9}>
                    <div className="pagination-wrapper">
                        <Pagination
                            locale={locale}
                            current={pageResponse.number + 1}
                            total={pageResponse.totalElements}
                            pageSize={pageResponse.size}
                            prefixCls={"pagination"}
                            onChange={props.onPageChange}
                            showSizeChanger={false}
                            hideOnSinglePage={true}
                        />
                    </div>
                </td>
            </tr>
            </tfoot>
        </table>
    )
}

function ProsumptionCell({
  primary, 
  secondary
}: {primary: number, secondary: number}) {

  if(!primary){
    return (
      <td>
        -
      </td>
    )
  }

  return (
    <td>
      <div className="line-height-1">
        { !!secondary && 'Tarbimine:'} { primary }
      </div>
      { !!secondary && (
        <div className="line-height-1 pr-1">
          Tootmine: { secondary }
        </div>
        )
      }
    </td>
  )
}
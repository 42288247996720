import React, {PureComponent} from 'react';
import {IPageResponse, SortDirection} from "../common/interfaces/IPageResponse";
import { IPageRequest } from 'common/interfaces/IPageRequest';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { ElectricityCustomersSearchBar } from 'components/ElectrcityCustomers/ElectricityCustomersSearchBar';
import { ElectricityCustomersTable } from 'components/ElectrcityCustomers/ElectricityCustomersTable';
import { ICustomer, ICustomerSortFields } from 'components/ElectrcityCustomers/model';
import CustomerService from 'services/customers.service';
import { ElectricityCustomerSettingsModal } from 'components/ElectrcityCustomers/modals/ElectricityCustomerSettingsModal';
import { CreateInvoicesModal } from 'components/ElectricityInvoices/modals/CreateInvoicesModal';
import { ISimplifiedProductionTable } from 'components/ElectricityProductionTables/models';
import { ElectricityProductionTablesService } from 'components/ElectricityProductionTables/ElectricityProductionTables.service';
import { ElectricityProductionTableModal } from 'components/ElectricityProductionTables/modals/ElectricityProductionTableModal';

export interface IElectricityCustomersState {
    pageRequest: IPageRequest<ICustomerSortFields>;
    isRequesting: boolean;
    customers?: IPageResponse<ICustomer>;
    isCustomerSettingsModalVisible?: boolean;
    isProductionTableModalVisible?: boolean;
    isCreateInvoicesModalVisible?: boolean;
    createInvoicesIsPerson?: boolean;
    activeCustomer?: ICustomer;
}

const searchApi = AwesomeDebouncePromise(
  request => CustomerService.getCustomersWithActiveAgreements(request),
  300
);

export default class ElectricityCustomers extends PureComponent<any, IElectricityCustomersState> {
    constructor(props: any) {
        super(props);
        this.state = {
            isRequesting: false,
            pageRequest: {
                page: 0,
                size: 20,
                sortDirection: SortDirection.DESC,
                sortField: ICustomerSortFields.ID,
                searchQuery: null
            }
        };
        this.props.onLoadValidatePage();
    }

    
    getCustomersWithActiveAgreements = () => {
        this.setState({
            isRequesting: true
        });
        CustomerService.getCustomersWithActiveAgreements(this.state.pageRequest).then(res => {
            this.setState({
                customers: res,
                isRequesting: false
            });
        });
    };

    handleSearchChange = (searchQuery: string) => {
      this.setState({
          customers: undefined,
          pageRequest: {
              page: 0,
              size: this.state.pageRequest.size,
              sortDirection: this.state.pageRequest.sortDirection,
              sortField: this.state.pageRequest.sortField,
              searchQuery
          }
      });
      this.searchCustomerContactPersons({
              page: 0,
              size: this.state.pageRequest.size,
              sortDirection: this.state.pageRequest.sortDirection,
              sortField: this.state.pageRequest.sortField,
              searchQuery
        });
    };

    searchCustomerContactPersons = (request: any) => {
        this.setState({
            isRequesting: true
        });
        searchApi(request).then(res => {
            this.setState({
                customers: res, 
                isRequesting: false
            });
        })
        
    }; 

    onPageChange = (targetPage: number) => {
        this.setState({
            pageRequest: {
                ...this.state.pageRequest,
                page: targetPage - 1
            }
        }, () => 
          this.getCustomersWithActiveAgreements()
        );
    };

    handleSortClick = (sortField: ICustomerSortFields) => {
      let sortDirection = SortDirection.ASC;
      if (this.state.pageRequest.sortField.match(sortField)) {
          sortDirection = this.state.pageRequest.sortDirection === SortDirection.DESC ? SortDirection.ASC : SortDirection.DESC;
      }
      this.setState({
          pageRequest: {
              ...this.state.pageRequest,
              sortDirection,
              sortField
          }
      }, () => this.onPageChange(1))
    };

    onConfigureCustomerSettings = (customer: ICustomer) => {
        this.setState({ 
            isCustomerSettingsModalVisible: true,
            activeCustomer: customer
        })
    }

    showProductionTableModalForCustomer = (customer: ICustomer) => {
        this.setState({ 
            isProductionTableModalVisible: true,
            activeCustomer: customer
        })
    }

    findProductionTablesForCustomer = (customerId: number) => {
        return ElectricityProductionTablesService.findProductionTablesForCustomer(customerId);
    }

    getProductionTableFile = (productionTable: ISimplifiedProductionTable, isCorrection: boolean) => {
        ElectricityProductionTablesService.getProductionTableFile(productionTable.id, isCorrection);
    }

    onCreateInvoices = (isPerson : boolean) => {
        this.setState({ 
            isCreateInvoicesModalVisible: true,
            createInvoicesIsPerson: isPerson
         });
    }
  
    componentDidMount(): void {
        this.getCustomersWithActiveAgreements();
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col">
                        <ElectricityCustomersSearchBar 
                          onFilter={this.handleSearchChange}
                          createInvoices={this.onCreateInvoices}
                        />
                        <ElectricityCustomersTable 
                          onPageChange={this.onPageChange}
                          isRequesting={this.state.isRequesting}
                          customers={this.state.customers}
                          onSort={this.handleSortClick}
                          sortDirection={this.state.pageRequest.sortDirection}
                          sortField={this.state.pageRequest.sortField}
                          configureCustomerSettings={this.onConfigureCustomerSettings}
                          showProductionTables={this.showProductionTableModalForCustomer}
                        />
                    </div>
                </div>
                {(this.state.isCustomerSettingsModalVisible && this.state.activeCustomer) && (
                    <ElectricityCustomerSettingsModal 
                        handleModalHide={() => {
                            this.setState({
                                ...this.state,
                                isCustomerSettingsModalVisible: false,
                                activeCustomer: undefined
                            })
                        }}  
                        customer={this.state.activeCustomer}
                        onSuccess={() => this.getCustomersWithActiveAgreements()}
                    />   
                )}
                {(this.state.isCreateInvoicesModalVisible && this.state.createInvoicesIsPerson !== undefined) && (
                    <CreateInvoicesModal
                        handleModalHide={() => {
                            this.setState({ isCreateInvoicesModalVisible: false });
                        }}
                        isPerson={this.state.createInvoicesIsPerson!}
                    />
                )}
                {(this.state.isProductionTableModalVisible && this.state.activeCustomer) && (
                    <ElectricityProductionTableModal  
                        handleModalHide={() => {
                            this.setState({
                                ...this.state,
                                isProductionTableModalVisible: false,
                                activeCustomer: undefined
                            })
                        }}  
                        activeCustomer={this.state.activeCustomer}
                        findProductionTablesForCustomer={this.findProductionTablesForCustomer}
                        getFile={this.getProductionTableFile}
                    />   
                )}
            </div>
        );
    }
}
import axios from "axios";
import { extractData, handleResponse } from "helpers/apiResponseHelper";
import { Company } from "models/company";
import { apiService } from "./api.service";


async function getByRegistryNumber(registryNumber: string): Promise<Company> {
    return axios.get(apiService.getApiUrl() + '/companies/registry-code/' + registryNumber)
        .then(handleResponse).then(extractData);
}

const CompanyService = {
    getByRegistryNumber
};

export default CompanyService;

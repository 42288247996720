import React from 'react';
import Pagination from "rc-pagination";
import {dateFormatOptions} from "../../common/dateFormatterConfig";
import {locale} from "../../common/table/paginatorConfig";
import {IUser} from "./models";
import {Button, OverlayTrigger, Tooltip, Spinner} from "react-bootstrap";
import { IPageResponse } from 'common/interfaces/IPageResponse';

export interface IUsersTableProps {
    users: IPageResponse<IUser> | null;
    isRequesting: boolean;
    openEditModal: (user: IUser) => void;
    openRemoveModal: (user: IUser) => void;
    onPageChange: (targetPage: number) => void;
}

export function UsersTable(props: IUsersTableProps) {
    const users: Array<IUser> = props.users ? props.users.content : []; 
    const pageResponse = props.users;
    const tableRows = users.map((user: IUser) =>
        <tr key={'row-' + user.id}>
            <td>
                {user.name}
            </td>
            <td>
                {user.email}
            </td>
            <td>
                {user.dateModified ? new Intl.DateTimeFormat('et', dateFormatOptions).format(new Date(user.dateModified)) : ""}
            </td>
            <td className="users__status">
                { user.adminActive === true &&
                <div className="active-access-status">
                    Aktiivne
                </div>
                }
                { user.adminActive !== true &&
                <div className='disabled-access-status'>
                    Peatatud
                </div>
                }

            </td>
            <td>
                <div className="row__actions">
                    <OverlayTrigger delay={250}
                                    overlay={
                                        <Tooltip id={`tooltip-${user.id}`}>
                                            <p style={{margin: '3px'}}>Muuda</p>
                                        </Tooltip>} placement={"left"}>
                        <Button size="sm" variant="outline-dark"
                                className="d-flex align-items-center border-color-background border-radius-circle btn-outline-icon"
                                onClick={(e: any) => {
                                    props.openEditModal(user);
                                }}>
                            <i className="icon--16 my-2 icon__edit--outline-mask"/>
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger delay={250}
                                    overlay={
                                        <Tooltip id={`tooltip-${user.id}`}>
                                            <p style={{margin: '3px'}}>Eemalda</p>
                                        </Tooltip>} placement={"left"}>
                        <Button size="sm" variant="outline-danger"
                                className="d-flex align-items-center border-color-background border-radius-circle btn-outline-icon"
                                onClick={(e: any) => {
                                    props.openRemoveModal(user);
                                }}
                                >
                            <i className="icon--16 icon__remove-user"/>
                        </Button>
                    </OverlayTrigger>
                </div>
            </td>
        </tr>
    );

    return (
        <table className='users__table table table-responsive-md table-hover'>
            <thead>
            <tr>
                <th>Nimi</th>
                <th>Email</th>
                <th>Viimati muudetud</th>
                <th>Halduse ligipääs</th>
                <th>Tegevused</th>
            </tr>
            </thead>
            <tbody>
            {props.isRequesting ?
                    <tr>
                    <td colSpan={5} className="spinner-cell">
                        <div className="p-4 w-100 d-flex align-items-center justify-content-center">
                        <Spinner animation="grow" />
                        </div>
                    </td>
                    </tr> :
                    tableRows
                }
            </tbody>
            {pageResponse ? <tfoot>
            <tr>
                <td colSpan={5}>
                    <div className="pagination-wrapper">
                        <Pagination
                            locale={locale}
                            current={pageResponse.number + 1}
                            total={pageResponse.totalElements}
                            pageSize={pageResponse.size}
                            prefixCls={"pagination"}
                            onChange={props.onPageChange}
                            showSizeChanger={false}
                            hideOnSinglePage={true}
                        />
                    </div>
                </td>
            </tr>
            </tfoot> : null}
        </table>
    )
}
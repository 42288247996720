import { PureComponent } from "react";
import { ADVERTISEMENT_TYPE_MAP, AdvertisementFilters, AdvertisementType } from "./models";
import React from "react";
import Select from 'react-select';
import { Button } from "react-bootstrap";

const selectStyle = {
  indicatorSeparator: () => (
      {
          backgroundColor: 'red',
          width: '50px',
      }
  ),
};

const ADVERTISEMENT_TYPES = [
  { value: AdvertisementType.FOOD, label: ADVERTISEMENT_TYPE_MAP[AdvertisementType.FOOD] },
  { value: AdvertisementType.DASHBOARD, label: ADVERTISEMENT_TYPE_MAP[AdvertisementType.DASHBOARD] },
];

const STATUS_TYPES = [
    { value: true, label: "Aktiivne" },
    { value: false, label: "Mitteaktiivne" },
  ];

interface AdvertisementsSearchBarProps {
  filters: AdvertisementFilters;
  onFilter: (filter: AdvertisementFilters) => void;
  onCreateAdvertisement: () => void
}

export class AdvertisementsSearchBar extends PureComponent<AdvertisementsSearchBarProps, any> {

    onInputChange = (inputEvent: any) => {
        this.props.onFilter({
            search: inputEvent.target.value
        });
    };

    onTypeChange = (value: any) => {
      this.props.onFilter({
          type: value?.value
      });
    }

    onStatusChange = (value: any) => {
        this.props.onFilter({
            active: value?.value
        });
      }

    render() {
        return (
            <div className="bar-position">
                <h2 className="text-light">
                    Reklaamid
                </h2>
                <div className="card mb-5 shadow">
                    <div className="card-body">
                        <div className="d-flex flex-wrap flex-sm-nowrap">
                            <input
                                placeholder="Reklaami tekst"
                                className='search-bar__input p-2'
                                onChange={this.onInputChange}
                                value={this.props.filters.search || ''}
                            />
                             <Select
                                style={selectStyle}
                                className='mw-192-px mr-2 customer-status__select'
                                value={ADVERTISEMENT_TYPES.find(s => s.value === this.props.filters.type) || null}
                                onChange={this.onTypeChange}
                                options={ADVERTISEMENT_TYPES}
                                isClearable
                                placeholder='Reklaami tüüp'
                            />
                            <Select
                                style={selectStyle}
                                className='mw-192-px mr-2 customer-status__select'
                                value={STATUS_TYPES.find(s => s.value === this.props.filters.active) || null}
                                onChange={this.onStatusChange}
                                options={STATUS_TYPES}
                                isClearable
                                placeholder='Staatus'
                            />
                             <div className="d-flex flex-nowrap ml-auto">
                                <Button className="ml-2 d-flex align-items-center"
                                        onClick={() => this.props.onCreateAdvertisement()}
                                        variant={"primary"}>
                                    <i className="icon--16 icon__add d-flex mr-1"/>
                                    <span>Uus reklaam</span>
                                </Button>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        )
    }
}
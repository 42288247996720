import React, {useState} from 'react';
import {Button, Form, Modal, OverlayTrigger, Spinner, Tooltip} from "react-bootstrap";
import AsyncSelect from 'react-select/async';
import { UsersService } from '../Users.service';
import { AddUsersConfirmModal } from './AddUsersConfirmModal';
import { IUser } from '../models';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { OptionTypeBase } from 'react-select';

interface AddUsersModalProps {
    rejectCloseHandler: () => void;
    submitCloseHandler: () => void;
}

const searchApi = AwesomeDebouncePromise(
    request => UsersService.searchNonAdminUsers(request),
    300
  );

export function AddUsersModal(props: AddUsersModalProps) {
    const [saving, setSaving]: [boolean, any] = useState(false);
    const [savingError, setSavingError]: [boolean, any] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible]: [boolean, any] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);

    const validateSubmit = () => {
        if(!selectedUsers || !selectedUsers.length){
            setSaving(false)
        }else{
            setIsConfirmModalVisible(true);
        }
    }

    const handleSubmit = async () => {
        setSaving(true);
        setSavingError(false);

        try{
            await UsersService.postAdminUsers(
                selectedUsers.map(user => user.id)
            );
            setSaving(false);
            setTimeout(() => {
                props.submitCloseHandler();
            }, 300);
        }catch(e){
            console.error('Saving failed', e);
            setSaving(false);
            setSavingError(true);
        }
    };

    const getUsers = async (searchTerm: string, callback: Function) => {
        if (!searchTerm || searchTerm.length < 3) {
          return [];
        }

        const users = await searchApi(searchTerm);
    
        const duplicateUsersRemoved = users.filter(
            user => !selectedUsers.some(selectedUser => selectedUser.id === user.id)
        )
    
        callback(
            duplicateUsersRemoved.map(user => ({ label: user.personalIdentificationCode, value: user }))
        )
    }

    const removeUser = (id: number) => {
        setSelectedUsers(
            selectedUsers.filter(user => user.id !== id)
        )
    }

    return (
        <Modal
            show={true}
            size={"sm"}
            onHide={props.rejectCloseHandler}
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    Kasutajate lisamine
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={(e: any) => e.preventDefault()}
                      id={"user-form"} autoComplete="new-password"
                      className={'mt-1 row align-self-center m-0'}>
                    <Form.Group controlId={'form-username'} className="w-100">
                        <AsyncSelect 
                            loadOptions={getUsers}
                            noOptionsMessage={() => "Valikud puuduvad"}
                            isClearable
                            onChange={(e: any) => setSelectedUsers([
                                ...selectedUsers,
                                e.value
                            ])}
                            loadingMessage={() => "..."}
                            placeholder={"Otsi kasutajaid..."}
                            value={null}
                            components={{Option: UserSelectOption}}
                        /> 
                    </Form.Group>
                </Form>
                {selectedUsers.map((user, index) => {
                    return (
                        <div key={user.id} className='selected-customer'>
                            <div className='row d-flex align-items-center'>
                                <div className='col-1'>
                                    {index + 1}
                                </div>
                                <div className='col-5'>
                                    {user.name}
                                </div>
                                <div className='col'>
                                    {user.personalIdentificationCode}
                                </div>
                                <OverlayTrigger
                                    delay={250}
                                    overlay={
                                        <Tooltip id={`remove-user-tooltip-${user.id}`}>
                                            <p style={{margin: '3px'}}>Eemalda kasutaja</p>
                                        </Tooltip>
                                    } 
                                    placement={"left"}
                                >
                                    <Button 
                                        size="sm"                             
                                        variant="secondary"
                                        className="d-flex align-items-center btn-outline-icon mr-2" 
                                        onClick={() => removeUser(user.id)}
                                        >
                                            <i className="icon--16 my-2 icon__delete--outline-mask"/>
                                    </Button>
                                </OverlayTrigger>
                            </div>
                            {
                                index !== selectedUsers.length - 1 ? (
                                    <div className='selected-customer__divider'/>
                                ) : null
                            }
                        </div>
                    )
                })}
            </Modal.Body>

            <Modal.Footer className="d-flex flex-row align-items-end flex-wrap mb-1">
                <Button variant="info"
                        onClick={() => props.rejectCloseHandler()}
                        type="button"
                        className={"d-flex align-items-center"}>
                    <span className='px-2'>Tühista</span>
                </Button>
                <Button 
                    variant="primary" 
                    type="button" 
                    onClick={validateSubmit} 
                    disabled={saving || !selectedUsers.length}
                    className="d-flex align-items-center"
                >
                    {
                        !saving &&
                        <span className='px-2'>Salvesta</span>
                    }
                    {
                        saving &&
                        <>
                            <span className='px-2'>Salvestab…</span>
                            <Spinner size={"sm"} animation={"border"}/>
                        </>
                    }
                </Button>
            </Modal.Footer>
            {
                !saving && savingError &&
                <span className="d-flex justify-content-end text-danger px-2 pb-2">
                    Viga salvestamisel, proovi mõne hetke pärast uuesti.
                </span>
            }
            {
                isConfirmModalVisible &&
                    <AddUsersConfirmModal
                        handleConfirmSubmit={() => {
                            setIsConfirmModalVisible(false);
                            handleSubmit();
                        }}
                        handleConfirmReject={() => {
                            setSaving(false);
                            setIsConfirmModalVisible(false);
                        }}
                    />
            }
        </Modal>
    );
}

function UserSelectOption(props: OptionTypeBase){

    return (
      <div 
        className='selected-customer__option'
        onClick={() => props.setValue(props.data)}
      >
        <div>{props.label}</div>
        {
          props.value.name ? (
            <div>{props.value.name}</div>
          ) : null
        }
      </div>
    )
  }
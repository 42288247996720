import React from 'react'
import { NavLink } from 'react-router-dom';

import { Pages } from 'pages/pages';

export function MobileMenu(props: { isOpen: boolean, action: any , handleLogout: () => void }) {
  return (
    <div
      className={props.isOpen ? 'mobile-menu open ' : 'mobile-menu '}
      onClick={props.action}
    >
      <div className='d-flex justify-content-end p-3 mb-5'>
        <button
          type='button'
          className='d-flex align-items-center my-2 btn btn-light'
        >
          <i className='icon__close--masked' />
        </button>
      </div>
      <div className='d-flex flex-column justify-content-between px-5 mb-5'>
        <ul className='nav d-flex flex-column align-items-center my-5 mx-5'>
          {Pages.map((page) => page.showInNav
            ?
            <li key={page.title} className='mb-3'>
              <NavLink
                to={`/${page.title}`}
                className='text-capitalize text-decoration-none'
                activeClassName='active'
                >
                {page.title}
              </NavLink>
            </li>
            : '')
          }
        </ul>
        <div className='d-flex justify-content-center flex-wrap mb-5'>
          <button
            type='button'
            className='d-flex align-items-center mx-5 my-2 btn btn-light'
          >
            <i className='icon--16 icon__profile' />
            <span className='ml-2'>
              Minu profiil
            </span>
          </button>
          <button
            type='button'
            className='d-flex align-items-center mx-5 my-2 btn btn-light'
            onClick={props.handleLogout}>
            <i className='icon--16 icon__log-out' />
            <span className='ml-2'>
              Logi välja
            </span>
          </button>
        </div>
      </div>
        <div className='mobile-menu__footer-illustration'/>
        <div className='mobile-menu__footer'/>
    </div>
  )
}

import { ModalProps } from "common/common-models";
import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { IElectricityAgreement } from "../../ElectricityAgreements/models";
import { toast } from "react-toastify";
import { translateCreateInvoiceError, validateAgreement } from "helpers";
import { IInvoiceCreationModel, ISimplifiedInvoice } from "../models";

interface ElectricityAgreementInvoiceModalProps extends ModalProps {
  activeAgreement: IElectricityAgreement;
  findInvoicesForAgreement: (contractNumber: string) => Promise<ISimplifiedInvoice[]>;
  createInvoiceForAgreement: (agreementId: number) => Promise<IInvoiceCreationModel[]>;
  getInvoiceFile: (invoice: ISimplifiedInvoice) => void;
}

export function ElectricityAgreementInvoiceModal(props: ElectricityAgreementInvoiceModalProps) {
    const [invoices, setInvoices] = useState<ISimplifiedInvoice[]>([]);
    const [isFetching, setIsFetching] = useState(false);
    const [isCreating, setIsCreating] = useState(false);

    useEffect(() => {
      findInvoices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const findInvoices = async () => {
      setIsFetching(true)

      try{
        const response = await props.findInvoicesForAgreement(props.activeAgreement.contractNumber);
        if(!!response.length){
          setInvoices(response)
        }
      }catch(e){
        console.error(e)
      }

      setIsFetching(false)
    }

    const createInvoice = async () => {
      setIsCreating(true)

      try{
        const response = await props.createInvoiceForAgreement(props.activeAgreement.id);
        
        if(response.length){
          const newInvoices = response.map(modal => modal.invoice).filter(inv => inv !== null);
          
          setInvoices([
            ...invoices,
            ...newInvoices
          ])
        }
      }catch(e: any){
        if (e.response && e.response.data) {
          let errorMessage = translateCreateInvoiceError(
            e.response.data.message
          );

          toast(errorMessage, {
            position: "top-right",
            type: 'error'
          })
        }
      }

      setIsCreating(false)
    }

    const customerHasGroupInvoicing = props.activeAgreement.customer.hasGroupInvoicing;
    const isDisabled = isCreating || customerHasGroupInvoicing;

    return (
      <Modal size="sm" show={true} onHide={props.handleModalHide} dialogClassName="invoice-modal">
        <Modal.Header>
            <Modal.Title>
                Arved
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {isFetching ? (
              <div className="d-flex justify-content-center align-items-center">
                <Spinner size="sm" animation="border"/>
              </div>
            ) : (
              <div className="d-flex">
                {
                  !invoices.length ? (
                    <div>Arved puuduvad...</div>
                  ) : 
                  (
                    <table className="w-100">
                      <thead>
                        <tr>
                          <th>Arve nr</th>
                          <th>Väljaandmise kuupäev</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                          {invoices.sort((a,b) => new Date(a.dateIssued).getTime() - new Date(b.dateIssued).getTime()).map((invoice) => (
                              <tr key={invoice.id}>
                                <td>{invoice.invoiceNumber}</td>
                                <td className="w-50">{invoice.dateIssued}</td>
                                <td className="d-flex justify-content-end">
                                  <Button 
                                        size="sm"
                                        variant="secondary"
                                        className="d-flex align-items-center btn-outline-icon" 
                                        onClick={() => props.getInvoiceFile(invoice)} 
                                      >
                                        <div className='icon__file action-icon'/>
                                  </Button>
                                </td>
                              </tr>
                          ))}
                        </tbody>
                      </table>
                  )
                }
            </div>
            )}
        </Modal.Body>
        <Modal.Footer>
            <div className="d-flex flex-column align-items-end">
              <div>
                {
                  validateAgreement(props.activeAgreement) && (
                    <Button
                      variant="primary"
                      onClick={() => createInvoice()}
                      type="button"
                      disabled={isDisabled}
                      style={{
                        width: "100px"
                      }}
                      className="mr-2"
                    >
                        <div>
                        {isCreating ? (
                          <Spinner size="sm" animation="border"/>
                        ) : (
                          <span>Loo arve</span>
                        )}
                        </div>
                    </Button>
                  )
                }
                <Button
                    variant="primary" 
                    onClick={props.handleModalHide}
                    type="button"
                >
                    <span className='px-3'>Sulge</span>
                </Button>
              </div>
              {
                customerHasGroupInvoicing && (
                  <span className="mt-1 d-flex justify-content-end text-warning">Kliendil on arvegrupi põhine arveldamine!</span>
                )
              }
            </div>
        </Modal.Footer>
    </Modal>      
    );
}
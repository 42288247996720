import React from "react";

interface CustomerInfoProps<T>{
  title: string;
  value: T
}

export function CustomerInfo<T>({title, value}: CustomerInfoProps<T>){
  return (
    <div className="row mb-2 d-flex align-items-center">
      <div className="font-weight-bold col-3">{title}</div> 
      <div className="col-6">{value}</div>
    </div>
  )
}
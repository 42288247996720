import { LOCAL_STORAGE_KEYS } from 'common/constants/localStorageKeys';
import React, {createContext, Dispatch, Reducer, useContext, useReducer} from 'react';
import {Redirect} from "react-router";

interface Actions {
    type: string | "setUserId" | "setUser" | "setToken" | 'setAuthenticated';
    value: any;
}

export interface IAuthProps {
    authenticated: boolean | null;
    userId: number | null;
    token: string | null;
    user: any | null;
    content: JSX.Element | null;
}

export interface InitContextProps {
    state: IAuthProps;
    dispatch: Dispatch<Actions>;
}

interface AuthProviderProps {
    reducer: Reducer<IAuthProps, Actions>;
    initialState: IAuthProps;
    currentPage: any
}

export const AuthenticationContext = createContext({} as InitContextProps);

export const AuthProvider: React.FC<AuthProviderProps> = ({reducer, initialState, children, currentPage}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = {state, dispatch};
    const isVisible = currentPage.isPublic === true ? true : (state.authenticated === true);

    if (!isVisible) {
        setTimeout(() => window.location.reload(), 50);
    }

    return (
        <AuthenticationContext.Provider value={value}>
            {isVisible === true ?
                children :
                <Redirect to={"/login"}/>
            }
        </AuthenticationContext.Provider>
    );
};
export const useAuthContext: () => any = () => useContext(AuthenticationContext);

const AuthContext: React.FC<any> = ({children, currentPage}) => {
    const localToken = localStorage.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION_HEADER);
    const initState: IAuthProps = {
        authenticated: false,
        userId: null,
        token: localToken,
        user: null,
        content: null
    };

    // TODO on restore validate and refresh token for userId

    const reducer: Reducer<IAuthProps, Actions> = (state, action) => {
        switch (action.type) {
            case 'setAuthenticated':
                return {
                    ...state,
                    authenticated: action.value
                };
            case 'setUser':
                return {
                    ...state,
                    user: action.value
                };
            case 'setUserId':
                return {
                    ...state,
                    userId: action.value
                };
            case 'setContent':
                return {
                    ...state,
                    content: action.value
                };
            case 'setToken':
                return {
                    ...state,
                    token: action.value
                };

            default:
                return state;
        }
    };
    if (localToken)
        initState.authenticated = true;

    return (
        <AuthProvider reducer={reducer} initialState={initState} currentPage={currentPage}>
            {children}
        </AuthProvider>
    );
};

export default AuthContext;
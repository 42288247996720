import { SettingsModalNavState } from "components/ElectrcityCustomers/modals/ElectricityCustomerSettingsModal";
import { IInvoiceGroup } from "components/ElectricityInvoiceGroups/models";
import React, { useState } from "react";
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";

interface InvoiceGroupProps extends IInvoiceGroup {
  navigate : (nextState : SettingsModalNavState) => void;
  handleEdit: (invoiceGroup: IInvoiceGroup) => void;
  handleRemove: (id: IInvoiceGroup) => void;
}

export function InvoiceGroup(props : InvoiceGroupProps){
  const [isExpanded, setIsExpanded] = useState(false);

  const {id, name, referenceNumber, meteringPoints, invoiceRecipientEmail, paidFromCustomersAdvance, handleEdit, handleRemove} = props;

  const meteringPointsLen = meteringPoints?.length ?? 0;
  const meteringPointsLimit = isExpanded ? meteringPointsLen : 4

  return (
    <div className="invoice-group pr-2">
      <div className="d-flex justify-content-between align-items-center w-100">
        <h2>{name}</h2>
        <div className="d-flex">
          <OverlayTrigger
            delay={250}
            overlay={
                <Tooltip id={`edit-invoice-group-tooltip-${id}`}>
                    <p style={{margin: '3px'}}>Muuda gruppi</p>
                </Tooltip>
            } 
            placement={"left"}
          >
            <Button 
                size="sm"                             
                variant="secondary"
                className="d-flex align-items-center btn-outline-icon mr-2" 
                onClick={() => handleEdit(props)}
                >
                    <i className="icon--16 my-2 icon__edit--outline-mask"/>
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            delay={250}
            overlay={
                <Tooltip id={`delete-invoice-group-tooltip-${id}`}>
                    <p style={{margin: '3px'}}>Kustuta grupp</p>
                </Tooltip>
            } 
            placement={"left"}
          >
            <Button 
                size="sm"                             
                variant="secondary"
                className="d-flex align-items-center btn-outline-icon" 
                onClick={() => handleRemove(props)}
                >
                  <i className="icon--16 my-2 icon__delete--outline-mask"/>
            </Button>
          </OverlayTrigger>
        </div>
      </div>
      <div className="row mb-3 d-flex align-items-center">
        <div className="font-weight-bold col-3">Viitenumber</div> 
        <div className="col-6">{referenceNumber}</div>
      </div>
      {invoiceRecipientEmail && (
        <div className="row mb-3 d-flex align-items-center">
          <div className="font-weight-bold col-3">Arve saaja e-post</div> 
          <div className="col-6">{invoiceRecipientEmail}</div>
        </div>
      )}
      <div className="row mb-3 d-flex align-items-center">
        <div className="font-weight-bold col-3">Tasutakse kliendi ettemaksust</div> 
        <div className="col-6">
          <Form.Check
              id='check-paid-from-customers-advance'
              custom
              type="checkbox"
              className='form-check-input-no-label'
              checked={paidFromCustomersAdvance}
              label=""
          />
        </div>
      </div>
      <div className="font-weight-bold mb-2">Mõõtepunktid</div>
      <div className="d-flex flex-column"> 
        <div className="row d-flex align-items-center p-2">
          <div className="col-3 text-gray-700">EIC</div>
          <div className="col-6 text-gray-700">AADRESS</div>
          <div className="col text-gray-700">TOOTMISVÕIMSUS</div>
        </div>
        {meteringPoints.slice(0, meteringPointsLimit).map(mp => (
          <div key={mp.id} className="invoice-group__metering-point row d-flex align-items-center my-2 p-2">
            <div className="col-3">{mp.eic}</div>
            <div className="col-6">{mp.address}</div>
            <div className="col">{mp.productionCapacity}</div>
          </div>
        ))}
        {(meteringPointsLen > 4) && (
          <div
            className="cursor-pointer row p-2"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <strong className="col">{isExpanded ? "Näita vähem..." : "Näita rohkem..."}</strong>
          </div>
        )}
      </div>
    </div>
  )
}
const baseUrl = process.env.REACT_APP_API_URL;
const apiVersion = process.env.REACT_APP_API_VERSION;

export const apiService = {
    getApiUrl,
    getBaseUrl
};

function getApiUrl() {
    return baseUrl + '/v' + apiVersion;
}
function getBaseUrl() {
    return baseUrl;
}

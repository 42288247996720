import { ElectricityInvoiceGroupsService } from "components/ElectricityInvoiceGroups/ElectrcityInvoiceGroups.service";
import { IInvoiceGroup } from "components/ElectricityInvoiceGroups/models";
import React, { useState } from "react";
import {Button, Modal, Spinner} from "react-bootstrap";

interface InvoiceGroupDeleteConfirmModalProps {
  invoiceGroup: IInvoiceGroup;
  handleSubmitClose: () => void;
  handleRejectClose: () => void;
}

export function InvoiceGroupDeleteConfirmModal(props: InvoiceGroupDeleteConfirmModalProps) {
  const [saving, setSaving] = useState(false);
  const [savingError, setSavingError] = useState(false);

  const confirmSubmit = async () => {
    setSaving(true);

    try{
      await ElectricityInvoiceGroupsService.deleteInvoiceGroup(props.invoiceGroup.id);
      props.handleSubmitClose()
    }catch(e){
      console.error(e)
      setSavingError(true)
    }finally{
      setSaving(false)
    }
  };
  
  const rejectSubmit = () => {
      props.handleRejectClose();
  };

  return (
      <>
          <Modal
              show={true}
              size={"sm"}
              centered
          >
              <Modal.Header>
                  <Modal.Title>
                      Arve grupi kustutamine
                  </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                  <div className="">
                      <>
                          <span>Kas oled kindel, et soovid arve grupi</span>
                          <strong>&nbsp;{props.invoiceGroup.name}&nbsp;</strong>
                          <span>kustutada?</span>
                      </>
                  </div>
              </Modal.Body>
              <Modal.Footer className="d-flex flex-row align-items-end justify-content-around flex-wrap mb-1">
                  <Button variant="info"
                          onClick={() => rejectSubmit()}
                          type="button"
                          className={"d-flex align-items-center"}>
                      <span className='px-2'>Loobu</span>
                  </Button>
                  <Button variant="danger"
                          onClick={confirmSubmit}
                          type="button"
                          className="d-flex align-items-center">
                      {
                          !saving &&
                          <span className='px-2'>Kustuta</span>
                      }
                      {
                          saving &&
                          <>
                              <span className='px-2'>Kustutamine…</span>
                              <Spinner size={"sm"} animation={"border"}/>
                          </>
                      }
                  </Button>
              </Modal.Footer>
              {
                  !saving && savingError &&
                  <span className="d-flex justify-content-end text-danger px-2 pb-2">
                      Viga salvestamisel, proovi mõne hetke pärast uuesti.
                  </span>
              }
          </Modal>
      </>
  );
}

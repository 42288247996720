/* eslint-disable react-hooks/exhaustive-deps */
import { ModalProps } from "common/common-models";
import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { ElectricityInvoicesService } from "../ElectricityInvoices.service";
import { SynchronizationData, SynchronizationErrorData, SynchronizationStatus, SynchronizationType } from "models/syncronization";
import { translateCreateInvoiceError } from "helpers";
import moment from "moment";

interface CreateInvoicesModalProps extends ModalProps {
  isPerson: boolean;
}

export function CreateInvoicesModal(props: CreateInvoicesModalProps) {
    const [errors, setErrors] = useState<SynchronizationErrorData[]>([]);
    const [isCreating, setIsCreating] = useState(false);
    const [intervalId, setIntervalId] = useState<null | NodeJS.Timeout>(null)
    const [latestSync, setLatestSync] = useState<SynchronizationData | null>(null)

    const isDisabled = isCreating;

    const getLatestSyncErrors = async () => {
      const response = await ElectricityInvoicesService.findLatestSynchronizationErrors(
        props.isPerson ? SynchronizationType.PRIVATE_INVOICES : SynchronizationType.BUSINESS_INVOICES
      );
      setErrors(
        translateErrors(response)
      );
    }

    const translateErrors = (errors: SynchronizationErrorData[]) => {
      return errors.map(error => {
          return {
            ...error,
            message: translateCreateInvoiceError(error.message)
          }
      })
    }
    
    const createInvoices = async () => {
      setIsCreating(true)

      await ElectricityInvoicesService.createInvoices(
        props.isPerson
      )

      if(!intervalId){
          initializeCreateInvicesInterval();
      }
    }

    const checkInvoicesSyncStatus = async () => {
        const response = await ElectricityInvoicesService.getSynchronizationStatus(
          props.isPerson ? SynchronizationType.PRIVATE_INVOICES : SynchronizationType.BUSINESS_INVOICES
        );
        if(response.status === SynchronizationStatus.IN_PROGRESS){
            setIsCreating(true)

            initializeCreateInvicesInterval();
        }else{
          setLatestSync(response);
        }
    }

    const initializeCreateInvicesInterval = async () => {
        const intervalId = setInterval(async () => {
            const response = await ElectricityInvoicesService.getSynchronizationStatus(
              props.isPerson ? SynchronizationType.PRIVATE_INVOICES : SynchronizationType.BUSINESS_INVOICES
            );
            if(response.status === SynchronizationStatus.COMPLETED || response.status === SynchronizationStatus.FAILED){
                setLatestSync(response);
                setIsCreating(false);
                getLatestSyncErrors();
                removeInterval(intervalId);
            }
        }, 10000)

        setIntervalId(intervalId);
    }

    const removeInterval = (interval : NodeJS.Timeout) => {
      clearInterval(interval);
      setIntervalId(null)
    }

    useEffect(() => {
      checkInvoicesSyncStatus();
      getLatestSyncErrors();

      return () => {
        if(intervalId){
          removeInterval(intervalId)
        }
      }
    }, [])

    return (
      <Modal show={true} onHide={props.handleModalHide} dialogClassName="create-invoices-modal">
        <Modal.Header>
            <Modal.Title>
                {props.isPerson ? "Erakliendi arved" : "Ärikliendi arved"}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {!!errors.length && (
               <div>
                  <h2>{moment(latestSync?.startedAt).format('YYYY-MM-DD').toString()} päringuga seotud veateated</h2>
                  <div className="create-invoices-modal__table-body">
                    <table className="w-100">
                    <thead>
                      <tr>
                        <th>Lepingu nr</th>
                        <th>Veateade</th>
                      </tr>
                    </thead>
                    <tbody className="create-invoices-modal__table-body">
                        {errors.map((error) => (
                            <tr key={error.property}>
                              <td>{error.property}</td>
                              <td className="text-danger">{error.message}</td>
                            </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
               </div>
            )}
        </Modal.Body>
        <Modal.Footer>
            <Button
              variant="primary"
              type="button"
              onClick={() => createInvoices()}
              disabled={isDisabled}
              style={{
                width: "100px"
              }}
            >
                <div>
                {isCreating ? (
                  <Spinner size="sm" animation="border"/>
                ) : (
                  <span>Loo arved</span>
                )}
                </div>
            </Button>
            <Button
                variant="primary" 
                onClick={props.handleModalHide}
                type="button"
            >
                <span className='px-3'>Sulge</span>
            </Button>
        </Modal.Footer>
    </Modal>      
    );
}
import React from 'react';
import {Button, Modal} from "react-bootstrap";
import {ModalType} from "../../../common/common-models";

interface INewTermsAndConditionsConfirmModalProps {
    termsAndConditionsName: string;
    handleConfirmSubmit: () => void;
    handleConfirmReject: () => void;
    modalType: ModalType
}

export function EditTermsAndConditionsConfirmModal(props: INewTermsAndConditionsConfirmModalProps) {

    const confirmSubmit = () => {
        props.handleConfirmSubmit();
    };
    const rejectSubmit = () => {
        props.handleConfirmReject();
    };

    return (
        <>
            <Modal
                show={true}
                size={"sm"}
                centered
            >
                <Modal.Header>
                    <Modal.Title>
                        Kinnita muudatused
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="">
                        {
                            props.modalType === ModalType.CREATE &&
                            <span>{`Kas oled kindel, et soovid uue tüüptingimuse salvestada?`}</span>
                        }
                        {
                            props.modalType === ModalType.EDIT &&
                            <span>{`Kas oled kindel, et soovid ${props.termsAndConditionsName} muudatused salvestada?`}</span>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex flex-row align-items-end justify-content-around flex-wrap mb-1">
                    <Button variant="info"
                            onClick={() => rejectSubmit()}
                            type="button"
                            className={"d-flex align-items-center"}>
                        <span className='px-2'>Tühista</span>
                    </Button>
                    <Button variant="primary"
                            onClick={confirmSubmit}
                            type="button"
                            className="d-flex align-items-center">
                        <span className='px-2'>Salvesta</span>
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

import React, {PureComponent} from 'react';

import {IPageResponse, SortDirection} from "../common/interfaces/IPageResponse";
import {PetrolStationsService} from "../components/PetrolStations/PetrolStations.service";
import {PetrolStationsTable} from "../components/PetrolStations/PetrolStationsTable";
import {
    IPetrolStation,
    IPetrolStationSortFields,
    IPetrolStationsState,
    IStationFeature
} from "../components/PetrolStations/models";
import {PetrolStationsSearchBar} from "../components/PetrolStations/PetrolStationsSearchBar";
import {EditPetrolStationModal} from "../components/PetrolStations/modals/EditPetrolStationModal";
import {DeletePetrolStationConfirmModal} from "../components/PetrolStations/modals/DeletePetrolStationConfirmModal";
import {ModalType} from "../common/common-models";

export default class PetrolStations extends PureComponent<any, IPetrolStationsState> {
    constructor(props: any) {
        super(props);
        this.state = {
            petrolStations: null,
            stationFeatures: null,
            pageRequest: {
                page: 0,
                size: 20,
                sortDirection: SortDirection.ASC,
                sortField: IPetrolStationSortFields.ID,
                searchQuery: null
            },
            modalType: ModalType.CREATE,
            isEditModalVisible: false,
            isDeleteModalVisible: false
        };
        this.props.onLoadValidatePage();
    }

    getPetrolStations = () => {
        PetrolStationsService.getPetrolStations(this.state.pageRequest).then((response: IPageResponse<IPetrolStation>) => {
            this.setState({
                petrolStations: response
            })
        });
    };

    getStationFeatures = () => {
        PetrolStationsService.getStationFeatures().then((response: IPageResponse<IStationFeature>) => {
            this.setState({
                stationFeatures: response
            })
        });
    };

    onPageChange = (targetPage: number) => {
        if (this.state.pageRequest.page + 1 !== targetPage) {
            this.setState({
                pageRequest: {
                    ...this.state.pageRequest,
                    page: targetPage - 1
                }
            }, () => this.getPetrolStations());
        }
    };

    openPetrolStationModal = (modalType: ModalType, petrolStation?: IPetrolStation) => {
        this.setState({
            isEditModalVisible: true,
            isDeleteModalVisible: false,
            modalType: modalType,
            petrolStation: petrolStation
        })
    };

    openDeletePetrolStationModal = (petrolStation: IPetrolStation) => {
        this.setState({
            isEditModalVisible: false,
            isDeleteModalVisible: true,
            petrolStation: petrolStation
        })
    };

    componentDidMount(): void {
        this.getPetrolStations();
        this.getStationFeatures();
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col">
                        <PetrolStationsSearchBar openPetrolStationModal={this.openPetrolStationModal} />
                        {this.state.petrolStations !== null ?
                            <PetrolStationsTable petrolStations={this.state.petrolStations}
                                                 onPageChange={this.onPageChange}
                                                 openPetrolStationModal={this.openPetrolStationModal}
                                                 openDeletePetrolStationModal={this.openDeletePetrolStationModal}
                            /> :
                            null
                        }
                    </div>
                </div>
                {
                    (this.state.isEditModalVisible && this.state.stationFeatures !== null) &&
                    <EditPetrolStationModal
                        handleModalHide={() => {this.setState({isEditModalVisible: false})}}
                        modalType={this.state.modalType}
                        stationFeatures={this.state.stationFeatures.content}
                        editingPetrolStation={this.state.petrolStation}
                        loadPetrolStations={this.getPetrolStations}
                    />
                }
                {
                    this.state.isDeleteModalVisible && this.state.petrolStation &&
                    <DeletePetrolStationConfirmModal
                        handleRejectClose={() => {
                            this.setState({isDeleteModalVisible: false})
                        }}
                        handleSubmitClose={() => {
                            this.setState({isDeleteModalVisible: false},
                                () => {
                                this.getPetrolStations();
                            });
                        }}
                        petrolStation={this.state.petrolStation}/>
                }
            </div>
        );
    }
}


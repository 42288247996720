import axios from 'axios';
import {apiService} from "../../services/api.service";
import { downloadFile, handleReportResponse } from 'helpers/apiResponseHelper';

async function getAdvertisementParticipantsExport(advertisementId: number) {
  return axios.get(apiService.getApiUrl() + `/promotion-participants/admin/promotion/${advertisementId}/participants-export`, {
      responseType: 'blob'
  }).then(handleReportResponse).then((res) => downloadFile(res.fileName, res.data));
}

export const AdvertisementParticpantService = {
  getAdvertisementParticipantsExport
};
/* eslint-disable react-hooks/exhaustive-deps */
import ElectricityContentBlock from "components/ElectricityAgreements/components/ElectricityAgreementContentBlock";
import React, { Dispatch, SetStateAction } from "react";
import { Form } from "react-bootstrap";
import { ElectricityCustomerSettingsModalFormState, SettingsModalNavState } from "components/ElectrcityCustomers/modals/ElectricityCustomerSettingsModal";
import { IElectricityAgreement, MeteringPoint } from "components/ElectricityAgreements/models";
import { CustomerInfo } from "./CustomerInfo";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

interface CustomerAgreementProps {
  form: ElectricityCustomerSettingsModalFormState;
  setForm: Dispatch<SetStateAction<ElectricityCustomerSettingsModalFormState>>;
  setActiveMeteringPoint: Dispatch<SetStateAction<MeteringPoint | undefined>>;
  navigate : (nextState : SettingsModalNavState) => void;
  agreement: IElectricityAgreement;
  invoiceRecipientIsInvalid: boolean;
}

export function CustomerAgreement({form, setForm, agreement, setActiveMeteringPoint, navigate, invoiceRecipientIsInvalid} : CustomerAgreementProps){
  const {meteringPoints, contractNumber, startDate, endDate, consumptionPackage, productionPackage} = agreement;

  return (
    <div className="customer-invoice-groups w-100">
      <ElectricityContentBlock 
        title="Leping"
      >
        <CustomerInfo 
          title="Lepingu number"
          value={contractNumber}
        />
        <CustomerInfo 
          title="Algus"
          value={startDate}
        />
        <CustomerInfo 
          title="Lõpp"
          value={endDate ?? "Tähtajatu"}
        />
        <CustomerInfo 
          title="Pakett"
          value={`${consumptionPackage.nameEt}${!!productionPackage ? '/ost' : ''}`}
        />
         <Form noValidate validated={form.validated}>
          <div className="row">
            <div className="col-6">
                <Form.Label>Lepingu arve saaja e-post</Form.Label>
                <Form.Control
                    value={form.agreement?.invoiceRecipientEmail ?? ''}
                    onChange={(e: any) => setForm({
                        ...form, 
                        agreement : {
                          ...form.agreement!,
                          invoiceRecipientEmail: e.target.value
                        }
                    })}
                    as="textarea"
                    rows={1}
                    isInvalid={invoiceRecipientIsInvalid}
                />
                <Form.Control.Feedback className={'text-right'} type="invalid">Vigane e-post!</Form.Control.Feedback>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-6">
                <Form.Check
                  id='check-paid-from-customers-advance'
                  custom
                  type="checkbox"
                  className='checkbox-large'
                  label='Tasutakse kliendi ettemaksust'
                  onChange={() => setForm({
                    ...form, 
                    agreement : {
                      ...form.agreement,
                      paidFromCustomersAdvance: !form.agreement?.paidFromCustomersAdvance
                    }
                  })}
                  checked={form.agreement?.paidFromCustomersAdvance}
                />
            </div>
          </div>
        </Form>
      </ElectricityContentBlock>
      <ElectricityContentBlock 
        title="Mõõtepunktid"
      >
         <MeteringPointsTable 
            meteringPoints={meteringPoints}
            handleEdit={(meteringPoint) => {
              setForm({
                ...form,
                meteringPoint : {
                  externalChargingStationId: meteringPoint.externalChargingStationId
                }
              })
              setActiveMeteringPoint(meteringPoint)
              navigate(SettingsModalNavState.METERING_POINT)
            }}
          />
      </ElectricityContentBlock>
    </div>
  )
}

function MeteringPointsTable({meteringPoints, handleEdit} : {
  meteringPoints: MeteringPoint[];
  handleEdit: (mp : MeteringPoint) => void;
}){

  const tableRows = meteringPoints.map((meteringPoint) => {
    const {id, eic, address, productionCapacity, externalChargingStationId} = meteringPoint;

    return (
        <tr key={id} className="w-100">
          <td className="col-4">{eic}</td>
          <td className="col">{address}</td>
          <td className="col-2">{externalChargingStationId}</td>
          <td className="col-1">{productionCapacity}</td>
          <td className="col-1">
            <OverlayTrigger
                delay={250}
                overlay={
                    <Tooltip id={`edit-electricity-metering-point-tooltip-${id}`}>
                        <p style={{margin: '3px'}}>Muuda mõõtepunkti</p>
                    </Tooltip>
                } 
                placement={"left"}
              >
                <Button 
                    size="sm"                             
                    variant="secondary"
                    className="d-flex align-items-center btn-outline-icon mr-2" 
                    onClick={() => handleEdit(meteringPoint)}
                    >
                        <i className="icon--16 my-2 icon__edit--outline-mask"/>
                </Button>
              </OverlayTrigger>
          </td>
        </tr>
    )
  })

  return (
    <table className='generic-table table table-responsive-md'>
      <thead>
        <tr>
          <th>Eic</th>
          <th>Aadress</th>
          <th>Laadimisjaam</th>
          <th>Tootmisvõimsus</th>
        </tr>
      </thead>
      <tbody>
        {tableRows}
      </tbody>
    </table>
  )
}
/* eslint-disable react-hooks/exhaustive-deps */
import { ElectricityPackagesService } from "components/ElectricityPackages/ElectricityPackages.service";
import { ElectricityPackageType, IElectricityPackage } from "components/ElectricityPackages/models";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { INDIVIDUAL_PACKAGE_VALUE, UpdateElectricityAgreementPackageModalForm } from "../../models";
import DatePicker from "react-date-picker";
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar.svg';
import { getLastMonthWithFirstDay, getLastMonthWithLastDay, getMarketPricePackageProductDescription } from "helpers";

interface UpdateElectricityAgreementPackageFormProps {
    form: UpdateElectricityAgreementPackageModalForm;
    setForm: (form: UpdateElectricityAgreementPackageModalForm) => void;
    activePackage: IElectricityPackage;
    agreementStartsInFuture: boolean;
    earliestPossibleStartDate: Date;
}

interface FormElectricityPackage extends IElectricityPackage {
    isIndividual?: boolean;
}

export default function UpdateElectricityAgreementPackageForm(
    { form, setForm, activePackage, agreementStartsInFuture, earliestPossibleStartDate} : UpdateElectricityAgreementPackageFormProps
) {
    const [packages, setPackages] = useState<FormElectricityPackage[]>([]);

    useEffect(() => {
        getPackages();
    }, []);

    const getPackages = () => {
        ElectricityPackagesService.getElectricityPackages({
            page: 0,
            size: 999
        }).then(res => {
            let packagesWithConsumptionType = res.content
                .filter((curr: IElectricityPackage) => {
                    if (!form.isJointInvoicePossible && curr.jointInvoicePackage) {
                    return false;
                    }
                    return true;
                })
                .filter((pckg: IElectricityPackage) => pckg.type === ElectricityPackageType.CONSUMPTION);

            const duplicatePackage = packagesWithConsumptionType.find((pckg) => pckg.id === activePackage.id);
            if(duplicatePackage){
                packagesWithConsumptionType = packagesWithConsumptionType.filter(pckg => pckg.id !== duplicatePackage.id);
            }

            setForm({
                ...form,
                consumptionPackage: {
                    ...form.consumptionPackage,
                    isIndividual: !duplicatePackage
                }
            })

            setPackages([
                ...packagesWithConsumptionType,
                duplicatePackage ? form.consumptionPackage : {
                    ...form.consumptionPackage,
                    isIndividual: true
                }
            ]);
        });
    }

    const packageOptions = packages
        .map(p => ({ value: p.id, label: p.nameEt, isIndividual: p.isIndividual }));

    packageOptions.push({ value: INDIVIDUAL_PACKAGE_VALUE, label: 'Individuaalne', isIndividual: true });

    const setPackageValue = (option?: any) => {
        const p : any = packages.find(p => p.id === option?.value);

        setForm({
            ...form,
            consumptionPackage: {
                id: option?.value === INDIVIDUAL_PACKAGE_VALUE ? INDIVIDUAL_PACKAGE_VALUE : p?.id,
                termsAndConditionsId: p?.terms?.id,
                basePrice: p?.basePrice,
                basePriceTextEt: p?.basePriceTextEt ?? '',
                basePriceTextEn: p?.basePriceTextEn ?? '',
                contractTitle: p?.contractTitle ?? '',
                pdfFooterDisclaimer: (form.isCompany ? p?.pdfFooterDisclaimerCompany : p?.pdfFooterDisclaimerPerson) ?? '',
                monthlyFee: p?.monthlyFee,
                marketPricePackage: !!p?.marketPricePackage,
                pdfProductDescriptionOnInvoice: p?.pdfProductDescriptionOnInvoice,
                externalProductDescriptionOnInvoice: p?.externalProductDescriptionOnInvoice,
                jointInvoicePackage: !!p?.jointInvoicePackage,
                isIndividual: option?.isIndividual,
                nameEt: p?.nameEt,
                nameEn: p?.nameEn
            },
        })
    }  

    const currentPackageOption = packageOptions.find(p => p.value === form.consumptionPackage?.id);

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-6">
                    <Form.Label>Pakett*</Form.Label>
                    <Select
                        required
                        options={packageOptions}
                        value={currentPackageOption}
                        onChange={setPackageValue}
                        placeholder='Vali elektripakett'
                        className={'w-100 form-control form-select' + (!form.consumptionPackage?.id && form.validated ? ' is-invalid' : '')}
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
            </div>
            { currentPackageOption?.isIndividual ?
                    (
                        <div className="row mt-3">
                            <div className="col-12 col-md-6">
                                <Form.Label>Paketi eestikeelne nimetus*</Form.Label>
                                <Form.Control
                                    required
                                    value={form.consumptionPackage?.nameEt}
                                    onChange={(e: any) => setForm({
                                        ...form,
                                        consumptionPackage: {
                                            ...form.consumptionPackage,
                                            nameEt: e.target.value
                                        }
                                    })}
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <Form.Label>Paketi inglisekeelne nimetus</Form.Label>
                                <Form.Control
                                    value={form.consumptionPackage?.nameEn}
                                    onChange={(e: any) => setForm({
                                        ...form,
                                        consumptionPackage: {
                                            ...form.consumptionPackage,
                                            nameEn: e.target.value
                                        }
                                    })}
                                />
                            </div>
                        </div>
                    ) : null
                }
            <div className="row mt-3">
                <div className="col-12 col-md-6">
                    <Form.Check
                        className='form-check-input-no-label'
                        id='market-price-check'
                        label='Börsipakett'
                        checked={form.consumptionPackage?.marketPricePackage}
                        disabled={!form.consumptionPackage?.isIndividual}
                        onChange={(e: any) => setForm({
                            ...form,
                            consumptionPackage: {
                                ...form.consumptionPackage,
                                marketPricePackage: e.target.checked,
                                basePrice: !form.consumptionPackage?.marketPricePackage ? undefined : form.consumptionPackage.basePrice,
                                basePriceTextEt: !form.consumptionPackage?.marketPricePackage ? !form.consumptionPackage?.basePriceTextEt ? 'Börsihind + marginaal' : form.consumptionPackage?.basePriceTextEt : '',
                                basePriceTextEn: !form.consumptionPackage?.marketPricePackage ? !form.consumptionPackage?.basePriceTextEn ? 'Stock market price + margin' : form.consumptionPackage?.basePriceTextEn : ''
                            }
                        })
                        }
                        type='checkbox'
                    />
                    { form.isJointInvoicePossible && (
                        <Form.Check
                            className='form-check-input-no-label'
                            id='joint-invoice-check'
                            label='Ühisarve'
                            checked={form.consumptionPackage?.jointInvoicePackage}
                            disabled={!form.consumptionPackage?.isIndividual}
                            onChange={(e: any) => setForm({
                                ...form,
                                consumptionPackage: {
                                    ...form.consumptionPackage,
                                    jointInvoicePackage: !form.consumptionPackage?.jointInvoicePackage,
                                }
                            })}
                            type='checkbox'
                        />
                    )}
                </div>
            </div>
            {!agreementStartsInFuture && (
                <div className="row mt-3">
                    <div className="col-12 col-md-6">
                        <Form.Label htmlFor="contract-start">Paketi algus*</Form.Label>
                            <DatePicker
                                className={'d-block' + (form.startDate === undefined && form.validated ? ' invalid is-invalid' : '')}
                                value={new Date(form.startDate)}
                                locale='et'
                                clearIcon={null}
                                calendarIcon={<CalendarIcon />}
                                format='dd.MM.yyyy'
                                minDate={earliestPossibleStartDate}
                                maxDate={new Date()}
                                onChange={(event: any) => {
                                    if(!event){
                                        return;
                                    }
                                    setForm({
                                        ...form, 
                                        startDate: new Date(event).toString(),
                                    })
                                }}
                                required
                            />
                            <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                        </div>
                </div>
            )}
            {currentPackageOption?.value === INDIVIDUAL_PACKAGE_VALUE ? (
                <>
                   <div className="row mt-3">
                        <div className="col-12 col-md-6">
                            <Form.Label>Arve toote kirjeldus PDF-il</Form.Label>
                            <Form.Control
                                value={form.consumptionPackage?.pdfProductDescriptionOnInvoice}
                                onChange={(e) => setForm({
                                        ...form,
                                        consumptionPackage: {
                                            ...form.consumptionPackage,
                                            pdfProductDescriptionOnInvoice: e.target.value
                                        }
                                    })
                                }
                                type="text"
                            />
                        </div>
                    </div>
                    <div className='text-break'>{form.consumptionPackage?.pdfProductDescriptionOnInvoice ?? (form.consumptionPackage?.marketPricePackage ? getMarketPricePackageProductDescription(form.consumptionPackage.nameEt) : "Elektrienergia universaalteenuse hinnaga")} ({getLastMonthWithFirstDay()} - {getLastMonthWithLastDay()}) mõõtepunktis <b>X</b> aadressiga <b>X</b></div>
                    <div className="row mt-3">
                        <div className="col-12 col-md-6">
                            <Form.Label>Arve toote kirjeldus meritis</Form.Label>
                            <Form.Control
                                className='col-12 col-md-6'
                                value={form.consumptionPackage?.externalProductDescriptionOnInvoice}
                                onChange={(e) => setForm({
                                        ...form,
                                        consumptionPackage: {
                                            ...form.consumptionPackage,
                                            externalProductDescriptionOnInvoice: e.target.value
                                        }
                                    })
                                }
                                maxLength={150}
                                type="text"
                            />
                        </div>
                    </div>
                    <div className='col-12 p-0 text-break'>{form.consumptionPackage?.externalProductDescriptionOnInvoice ?? (form.consumptionPackage?.marketPricePackage ? getMarketPricePackageProductDescription(form.consumptionPackage.nameEt) : "Elektri universaalteenus")} ({getLastMonthWithFirstDay()} - {getLastMonthWithLastDay()}) - (<b>X</b>) <b>X</b></div>
                </>
            ) : null} 
        </>
    );
}
export interface ICustomer {
  id: number;
  name: string;
  registryNumber: string;
  eic: string;
  address: string;
  invoiceRecipientEmail: string;
  agreementsCount: number;
  isPerson: boolean;
  isGroupInvoicingEnabled: boolean;
  isFactoringClient: boolean;
  bankAccountNumber?: string
  receiveInvoiceByEmail: boolean;
}

export interface ISimplifiedCustomer {
  id: number;
  name: string;
}

export enum ICustomerSortFields {
  ID = "ID",
  NAME = "NAME",
  REGISTRY_NUMBER = "REGISTRY_NUMBER",
  EIC = "EIC"
}
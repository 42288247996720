import React from "react";
import { IElectricityAgreement, IElectricAgreementTableProps, ELECTRICITY_AGREEMENT_STATUS_MAP, IElectricityAgreementSortFields, ElectricityAgreementStatus } from "./models";
import {dateFormatOptions} from "../../common/dateFormatterConfig";
import {Button, OverlayTrigger, Spinner, Tooltip} from "react-bootstrap";
import Pagination from "rc-pagination";
import {locale} from "../../common/table/paginatorConfig";
import { SortIcons } from "components/SortIcons/SortIcons";
import { roundNumber } from "helpers";
import moment from "moment";

export function ElectricityAgreementsTable(props: IElectricAgreementTableProps) {
    const electricityAgreements: Array<IElectricityAgreement> = props.electricityAgreements.content;
    const pageResponse = props.electricityAgreements;

    const electricityAgreementTableRows = electricityAgreements.map((electricityAgreement: IElectricityAgreement) =>
        <tr key={'row-' + electricityAgreement.id}>
            <td className="line-height-1">
                {electricityAgreement.customer.name}<br />
                <small className="text-dark">{electricityAgreement.customer.eic}</small>
            </td>
            <td className="text-nowrap">
                {electricityAgreement.contractNumber}
            </td>
            <td>
                {electricityAgreement.startDate ? new Intl.DateTimeFormat('et', dateFormatOptions).format(new Date(electricityAgreement.startDate)) : ""}
            </td>
            <td>
                {electricityAgreement.endDate ? new Intl.DateTimeFormat('et', dateFormatOptions).format(new Date(electricityAgreement.endDate)) : 'Tähtajatu'}
            </td>            
            <td>
                {electricityAgreement.consumptionPackage.nameEt}{!!electricityAgreement.productionPackage ? '/ost' : ''}
            </td>
            <td>
                <div className="line-height-1">{ !!electricityAgreement.productionMarginal && 'Tarbimine:'} { roundNumber(electricityAgreement.consumptionMarginal, 4) }</div>
                { !!electricityAgreement.productionMarginal && <div className="line-height-1">Tootmine: { roundNumber(electricityAgreement.productionMarginal, 4)}</div>}
            </td>
            <td>
                <MeteringPointCell electricityAgreement={electricityAgreement} />
            </td>
            <td>
                {ELECTRICITY_AGREEMENT_STATUS_MAP[electricityAgreement.status]}
            </td>
            <td>
                {electricityAgreement.dateCreated ? new Intl.DateTimeFormat('et', dateFormatOptions).format(new Date(electricityAgreement.dateCreated)) : ""}
            </td>
            <td>
                <div className="row__actions">
                    {
                        (electricityAgreement.status === ElectricityAgreementStatus.ACTIVE) && (
                            <OverlayTrigger
                                    delay={250}
                                    overlay={
                                        <Tooltip id={`update-package-tooltip-${electricityAgreement.id}`}>
                                            <p style={{margin: '3px'}}>Muuda paketti</p>
                                        </Tooltip>
                                    } 
                                    placement={"left"}
                            >
                                <Button 
                                    size="sm"                             
                                    variant="secondary"
                                    className="d-flex align-items-center btn-outline-icon" 
                                    onClick={() => props.updateElectricityAgreementPackage(electricityAgreement)}
                                    >
                                        <i className="icon--16 my-2 icon__edit--outline-mask"/>
                                </Button>
                            </OverlayTrigger>
                        )
                    }
                    {
                        (electricityAgreement.status === ElectricityAgreementStatus.PENDING) && (
                            <OverlayTrigger
                                    delay={250}
                                    overlay={
                                        <Tooltip id={`cancel-offer-tooltip-${electricityAgreement.id}`}>
                                            <p style={{margin: '3px'}}>Tühista pakkumine</p>
                                        </Tooltip>
                                    } 
                                    placement={"left"}
                            >
                                <Button 
                                    size="sm"                             
                                    variant="secondary"
                                    className="d-flex align-items-center btn-outline-icon" 
                                    onClick={() => props.cancelElectricityAgreementOffer(electricityAgreement.id)}
                                    >
                                        <i className="icon--16 my-2 icon__cancel--outline-mask"/>
                                </Button>
                            </OverlayTrigger>
                        )
                    }
                    <OverlayTrigger delay={250}
                        overlay={
                            <Tooltip id={`tooltip-${electricityAgreement.id}`}>
                                <p style={{margin: '3px'}}>Vaata PDF faili</p>
                            </Tooltip>} placement={"left"}>
                        <Button 
                            size="sm"
                            variant="secondary"
                            className="d-flex align-items-center btn-outline-icon ml-1" 
                            onClick={() => props.getPdfPreview(electricityAgreement)}
                        >
                            {
                                props.pdfPreviewLoading === electricityAgreement.id ? (
                                    <Spinner animation="border" size="sm"/> 
                                ) : (
                                    <div className='icon__preview-file action-icon'/>
                                )
                            }
                        </Button>
                    </OverlayTrigger>
                    { !!electricityAgreement.startDate && moment(electricityAgreement.startDate).isBefore(moment()) &&  (
                        <>
                            <OverlayTrigger delay={250}
                                overlay={
                                    <Tooltip id={`invoices-tooltip-${electricityAgreement.id}`}>
                                        <p style={{margin: '3px'}}>Vaata arveid</p>
                                    </Tooltip>} placement={"left"}>
                                    <Button size="sm"
                                        variant={ electricityAgreement.hasLastMonthsInvoice ? 'success' : 'secondary'}
                                        className="d-flex align-items-center justify-content-center btn-outline-icon ml-1" 
                                        onClick={() => props.showInvoiceModalForAgreement(electricityAgreement)}>
                                    
                                        <div className='icon--16 icon__invoice action-icon'/>
                                    </Button>
                            </OverlayTrigger>
                        </>
                    )}
                </div>
            </td>
        </tr>
    );

    return (
        <table className='generic-table table table-responsive-md table-hover'>
            <thead>
            <tr>
                <th>Klient</th>
                <th>Lepingu number</th>
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(IElectricityAgreementSortFields.START_DATE)}>
                        <span>Alguskuupäev</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={IElectricityAgreementSortFields.START_DATE} />
                    </div>
                </th>
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(IElectricityAgreementSortFields.END_DATE)}>
                        <span>Lõppkuupäev</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={IElectricityAgreementSortFields.END_DATE} />
                    </div>
                </th>                
                <th>Pakett</th>
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(IElectricityAgreementSortFields.MARGINAL)}>
                        <span>Marginaal, s/kWh</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={IElectricityAgreementSortFields.MARGINAL} />
                    </div>
                </th>
                <th>Mõõtepunkt(id)</th>
                <th>Staatus</th>
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(IElectricityAgreementSortFields.DATE_CREATED)}>
                        <span>Leping loodud</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={IElectricityAgreementSortFields.DATE_CREATED} />
                    </div>
                </th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {electricityAgreementTableRows}
            </tbody>
            <tfoot>
            <tr>
                <td colSpan={9}>
                    <div className="pagination-wrapper">
                        <Pagination
                            locale={locale}
                            current={pageResponse.number + 1}
                            total={pageResponse.totalElements}
                            pageSize={pageResponse.size}
                            prefixCls={"pagination"}
                            onChange={props.onPageChange}
                            showSizeChanger={false}
                            hideOnSinglePage={true}
                        />
                    </div>
                </td>
            </tr>
            </tfoot>
        </table>
    )
}

function MeteringPointCell({ electricityAgreement }: { electricityAgreement: IElectricityAgreement }) {

    const allMeteringPoints = electricityAgreement.meteringPoints.map(mp => <small className="d-block line-height-1" key={mp.eic}>{mp.eic}</small>);
    if (electricityAgreement.meteringPoints.length < 4) {
        return (
        <>
            { allMeteringPoints }
        </>);
    }
    const sliced = electricityAgreement.meteringPoints.slice(0, 3);
    const hidden = electricityAgreement.meteringPoints.slice(3);
    return (
        <>
            <OverlayTrigger delay={250}
                overlay={
                    <Tooltip id={`tooltip-mp-${electricityAgreement.id}`}>
                        <>
                        { allMeteringPoints }
                        </>
                    </Tooltip>
                }
                placement={"top"}
            >
                <div>
                    { sliced.map(mp => <small className="d-block line-height-1 text-nowrap" key={mp.eic}>{mp.eic}</small>) }
                    <small className="d-block line-height-1">...ja veel {hidden.length}</small>                
                </div>
            </OverlayTrigger>

        </>
    );
}
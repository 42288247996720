export interface IUser {
    id: number ;
    email: string | null;
    name: string;
    adminActive: boolean | null;
    adminAccess: boolean | null;
    personalIdentificationCode: string | null;
    lastLogin: string | null;
    dateModified: string | null;
}

export enum IUsersSortFields {
    ID = "ID",
    NAME = "NAME"
}

export interface IUsersSearchBarProps {
    openAddUsersModal: () => void;
}

export interface IEditUserModalProps {
    rejectCloseHandler: () => void;
    submitCloseHandler: () => void;
    editingUser: IUser;
}

export interface IUserForm {
    email: string;
    name: string;
    adminActive: boolean;
    password: string;
    passwordCheck: string;
}
import axios from "axios";
import { convertToPageable, extractData, handleResponse } from "helpers/apiResponseHelper";
import { apiService } from "services/api.service";
import { UpdateSpecialOfferModalForm } from "./modals/UpdateSpecialOfferModal";

async function getSpecialOffers(pageRequest: any) {
  return axios.get(`${apiService.getApiUrl()}/special-offers/admin`, {
      params: pageRequest
  }).then(handleResponse).then(convertToPageable);
}

async function updateSpecialOffer(request: UpdateSpecialOfferModalForm, specialOfferId: number) {  
  return axios.put(`${apiService.getApiUrl()}/special-offers/admin/${specialOfferId}`, {
    active: request.active,
    type: request.type,
    titleEt: request.titleEt,
    titleEn: request.titleEn
  }).then(handleResponse).then(extractData);
}

async function getSpecialOffersImage(id: number) {
  return axios.get(`${apiService.getApiUrl()}/special-offers/admin/${id}/file`,
    {
      responseType: 'blob'
    }
  ).then(handleResponse);
}

async function uploadSpecialOffersImage(file: File, specialOfferId: number) {
  const formData = new FormData();
  
  formData.append("file", file)

  return axios({
    method: 'POST',
    url: `${apiService.getApiUrl()}/special-offers/admin/${specialOfferId}/file`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  }).then(handleResponse).then(extractData);
}

async function syncSpecialOffers() {
  return axios.post(`${apiService.getApiUrl()}/special-offers/admin/sync`).then(handleResponse);
}

export const SpecialOfferService = {
  getSpecialOffers,
  updateSpecialOffer,
  uploadSpecialOffersImage,
  getSpecialOffersImage,
  syncSpecialOffers
};
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { ModalType } from "common/common-models";
import { SortDirection } from "common/interfaces/IPageResponse";
import { AdvertisementService } from "components/Advertisements/Advertisement.service";
import { AdvertisementParticpantService } from "components/Advertisements/AdvertisementParticipant.service";
import { AdvertisementsSearchBar } from "components/Advertisements/AdvertisementsSearchBar";
import { AdvertisementsTable } from "components/Advertisements/AdvertisementsTable";
import { DeleteAdvertisementConfirmModal } from "components/Advertisements/modals/DeleteAdvetisementConfirmModal";
import { EditAdvertisementModal } from "components/Advertisements/modals/EditAdvertisementModal";
import { AdvertisementFilters, AdvertisementSortFields, AdvertisementsState, IAdvertisement } from "components/Advertisements/models";
import React from "react";
import { PureComponent } from "react";

const searchApi = AwesomeDebouncePromise(
  request => AdvertisementService.getAdvertisements(request),
  300
);

export default class Avertisements extends PureComponent<any, AdvertisementsState> {
  constructor(props: any) {
      super(props);
      this.state = {
        advertisements: null,
        request: {
          sortDirection: SortDirection.ASC,
          sortField: AdvertisementSortFields.ID,
          searchQuery: null,
        },
        filters: { },
        isRequesting: false,
        isCreateModalVisible: false,
        isUpdateModalVisible: false,
        isDeleteModalVisible: false
      };
      this.props.onLoadValidatePage();
  }

  getAdvertisements(){
    AdvertisementService.getAdvertisements(this.state.request).then((response: IAdvertisement[]) => {
      this.setState({
        advertisements: response
      });
    })
  }

  handleFilterChange = (change: AdvertisementFilters) => {
    this.setState({
        advertisements: null,
        request: {
            ...this.state.request,
            sortDirection: this.state.request.sortDirection,
            sortField: this.state.request.sortField
        },
        filters: {
            ...this.state.filters,
            ...change
        },
        isRequesting: false,
    }, () => {
        this.searchAdvertisements({
            sortDirection: this.state.request.sortDirection,
            sortField: this.state.request.sortField,
            searchQuery: this.state.filters.search,
            type: this.state.filters.type,
            active: this.state.filters.active
        });
    });
  };

  searchAdvertisements = (request: any) => {
    this.setState({
        isRequesting: true
    });
    searchApi(request).then((response: IAdvertisement[]) => {
        this.setState({
            advertisements: response, 
            isRequesting: false
        });
    })
  };

  handleSortClick = (sortField: AdvertisementSortFields) => {
    let sortDirection = SortDirection.ASC;
    if (this.state.request.sortField.match(sortField)) {
        sortDirection = this.state.request.sortDirection === SortDirection.DESC ? SortDirection.ASC : SortDirection.DESC;
    }
    this.setState({
        request: {
            ...this.state.request,
            sortDirection,
            sortField
        }
    }, () => this.searchAdvertisements({
        sortDirection: this.state.request.sortDirection,
        sortField: this.state.request.sortField,
        searchQuery: this.state.filters.search,
        type: this.state.filters.type,
        active: this.state.filters.active
      })  
    )
  };

  onCreateAdvertisement = () => {
    this.setState({
      isCreateModalVisible: true 
    })
  }

  onUpdateAdvertisement = (activeAdvertisement : IAdvertisement) => {
    this.setState({
      isUpdateModalVisible: true,
      activeAdvertisement
    })
  }

  onDeleteAdvertisement = (activeAdvertisement : IAdvertisement) => {
    this.setState({
      isDeleteModalVisible: true,
      activeAdvertisement
    })
  }

  getAdvertisementParticipantsExport = async (advertisementId : number) => {
    try{
      this.setState({
        advertisementToLoad: advertisementId
      })

      await AdvertisementParticpantService.getAdvertisementParticipantsExport(advertisementId);
    }catch(e){
      console.error(e);
    }finally{
      this.setState({
        advertisementToLoad: undefined
      })
    }
  }

  componentDidMount(): void {
    this.getAdvertisements();
  }

  render() {
    return (
        <div className="container">
            <div className="row">
                <div className="col">
                  <AdvertisementsSearchBar 
                    filters={this.state.filters}
                    onFilter={this.handleFilterChange}
                    onCreateAdvertisement={this.onCreateAdvertisement}
                  />
                  {
                    this.state.advertisements !== null ? (
                        <AdvertisementsTable 
                          advertisements={this.state.advertisements}
                          onSort={this.handleSortClick}
                          sortDirection={this.state.request.sortDirection}
                          sortField={this.state.request.sortField}
                          onUpdateAdvertisement={this.onUpdateAdvertisement}
                          onDeleteAdvertisement={this.onDeleteAdvertisement}
                          getAdvertisementParticipantsExport={this.getAdvertisementParticipantsExport}
                          advertisementToLoad={this.state.advertisementToLoad}
                        />
                    ) : null
                  }
                </div>
            </div>
            { this.state.isCreateModalVisible &&  
              <EditAdvertisementModal
                handleModalHide={() => {
                  this.setState({ isCreateModalVisible: false });
                }}
                onSuccess={() => this.getAdvertisements()}
                modalType={ModalType.CREATE}
              />
            }
            { (this.state.isUpdateModalVisible && this.state.activeAdvertisement) &&  
              <EditAdvertisementModal
                modalType={ModalType.EDIT}
                advertisement={this.state.activeAdvertisement}
                handleModalHide={() => {
                  this.setState({ isUpdateModalVisible: false });
                }}
                onSuccess={() => this.searchAdvertisements({
                  sortDirection: this.state.request.sortDirection,
                  sortField: this.state.request.sortField,
                  searchQuery: this.state.filters.search,
                  type: this.state.filters.type,
                  active: this.state.filters.active
                })}
              />
            }
            { (this.state.isDeleteModalVisible && this.state.activeAdvertisement) &&  
              <DeleteAdvertisementConfirmModal
                handleRejectClose={() => {
                  this.setState({ isDeleteModalVisible: false });
                }}
                handleSubmitClose={() => {
                  this.searchAdvertisements({
                    sortDirection: this.state.request.sortDirection,
                    sortField: this.state.request.sortField,
                    searchQuery: this.state.filters.search,
                    type: this.state.filters.type,
                    active: this.state.filters.active
                  })
                  this.setState({
                    isDeleteModalVisible: false
                  })
                }}
                advertisement={this.state.activeAdvertisement}
              />
            }
        </div>
    );
  }
}
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, useState, useEffect } from 'react';
import Pagination from "rc-pagination";
import { dateFormatOptions } from "../../common/dateFormatterConfig";
import { locale } from "../../common/table/paginatorConfig";
import { AgreementSigningStatus, IAgreement, IAgreementsSortFields } from "../../models/agreements";
import { SortIcons } from 'components/SortIcons/SortIcons';
import { SortDirection } from 'common/interfaces/IPageResponse';
import AgreementsService from 'services/agreements.service';
import { Button, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';

export function AgreementsTable(props: any) {
    const agreements: Array<IAgreement> = props.agreements.content;
    const pageResponse = props.agreements;
    const [sortedByField, setSortedByField]: [IAgreementsSortFields, Dispatch<any>] = useState<IAgreementsSortFields>(IAgreementsSortFields.DATE_MODIFIED);
    const [sortDirection, setSortDirection]: [SortDirection, Dispatch<any>] = useState<SortDirection>(SortDirection.DESC);

    useEffect(() => {
        props.onSortChange(sortedByField, sortDirection);
    }, [sortedByField, sortDirection]);
    const tableRows = agreements.map((agreement: IAgreement) =>
        <tr key={'row-' + agreement.id}>
            <td >
                {agreement.customerName}
            </td>
            <td>
                {agreement.contractNumber}
            </td>
            <td>
                {agreement.dateModified ? new Intl.DateTimeFormat('et', dateFormatOptions).format(new Date(agreement.dateModified)) : ""}
            </td>
            <td className="agreements__status">
                <SigningStatusLabel agreement={agreement} />
            </td>
            <td>
                {
                    agreement.isSignedByVendor ?
                    <button className="btn btn-link" onClick={() => props.openSignatures(agreement)}>Vaata allkirju</button> :
                     <div className='card__end'>
                        <button 
                            className='btn btn-dark btn-md' 
                            onClick={() => props.openSigningModal(agreement)}
                            disabled={agreement.isExpired}
                        >
                            Lisa allkiri
                        </button>
                    </div>
                }                
            </td>
            <td>
                <div className="row__actions">
                    <Button size="sm" variant="secondary" className="d-flex align-items-center btn-outline-icon" onClick={() => props.getAgreementFile(agreement, 'PDF')} >
                        <div className='icon__file action-icon'/>
                    </Button>
                    <Button size="sm" variant="secondary" className="d-flex align-items-center btn-outline-icon position-relative" onClick={() => props.getAgreementFile(agreement, 'BDOC')} >
                        <div className='icon__download action-icon mb-2' />
                        <span style={{ position: 'absolute', fontSize: '8px', bottom: '2px', left: '2px'}}>BDOC</span>
                    </Button>
                    <OverlayTrigger delay={250}
                                    overlay={
                                        <Tooltip id={`tooltip-${agreement.id}`}>
                                            <p style={{margin: '3px'}}>Muuda</p>
                                        </Tooltip>} placement={"left"}>
                        <Button size="sm" variant="outline-dark"
                            disabled={agreement.isExpired}
                            className="d-flex align-items-center border-color-background border-radius-circle btn-outline-icon"
                            onClick={() => props.changeAgreementStatus(agreement)}
                        >
                            <i className="icon--16 icon__edit--outline-mask"/>
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger delay={250}
                                    overlay={
                                        <Tooltip id={`tooltip-${agreement.id}`}>
                                            <p style={{margin: '3px'}}>Uus versioon</p>
                                        </Tooltip>} placement={"left"}>
                        <Button size="sm" variant="outline-dark"
                            disabled={agreement.isExpired}
                            className="d-flex align-items-center border-color-background border-radius-circle btn-outline-icon"
                            onClick={() => props.createNewVersion(agreement)}
                        >
                            <span style={{ fontSize: '18px', marginLeft: '2px', fontWeight: 'bold' }}>+</span>
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger delay={250}
                                    overlay={
                                        <Tooltip id={`tooltip-${agreement.id}`}>
                                            <p style={{margin: '3px'}}>Kustuta</p>
                                        </Tooltip>} placement={"left"}>
                        <Button size="sm" variant="outline-danger"
                            className="d-flex align-items-center border-color-background border-radius-circle btn-outline-icon"
                            onClick={() => props.deleteAgreement(agreement)}
                        >
                            <i className="icon--16 icon__delete--outline-mask"/>
                        </Button>
                    </OverlayTrigger>
                 
                </div>
            </td>
        </tr>
    );
    const handleSortClick = (currentSortColumn: IAgreementsSortFields) => {
        if (sortedByField.match(currentSortColumn)) {
            setSortDirection(sortDirection === SortDirection.DESC ? SortDirection.ASC : SortDirection.DESC);
        } else {
            setSortDirection(SortDirection.ASC);
            setSortedByField(currentSortColumn);
        }
    };

    return (
        <table className='agreements__table table table-responsive-md table-hover'>
            <thead>
                <tr>
                    <th className="sort-down-up-hover">
                        <div className="d-flex align-items-center"
                            onClick={() => handleSortClick(IAgreementsSortFields.CUSTOMER_NAME)}>
                            <span>Klient</span>
                            <SortIcons
                                sortedByField={sortedByField}
                                sortDirection={sortDirection}
                                currentSortColumn={IAgreementsSortFields.CUSTOMER_NAME} />
                        </div>
                    </th>
                    <th className="sort-down-up-hover">
                        <div className="d-flex align-items-center"
                            onClick={() => handleSortClick(IAgreementsSortFields.CONTRACT_NUMBER)}>
                            <span>Leping</span>
                            <SortIcons
                                sortedByField={sortedByField}
                                sortDirection={sortDirection}
                                currentSortColumn={IAgreementsSortFields.CONTRACT_NUMBER} />
                        </div>
                    </th>
                    <th className="sort-down-up-hover">
                        <div className="d-flex align-items-center"
                            onClick={() => handleSortClick(IAgreementsSortFields.DATE_MODIFIED)}>
                            <span>Uuendatud</span>
                            <SortIcons
                                sortedByField={sortedByField}
                                sortDirection={sortDirection}
                                currentSortColumn={IAgreementsSortFields.DATE_MODIFIED} />
                        </div>
                    </th>
                    <th>Staatus</th>
                    <th>Allkirjad</th>
                    <th>Tegevused</th>
                </tr>
            </thead>
            <tbody>
                {props.isRequesting ?
                <tr>
                    <td colSpan={6} className="spinner-cell">
                        <div className="p-4 w-100 d-flex align-items-center justify-content-center">
                            <Spinner animation="grow" />
                        </div>
                    </td>
                </tr> :
                tableRows}
            </tbody>
            <tfoot>
            {pageResponse ? <tr>
                    <td colSpan={6}>
                        <div className="pagination-wrapper">
                            <Pagination
                                locale={locale}
                                current={pageResponse.number + 1}
                                total={pageResponse.totalElements}
                                pageSize={pageResponse.size}
                                prefixCls={"pagination"}
                                onChange={props.onPageChange}
                                showSizeChanger={false}
                                hideOnSinglePage={true}
                            />
                        </div>
                    </td>
                </tr> : null}
            </tfoot>
        </table>
    )
}

function getSigningStatusString(status: AgreementSigningStatus): string {
    switch (status) {
        case AgreementSigningStatus.PREPARED:
            return 'Allkirjad puuduvad';
        case AgreementSigningStatus.PENDING_VENDOR:
            return 'Kliendi poolt allkirjastatud';
        case AgreementSigningStatus.COMPLETED:
            return 'Allkirjastatud';
        case AgreementSigningStatus.PENDING_CUSTOMER:
            return 'Kliendi allkirja ootel';
        case AgreementSigningStatus.EXPIRED:
            return 'Aegunud';
        default:
            return '';
    }
}

const SigningStatusLabel = ({ agreement }: { agreement: IAgreement }) => {

    let className = 'client-status';
    const signingStatus = AgreementsService.getSigningStatus(agreement);
    if (signingStatus === AgreementSigningStatus.PREPARED) {
        className = 'prepared-status';
    } else if (signingStatus === AgreementSigningStatus.COMPLETED) {
        className = 'signed-status';
    } else if (signingStatus === AgreementSigningStatus.EXPIRED) {
        className = 'expired-status';
    }

    return (
        <div className={className}>
            {getSigningStatusString(signingStatus)}
        </div>
    )
}
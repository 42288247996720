import axios from 'axios';
import {apiService} from "../../services/api.service";
import {convertToPageable, downloadFile, handleResponse, handleReportResponse, extractData, handleApiException} from "../../helpers/apiResponseHelper";
import { ElectricityAgreementForm, MeteringPoint, INDIVIDUAL_PACKAGE_VALUE, UpdateElectricityAgreementPackageModalForm } from './models';
import moment from 'moment';
import { isoDateFormat } from 'common/dateFormatterConfig';
import { normalizeCustomersInvoiceRecipientEmail } from 'helpers';

async function getElectricityAgreements(pageRequest: any) {
    return axios.get(apiService.getApiUrl() + '/electricity/agreements/admin', {
        params: pageRequest
    }).then(handleResponse).then(convertToPageable).catch(handleApiException);
}

async function getActiveElectricityAgreements(customerId: number) {
    return axios.get(apiService.getApiUrl() + `/electricity/agreements/admin/customer/${customerId}/active`)
        .then(handleResponse).then(extractData);
}

async function createElectricityAgreementOffer(request: ElectricityAgreementForm, meteringPoints: MeteringPoint[]) {
    // TODO: TT-751 uue kliendi lisamine - fields for new customer
    return axios.post(apiService.getApiUrl() + '/electricity/agreements/admin/offer', {
        customerId: request.customerId,
        contactPersonId: request.contactId,
        registryNumber: request.registryNumber,
        companyName: request.customerName,
        email: request.email,
        phone: request.phone,
        address: request.address,
        city: request.city,
        zip: request.zip,
        county: request.county,
        producer: request.sellCheck,
        meteringPoints: meteringPoints,
        consumptionPackage: {
            id: request.consumptionPackage.id !== INDIVIDUAL_PACKAGE_VALUE ? request.consumptionPackage.id : null,
            nameEt: request.consumptionPackage.nameEt,
            nameEn: request.consumptionPackage.nameEn,
            basePrice: request.consumptionPackage.basePrice,
            basePriceTextEt: request.consumptionPackage.basePriceTextEt,
            basePriceTextEn: request.consumptionPackage.basePriceTextEn,
            monthlyFee: request.consumptionPackage.monthlyFee,
            termsAndConditionsId: request.consumptionPackage.termsAndConditionsId,
            contractTitle: request.consumptionPackage.contractTitle,
            contractPeriodId: request.contractPeriodId,
            pdfFooterDisclaimer: request.consumptionPackage.pdfFooterDisclaimer,
            marketPricePackage: request.consumptionPackage.marketPricePackage,
            pdfProductDescriptionOnInvoice: request.consumptionPackage.pdfProductDescriptionOnInvoice,
            externalProductDescriptionOnInvoice: request.consumptionPackage.externalProductDescriptionOnInvoice,
            jointInvoicePackage: request.isJointInvoicePossible ? request.consumptionPackage.jointInvoicePackage : false,
            individual: request.consumptionPackage.id === INDIVIDUAL_PACKAGE_VALUE
        },
        endDate: request.contractPeriodId !== 8 ? null : moment(request.endDate).startOf('day').add(3, 'hours').format(isoDateFormat),
        productionPackageId: request.sellCheck ? request.productionPackageId : null,
        consumptionMarginal: request.consumptionMarginal,
        productionMarginal: request.productionMarginal,
        guarantee: request.guarantee,
        consolidatedInvoice: request.consolidatedInvoice,
        firstName: request.firstName,
        lastName: request.lastName,
        personalIdentificationCode: request.contactPersonCode,
        offerDeadline: request.offerDeadline ? moment(request.offerDeadline!).startOf('day').add(3, 'hours').format('YYYY-MM-DD') : null,
        invoiceRecipientEmail: normalizeCustomersInvoiceRecipientEmail(request.invoiceRecipientEmail)
    }).then(handleResponse).then(extractData).catch(handleApiException);
}

async function createElectricityAgreement(request: ElectricityAgreementForm, meteringPoints: MeteringPoint[]) {
    // TODO: TT-751 uue kliendi lisamine - fields for new customer
    return axios.post(apiService.getApiUrl() + '/electricity/agreements/admin', {
        customerId: request.customerId,
        contactPersonId: request.contactId,
        registryNumber: request.registryNumber,
        companyName: request.customerName,
        email: request.email,
        phone: request.phone,
        address: request.address,
        city: request.city,
        zip: request.zip,
        county: request.county,
        bankAccountNumber: request.eInvoice ? request.bankAccountNumber : null,
        producer: request.sellCheck,
        meteringPoints: meteringPoints,
        startDate: moment(request.startDate).startOf('day').add(3, 'hours').format(isoDateFormat),
        endDate: request.contractPeriodId !== 8 ? null : moment(request.endDate).startOf('day').add(3, 'hours').format(isoDateFormat),
        //contract period with id 8 is of Tähtajaline
        consumptionPackage: {
            id: request.consumptionPackage.id !== INDIVIDUAL_PACKAGE_VALUE ? request.consumptionPackage.id : null,
            nameEt: request.consumptionPackage.nameEt,
            nameEn: request.consumptionPackage.nameEn,
            basePrice: request.consumptionPackage.basePrice,
            basePriceTextEt: request.consumptionPackage.basePriceTextEt,
            basePriceTextEn: request.consumptionPackage.basePriceTextEn,
            monthlyFee: request.consumptionPackage.monthlyFee,
            termsAndConditionsId: request.consumptionPackage.termsAndConditionsId,
            contractTitle: request.consumptionPackage.contractTitle,
            contractPeriodId: request.contractPeriodId,
            pdfFooterDisclaimer: request.consumptionPackage.pdfFooterDisclaimer,
            marketPricePackage: request.consumptionPackage.marketPricePackage,
            pdfProductDescriptionOnInvoice: request.consumptionPackage.pdfProductDescriptionOnInvoice,
            externalProductDescriptionOnInvoice: request.consumptionPackage.externalProductDescriptionOnInvoice,   
            jointInvoicePackage: request.isJointInvoicePossible ? request.consumptionPackage.jointInvoicePackage : false,
            individual: request.consumptionPackage.id === INDIVIDUAL_PACKAGE_VALUE
        },
        productionPackageId: request.sellCheck ? request.productionPackageId : null,
        consumptionMarginal: request.consumptionMarginal,
        productionMarginal: request.productionMarginal,
        guarantee: request.guarantee,
        consolidatedInvoice: request.consolidatedInvoice,
        eInvoice: request.eInvoice,
        firstName: request.firstName,
        lastName: request.lastName,
        personalIdentificationCode: request.contactPersonCode,
        invoiceRecipientEmail: normalizeCustomersInvoiceRecipientEmail(request.invoiceRecipientEmail)
    }).then(handleResponse).then(extractData).catch(handleApiException);
}

async function getElectricityAgreementFileWithDownload(electricityAgreementId: number, name: string) {
    return axios.get(apiService.getApiUrl() + '/electricity/agreements/admin/file?electricityAgreementId=' + electricityAgreementId,
        {
            responseType: 'blob'
        }).then(handleResponse).then(response => downloadFile(name, response));
}

async function getElectricityAgreementFile(electricityAgreementId: number) {
    return axios.get(apiService.getApiUrl() + '/electricity/agreements/admin/file?electricityAgreementId=' + electricityAgreementId,
        {
            responseType: 'blob'
        }).then(handleResponse);
}

async function getElectricityAgreementReportExcel() {
    return axios.get(apiService.getApiUrl() + '/reports/admin/electricity/agreements-excel', {
        responseType: 'blob'
    }).then(handleReportResponse).then((res) => downloadFile(res.fileName, res.data));
}

async function getElectricityContractPeriods() {
    return axios.get(apiService.getApiUrl() + '/electricity/contract-period')
        .then(handleResponse).then(extractData);
}

async function changeElectricityAgreementsPackage(agreementId: number, request: UpdateElectricityAgreementPackageModalForm) {
    return axios.post(apiService.getApiUrl() + '/electricity/agreements/admin/change-package/' + agreementId, {
          consumptionPackageId: request.consumptionPackage?.id !== -1 ? request.consumptionPackage?.id : null,
          consumptionMarginal: request.consumptionMarginal,
          productionMarginal: request.productionMarginal,
          nameEt: request.consumptionPackage?.nameEt,
          nameEn: request.consumptionPackage?.nameEn,
          marketPricePackage: request.consumptionPackage?.marketPricePackage,
          pdfProductDescriptionOnInvoice: request.consumptionPackage?.pdfProductDescriptionOnInvoice,
          externalProductDescriptionOnInvoice: request.consumptionPackage?.externalProductDescriptionOnInvoice,
          jointInvoicePackage: request.consumptionPackage?.jointInvoicePackage,
          basePrice: request.consumptionPackage?.basePrice,
          basePriceTextEt: request.consumptionPackage?.basePriceTextEt,
          basePriceTextEn: request.consumptionPackage?.basePriceTextEn,
          monthlyFee: request.consumptionPackage?.monthlyFee,
          termsAndConditionsId: request.consumptionPackage?.termsAndConditionsId,
          contractTitle: request.consumptionPackage?.contractTitle,
          pdfFooterDisclaimer: request.consumptionPackage?.pdfFooterDisclaimer,
          individual: request.consumptionPackage?.isIndividual,
          startDate: moment(request.startDate).startOf('day').add(3, 'hours').format(isoDateFormat),
          invoiceRecipientEmail: normalizeCustomersInvoiceRecipientEmail(request.invoiceRecipientEmail)
    }).then(handleResponse).then(extractData);
}

async function cancelElectricityAgreement(agreementId : number) {
    return axios.post(apiService.getApiUrl() + `/electricity/agreements/admin/cancel/${agreementId}`, {})
        .then(handleResponse).then(extractData);
}

interface UpdateElectricityAgreementRequest {
    invoiceRecipientEmail?: string;
    paidFromCustomersAdvance: boolean;
}

async function updateElectricityAgreement(agreementId: number, request: UpdateElectricityAgreementRequest){
    return axios.patch(apiService.getApiUrl() + `/electricity/agreements/admin/${agreementId}`, 
    {
        ...request
    }).then(handleResponse).then(extractData);
}

export const ElectricityAgreementsService = {
    getElectricityAgreements,
    getElectricityAgreementFileWithDownload,
    getElectricityAgreementFile,
    getElectricityAgreementReportExcel,
    createElectricityAgreementOffer,
    createElectricityAgreement,
    getElectricityContractPeriods,
    changeElectricityAgreementsPackage,
    cancelElectricityAgreement,
    getActiveElectricityAgreements,
    updateElectricityAgreement
};
export interface IPageResponse<T> {
    content: Array<T>;
    pageable: IPagePageableResponse;
    totalPages: number;
    totalElements: number;
    last: boolean;
    size: number;
    number: number;
    first: boolean;
    sort: IPageSortResponse;
    numberOfElements: number;
    empty: boolean;
}

interface IPageSortResponse {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
}

interface IPagePageableResponse {
    sort: IPageSortResponse;
    pageSize: number;
    pageNumber: number;
    offset: number;
    unpaged: boolean;
    paged: boolean;
}


export enum SortDirection {
    ASC = 'ASC',
    DESC = 'DESC'
}
/* eslint-disable react-hooks/exhaustive-deps */
import { TermsAndConditionsService } from "components/TermsAndConditions/TermsAndConditions.service";
import React, { useEffect, useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import { ElectricityAgreementForm, INDIVIDUAL_PACKAGE_VALUE } from "../../models";
import DatePicker from "react-date-picker";
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar.svg';
import { ElectricityAgreementsService } from "../../ElectricityAgreements.service";
import moment from "moment";

interface NewElectricityAgreementDetailsFormProps {
    form: ElectricityAgreementForm;
    setForm: (form: ElectricityAgreementForm) => void;
    isOffer: boolean;
    earliestPossibleAgreementStartDate?: Date;
}

export default function NewElectricityAgreementDetailsForm(
    { form, setForm, isOffer, earliestPossibleAgreementStartDate } : NewElectricityAgreementDetailsFormProps
) {

    const [termsAndConditionsOptions, setTermsAndConditionsOptions] = useState<any[]>([]);
    const [electricityContractPeriods, setElectricityContractPeriods] = useState<any[]>([]);

    useEffect(() => {
        getTermsAndConditions();
        getContractPeriods();
    }, []);

    const getTermsAndConditions = () => {
        TermsAndConditionsService.getTermsAndConditions()
            .then(res => setTermsAndConditionsOptions(
                res.content.map(c => ({ value: c.id, label: c.name }))
            ));
    }

    const getContractPeriods = () => {
        ElectricityAgreementsService.getElectricityContractPeriods()
            .then(res => {
                let periods : any = res.map((c:any) => ({ value: c.id, label: c.name }));
                setElectricityContractPeriods(periods)
            });
    }

    const currentTermsAndConditionsOption = termsAndConditionsOptions.find(t => t.value === form.consumptionPackage.termsAndConditionsId) || null;
    const currentContractPeriod = electricityContractPeriods.find(p => p.value === form.contractPeriodId) || null;

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-6">
                    <Form.Label>Lepingu pealkiri*</Form.Label>
                    <Form.Control
                        value={form.consumptionPackage.contractTitle}
                        disabled={form.consumptionPackage.id !== INDIVIDUAL_PACKAGE_VALUE}
                        onChange={(e: any) => setForm({
                            ...form, consumptionPackage: {
                                ...form.consumptionPackage,
                                contractTitle: e.target.value
                            }
                        })}
                        required
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
                { form.consumptionPackage.id === INDIVIDUAL_PACKAGE_VALUE ? 
                <div className="col-12 col-md-6">
                    <Form.Label>Tüüptingimused*</Form.Label>
                    <Select
                        options={termsAndConditionsOptions}
                        value={currentTermsAndConditionsOption}
                        onChange={(val: any) => setForm({
                            ...form,
                            consumptionPackage: {
                                ...form.consumptionPackage,
                                termsAndConditionsId: val?.value
                            }
                        })}
                        className={'w-100 form-control form-select' + (!form.consumptionPackage.termsAndConditionsId && form.validated ? ' is-invalid' : '')}
                        placeholder='Vali elektripaketi tüüptingimus'
                        required
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div> : null }                
            </div>
            <div className="row mt-3">
                <div className="col-6">
                    <Form.Label>Lepingu periood*</Form.Label>
                    <Select
                        options={electricityContractPeriods}
                        value={currentContractPeriod}
                        onChange={(val: any) => setForm({
                            ...form,
                            contractPeriodId: val.value
                        })}
                        className={'w-100 form-control form-select' + (!form.contractPeriodId && form.validated ? ' is-invalid' : '')}
                        placeholder='Vali lepingu periood'
                        required
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
                {
                    currentContractPeriod?.label === "Tähtajaline" ? (
                        <div className="col-6">
                            <Form.Label htmlFor="contract-end">Lepingu lõpp*</Form.Label>
                            <DatePicker
                                className={'d-block' + (form.endDate === undefined && form.validated ? ' invalid is-invalid' : '')}
                                value={form.endDate}
                                locale='et'
                                clearIcon={null}
                                calendarIcon={<CalendarIcon />}
                                format='dd.MM.yyyy'
                                minDate={moment(form.startDate).add(1, "d").toDate()}
                                onChange={(event: any) => {
                                    setForm({
                                        ...form, 
                                        endDate: event ? new Date(event) : undefined
                                    })
                                }}
                            />
                            <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                        </div>
                    ) : null
                }
            </div>            
            <div className="row mt-3">
                <div className="col-12">
                    <Form.Label>
                        Lepingu eritingimused*
                        <OverlayTrigger delay={250}
                            overlay={
                                <Tooltip id={`tooltip-base-price-text`}>
                                    <p className='m-1'>Kuvatakse lepingu jaluses</p>
                                    <p>Tingimuste vormindamiseks on kasutatud HTML süntaksi</p>
                                    <p>Reavahe tekitamise jaoks kasutada sümbolit <code>{'<br />'}</code></p>
                                    <p>Tekstilõiku (paragraph) saab lisada järgmiselt: <code>{'<p>Lõigu tekst</p>'}</code></p>
                                </Tooltip>} placement={"right"}>
                                <i className='icon__info icon__info--masked bg-dark ml-2 mb-n1'></i>
                        </OverlayTrigger>                        
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        value={form.consumptionPackage.pdfFooterDisclaimer}
                        rows={6}
                        disabled={form.consumptionPackage.id !== INDIVIDUAL_PACKAGE_VALUE}
                        required
                        onChange={(e: any) => setForm({
                            ...form, consumptionPackage: {
                                ...form.consumptionPackage,
                                pdfFooterDisclaimer: e.target.value
                            }
                        })}
                    />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-12 col-md-6">
                    {
                        isOffer ? (
                            <>
                                <Form.Label htmlFor="offer-deadline">Pakkumuse tähtaeg*</Form.Label>
                                    <DatePicker
                                        className={'d-block' + (form.offerDeadline === undefined && form.validated ? ' invalid is-invalid' : '')}
                                        value={form.offerDeadline}
                                        locale='et'
                                        clearIcon={null}
                                        calendarIcon={<CalendarIcon />}
                                        format='dd.MM.yyyy'
                                        minDate={new Date()}
                                        onChange={(event: any) => {
                                            setForm({
                                            ...form, offerDeadline: event ? new Date(event) : undefined
                                            })
                                        }}
                                    />
                                 <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                            </>
                        ) : (
                            <>
                                <Form.Label htmlFor="contract-start">Lepingu algus*</Form.Label>
                                    <DatePicker
                                        className={'d-block' + (form.startDate === undefined && form.validated ? ' invalid is-invalid' : '')}
                                        value={form.startDate}
                                        locale='et'
                                        clearIcon={null}
                                        calendarIcon={<CalendarIcon />}
                                        format='dd.MM.yyyy'
                                        minDate={earliestPossibleAgreementStartDate}
                                        onChange={(event: any) => {
                                            if(!event){
                                                return;
                                            }
                                            const dayDiff = moment(form.endDate).diff(event, 'days', true)
                                            setForm({
                                                ...form, 
                                                startDate: new Date(event),
                                                endDate: dayDiff < 1 ? moment(event).add(1, "d").toDate() : form.endDate
                                            })
                                        }}
                                    />
                                 <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                            </>
                        )
                    }
                </div>
            </div>
        </>
    );
}
import React, {useState} from 'react';
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import {IEditUserModalProps} from "../models";
import {UsersService} from "../Users.service";

export function EditUserModal(props: IEditUserModalProps) {
    const [saving, setSaving]: [boolean, any] = useState(false);
    const [savingError, setSavingError]: [boolean, any] = useState(false);
    const [adminActive, setAdminActive] = useState(props.editingUser && props.editingUser.adminActive !== null ? props.editingUser.adminActive : true);

    const validateSubmit = () => {
        if (props.editingUser?.adminActive !== adminActive) {
            handleSubmit();
        }
    };

    const handleSubmit = async () => {
        setSaving(true);
        setSavingError(false);

        try{
            await UsersService.patchUser(props.editingUser.id, adminActive);
            setTimeout(() => {
                props.submitCloseHandler();
            }, 300);
        }catch(e){
            console.error('Saving failed', e);
            setSavingError(true);
        }finally{
            setSaving(false);
        }
    };

    return (
        <Modal
            show={true}
            size={"sm"}
            onHide={props.rejectCloseHandler}
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    Kasutaja andmete muutmine
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={(e: any) => e.preventDefault()}
                      id={"user-form"}
                      className={'w-100'}
                >
                    <div className='row mb-2'>
                        <div className="col-5">
                            Kasutaja nimi
                        </div>
                        <div className="text-start font-weight-bold">
                            {props.editingUser.name}
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className="col-5">
                            Isikukood
                        </div>
                        <div className="text-start font-weight-bold">
                            {props.editingUser.personalIdentificationCode}
                        </div>
                    </div>
                    <Form.Group controlId={'form-admin-active'} className="row">
                        <Form.Label className={'col-5'}>
                            <span className="text-nowrap">Staatus</span>
                        </Form.Label>
                        <Form.Check
                            inline
                            className="form-check-label-black"
                            label="Aktiivne"
                            type="checkbox"
                            checked={adminActive}
                            disabled={saving}
                            onChange={(e: any) => setAdminActive(e.target.checked)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>

            <Modal.Footer className="d-flex flex-row align-items-end justify-content-around flex-wrap mb-1">
                <Button variant="info"
                        onClick={() => props.rejectCloseHandler()}
                        type="button"
                        className={"d-flex align-items-center"}>
                    <span className='px-2'>Tühista</span>
                </Button>
                <Button 
                    variant="primary" 
                    onClick={validateSubmit} 
                    type="button" 
                    disabled={saving || props.editingUser?.adminActive === adminActive}
                    className="d-flex align-items-center"
                >
                    {
                        !saving &&
                        <span className='px-2'>Salvesta</span>
                    }
                    {
                        saving &&
                        <>
                            <span className='px-2'>Salvestab…</span>
                            <Spinner size={"sm"} animation={"border"}/>
                        </>
                    }
                </Button>

            </Modal.Footer>
            {
                !saving && savingError &&
                <span className="d-flex justify-content-end text-danger px-2 pb-2">
                    Viga salvestamisel, proovi mõne hetke pärast uuesti.
                </span>
            }
        </Modal>
    );
}

import React, {PureComponent} from "react";

import {IPageResponse, SortDirection} from "../common/interfaces/IPageResponse";
import {
    IElectricityPackage,
    IElectricityPackageSortFields,
    IElectricityPackagesState
} from "../components/ElectricityPackages/models";

import {ModalType} from "../common/common-models";
import {ElectricityPackagesService} from "../components/ElectricityPackages/ElectricityPackages.service";
import {EditElectricityPackageModal} from "../components/ElectricityPackages/modals/EditElectricityPackageModal";
import {DeleteElectricityPackageConfirmModal} from "../components/ElectricityPackages/modals/DeleteElectricityPackageConfirmModal";
import {ElectricityPackagesTable} from "../components/ElectricityPackages/ElectricityPackagesTable";
import {ElectricityPackagesSearchBar} from "components/ElectricityPackages/ElectricityPackagesSearchBar";

export default class ElectricityPackages extends PureComponent<any,IElectricityPackagesState> {
    constructor(props: any) {
        super(props);
        this.state = {
            electricityPackages: null,
            pageRequest: {
                page: 0,
                size: 20,
                sortDirection: SortDirection.ASC,
                sortField: IElectricityPackageSortFields.ID,
                searchQuery: null
            },
            modalType: ModalType.CREATE,
            isEditModalVisible: false,
            isDeleteModalVisible: false
        };
        this.props.onLoadValidatePage();
    }

    getElectricityPackages = () => {
        ElectricityPackagesService.getElectricityPackages(this.state.pageRequest).then((response: IPageResponse<IElectricityPackage>) => {
            this.setState({
                electricityPackages: response
            })
        });
    };

    onPageChange = (targetPage: number) => {
        if (this.state.pageRequest.page + 1 !== targetPage) {
            this.setState({
                pageRequest: {
                    ...this.state.pageRequest,
                    page: targetPage - 1
                }
            }, () => this.getElectricityPackages());
        }
    };

    openElectricityPackageModal = (modalType: ModalType, electricityPackage?: IElectricityPackage) => {
        this.setState({
            isEditModalVisible: true,
            isDeleteModalVisible: false,
            modalType: modalType,
            electricityPackage: electricityPackage
        })
    };

    openDeleteElectricityPackageModal = (electricityPackage: IElectricityPackage) => {
        this.setState({
            isEditModalVisible: false,
            isDeleteModalVisible: true,
            electricityPackage: electricityPackage
        })
    };

    componentDidMount(): void {
        this.getElectricityPackages();
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col">
                        <ElectricityPackagesSearchBar openElectricityPackageModal={this.openElectricityPackageModal}/>
                            {this.state.electricityPackages !== null ?
                            <ElectricityPackagesTable electricityPackages={this.state.electricityPackages}
                                                 openElectricityPackageModal={this.openElectricityPackageModal}
                                                 openDeleteElectricityPackageModal={this.openDeleteElectricityPackageModal}
                            /> :
                            null
                        }
                    </div>
                </div>
                {
                    this.state.isEditModalVisible &&
                    <EditElectricityPackageModal
                        handleModalHide={() => {this.setState({isEditModalVisible: false})}}
                        modalType={this.state.modalType}
                        editingElectricityPackage={this.state.electricityPackage}
                        loadElectricityPackages={this.getElectricityPackages}
                    />
                }
                {
                    this.state.isDeleteModalVisible && this.state.electricityPackage &&
                    <DeleteElectricityPackageConfirmModal
                        handleRejectClose={() => {
                            this.setState({isDeleteModalVisible: false})
                        }}
                        handleSubmitClose={() => {
                            this.setState({isDeleteModalVisible: false},
                                () => {
                                    this.getElectricityPackages();
                                });
                        }}
                        electricityPackage={this.state.electricityPackage}/>
                }
            </div>
        );
    }
}
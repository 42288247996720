import axios from "axios";
import { downloadFile, extractData, extractFileNameFromResponse, handleResponse } from "helpers/apiResponseHelper";
import { apiService } from "services/api.service";

async function findProductionTablesForAgreement(contractNumber: string) {
  return axios.get(apiService.getApiUrl() + `/electricity/production-tables/admin/agreement/${contractNumber}`)
    .then(handleResponse).then(extractData);
}

async function findProductionTablesForCustomer(customerId: number) {
  return axios.get(apiService.getApiUrl() + `/electricity/production-tables/admin/customer/${customerId}`)
    .then(handleResponse).then(extractData);
}

async function getProductionTableFile(productionTableId: number, isCorrection? : boolean) {
  return axios.get(apiService.getApiUrl() + `/electricity/production-tables/admin/${productionTableId}/file?isCorrection=${isCorrection}`, {
      responseType: 'blob'
  }).then(res => handleResponse(res, true)).then((res) => downloadFile(
    extractFileNameFromResponse(res, "Mõõteandmete tabel.xlsx"),
    res.data
  ));
}

export const ElectricityProductionTablesService = {
  findProductionTablesForAgreement,
  findProductionTablesForCustomer,
  getProductionTableFile
};
import axios from 'axios';
import {apiService} from "../../services/api.service";
import {convertToPageable, extractData, handleResponse} from "../../helpers/apiResponseHelper";
import {IPetrolStationForm} from "./models";

async function getPetrolStations(pageRequest: any) {
    return axios.get(apiService.getApiUrl() + '/petrol-stations/admin', {
        params: pageRequest
    }).then(handleResponse).then(convertToPageable);
}

async function getStationFeatures() {
    return axios.get(apiService.getApiUrl() + '/station-features')
        .then(handleResponse).then(convertToPageable);
}

async function getStationProducts(petrolStationId: number) {
  return axios.get(apiService.getApiUrl() + `/petrol-stations/admin/${petrolStationId}/products`)
  .then(handleResponse).then(extractData);
}

async function postPetrolStation(request: IPetrolStationForm) {
    return axios.post(apiService.getApiUrl() + '/petrol-stations/admin',
        {
            ...request
        }).then(handleResponse).then(extractData);
}

async function putPetrolStation(petrolStationId: number, request: IPetrolStationForm) {
    return axios.put(apiService.getApiUrl() + '/petrol-stations/admin/' + petrolStationId,
        {
            ...request
        }).then(handleResponse).then(extractData);
}

async function deletePetrolStation(petrolStationId: number) {
    return axios.delete(apiService.getApiUrl() + '/petrol-stations/admin/' + petrolStationId)
        .then(handleResponse).then(extractData);
}

async function getPetrolStationsImage(id: number) {  
    return axios.get(apiService.getApiUrl() + `/petrol-stations/admin/${id}/file`,
      {
        responseType: 'blob'
      }
    ).then(handleResponse);
  }
  
  async function uploadPetrolStationsImage(file: File, petrolStationId: number) {
    const formData = new FormData();
    
    formData.append("file", file)
  
    return axios({
      method: 'POST',
      url: apiService.getApiUrl() + `/petrol-stations/admin/${petrolStationId}/file`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    }).then(handleResponse).then(extractData);
  }

export const PetrolStationsService = {
    getPetrolStations,
    getStationFeatures,
    getStationProducts,
    postPetrolStation,
    putPetrolStation,
    deletePetrolStation,
    getPetrolStationsImage,
    uploadPetrolStationsImage
};
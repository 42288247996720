import React from 'react';
import {Button, Modal} from "react-bootstrap";

interface IAddUsersConfirmModalProps {
    handleConfirmSubmit: () => void;
    handleConfirmReject: () => void;
}

export function AddUsersConfirmModal(props: IAddUsersConfirmModalProps) {

    const confirmSubmit = () => {
        props.handleConfirmSubmit();
    };
    const rejectSubmit = () => {
        props.handleConfirmReject();
    };

    return (
        <>
            <Modal
                show={true}
                size={"sm"}
                centered
            >
                <Modal.Header>
                    <Modal.Title>
                        Kinnita muudatused
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    Kas oled kindel, et soovid valitud kasutajad lisada?
                </Modal.Body>
                <Modal.Footer className="d-flex flex-row align-items-end justify-content-around flex-wrap mb-1">
                    <Button variant="info"
                            onClick={() => rejectSubmit()}
                            type="button"
                            className={"d-flex align-items-center"}>
                        <span className='px-2'>Tühista</span>
                    </Button>
                    <Button variant="primary"
                            onClick={confirmSubmit}
                            type="button"
                            className="d-flex align-items-center">
                        <span className='px-2'>Salvesta</span>
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

import React from "react";
import {ELECTRICITY_PACKAGE_TYPE_MAP, IElectricityPackage, IElectricPackageTableProps} from "./models";
import {dateFormatOptions} from "../../common/dateFormatterConfig";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {ModalType} from "../../common/common-models";
import Pagination from "rc-pagination";
import {locale} from "../../common/table/paginatorConfig";

export function ElectricityPackagesTable(props: IElectricPackageTableProps) {
    const electricityPackages: Array<IElectricityPackage> = props.electricityPackages.content;
    const pageResponse = props.electricityPackages;

    const electricityPackageTableRows = electricityPackages.map((electricityPackage: IElectricityPackage) =>
        <tr key={'row-' + electricityPackage.id}>
            <td>
                {electricityPackage.id}
            </td>
            <td>
                {electricityPackage.nameEt}
            </td>
            <td>
                {ELECTRICITY_PACKAGE_TYPE_MAP[electricityPackage.type]}
            </td>
            <td>
                {electricityPackage.basePrice || electricityPackage.basePriceTextEt}
            </td>
            <td>

            </td>
            <td>
                {electricityPackage.dateModified ? new Intl.DateTimeFormat('et', dateFormatOptions).format(new Date(electricityPackage.dateModified)) : ""}
            </td>
            <td>
                <div className="row__actions">
                    <OverlayTrigger delay={250}
                                    overlay={
                                        <Tooltip id={`tooltip-${electricityPackage.id}`}>
                                            <p style={{margin: '3px'}}>Muuda</p>
                                        </Tooltip>} placement={"left"}>
                        <Button size="sm" variant="outline-dark"
                                className="d-flex align-items-center border-color-background border-radius-circle btn-outline-icon"
                                onClick={(e: any) => {
                                    props.openElectricityPackageModal(ModalType.EDIT, electricityPackage);
                                }}>
                            <i className="icon--16 my-2 icon__edit--outline-mask"/>
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger delay={250}
                                    overlay={
                                        <Tooltip id={`tooltip-${electricityPackage.id}`}>
                                            <p style={{margin: '3px'}}>Kustuta</p>
                                        </Tooltip>} placement={"left"}>
                        <Button size="sm" variant="outline-danger"
                                className="d-flex align-items-center border-color-background border-radius-circle btn-outline-icon"
                                onClick={(e: any) => {
                                    props.openDeleteElectricityPackageModal(electricityPackage);
                                }}>
                            <i className="icon--16 my-2 icon__delete--outline-mask"/>
                        </Button>
                    </OverlayTrigger>
                </div>
            </td>
        </tr>
    );

    return (
        <table className='generic-table table table-responsive-md table-hover'>
            <thead>
            <tr>
                <th>Id</th>
                <th>Nimi</th>
                <th>Tüüp</th>
                <th>Baashind</th>
                <th></th>
                <th>Viimati muudetud</th>
                <th>Tegevused</th>
            </tr>
            </thead>
            <tbody>
            {electricityPackageTableRows}
            </tbody>
            <tfoot>
            <tr>
                <td colSpan={5}>
                    <div className="pagination-wrapper">
                        <Pagination
                            locale={locale}
                            current={pageResponse.number + 1}
                            total={pageResponse.totalElements}
                            pageSize={pageResponse.size}
                            prefixCls={"pagination"}
                            showSizeChanger={false}
                            hideOnSinglePage={true}
                        />
                    </div>
                </td>
            </tr>
            </tfoot>
        </table>
    )
}
import {IPageResponse} from "../../common/interfaces/IPageResponse";
import {IPageRequest} from "../../common/interfaces/IPageRequest";
import {ModalProps, ModalType} from "../../common/common-models";
import { UploadedFile } from "components/FileUpload/FileUploadFormGroup";

export interface IPetrolStationsState {
    petrolStations: IPageResponse<IPetrolStation> | null;
    stationFeatures: IPageResponse<IStationFeature> | null;
    pageRequest: IPageRequest<IPetrolStationSortFields>;
    isEditModalVisible: boolean;
    isDeleteModalVisible: boolean;
    modalType: ModalType;
    petrolStation?: IPetrolStation
}

export interface IPetrolStation {
    id: number;
    nameEt: string;
    nameEn: string | null;
    nameRu: string | null;
    descriptionEt: string | null;
    descriptionEn: string | null;
    descriptionRu: string | null;
    locationEt: string | null;
    locationEn: string | null;
    locationRu: string | null;
    latitude: number | null;
    longitude: number | null;
    externalId: string | null;
    dateModified: string | null;
    enabledFeatures: Array<number> | [];
    enabledProducts: Array<number> | [];
    originalFileName: string | null;
}

export interface IPetrolStationTableProps {
    petrolStations: IPageResponse<IPetrolStation>;
    openPetrolStationModal: (modalType: ModalType, petrolStation?: IPetrolStation) => void;
    openDeletePetrolStationModal: (petrolStation: IPetrolStation) => void;
    onPageChange: (targetPage: number) => void;
}

export interface IStationFeature {
    id: number;
    nameEt: string;
    nameEn: string;
    nameRu: string;
    code: string;
    dateCreated: string;
}

export enum IPetrolStationSortFields {
    ID = "ID",
    NAME_ET = "NAME_ET",
    NAME_EN = "NAME_EN",
    NAME_RU = "NAME_RU",
    DATE_MODIFIED = "DATE_MODIFIED"
}
export interface IPetrolStationsSearchBarProps {
    openPetrolStationModal: (modalType: ModalType, petrolStation?: IPetrolStation) => void;
}

export interface IPetrolStationConfirmModalProps extends ModalProps {
    stationFeatures: Array<IStationFeature>;
    editingPetrolStation?: IPetrolStation;
    loadPetrolStations: () => void;
}

export interface IPetrolStationForm {
    nameEt: string;
    locationEt: string;
    descriptionEt: string;
    externalId: string;
    latitude: string;
    longitude: string;
    enabledFeatures: Array<number>;
    image? : UploadedFile
}
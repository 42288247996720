import AwesomeDebouncePromise from "awesome-debounce-promise";
import { IPageResponse, SortDirection } from "common/interfaces/IPageResponse";
import { SpecialOfferService } from "components/SpecialOffers/SpecialOffer.service";
import { SpecialOffersSearchBar } from "components/SpecialOffers/SpecialOffersSearchBar";
import { SpecialOffersTable } from "components/SpecialOffers/SpecialOffersTable";
import { UpdateSpecialOfferModal } from "components/SpecialOffers/modals/UpdateSpecialOfferModal";
import { ISpecialOffer, SpecialOfferFilters, SpecialOfferSortFields, SpecialOffersState } from "components/SpecialOffers/model";
import React from "react";
import { PureComponent } from "react";
import { toast } from "react-toastify";

const searchApi = AwesomeDebouncePromise(
  request => SpecialOfferService.getSpecialOffers(request),
  300
);

export default class SpecialOffers extends PureComponent<any, SpecialOffersState> {
  constructor(props: any) {
      super(props);
      this.state = {
        specialOffers: null,
        pageRequest: {
          page: 0,
          size: 20,
          sortDirection: SortDirection.ASC,
          sortField: SpecialOfferSortFields.ID,
          searchQuery: null
        },
        filters: { },
        isRequesting: false,
        isUpdateModalVisible: false,
        isSyncing: false
      };
      this.props.onLoadValidatePage();
  }

  getSpecialOffers(){
    SpecialOfferService.getSpecialOffers(this.state.pageRequest).then((response: IPageResponse<ISpecialOffer>) => {
      this.setState({
        specialOffers: response
      });
    })
  }

  handleFilterChange = (change: SpecialOfferFilters) => {
    this.setState({
        specialOffers: null,
        pageRequest: {
            page: 0,
            size: this.state.pageRequest.size,
            sortDirection: this.state.pageRequest.sortDirection,
            sortField: this.state.pageRequest.sortField
        },
        filters: {
            ...this.state.filters,
            ...change
        },
        isRequesting: false,
    }, () => {
        this.searchSpecialOffers({
            ...this.state.filters,
            ...this.state.pageRequest,
            page: 0
        });
    });
  };

  searchSpecialOffers = (request: any) => {
    this.setState({
        isRequesting: true
    });
    searchApi(request).then((response: IPageResponse<ISpecialOffer>) => {
        this.setState({
            specialOffers: response, 
            isRequesting: false
        });
    })
  };

  onPageChange = (targetPage: number) => {
    const page = targetPage - 1;

    this.setState({
        pageRequest: {
            ...this.state.pageRequest,
            page
        }
    }, () => this.searchSpecialOffers({
      ...this.state.pageRequest,
      ...this.state.filters,
      page
    }));
  };

  handleSortClick = (sortField: SpecialOfferSortFields) => {
    let sortDirection = SortDirection.ASC;
    if (this.state.pageRequest.sortField.match(sortField)) {
        sortDirection = this.state.pageRequest.sortDirection === SortDirection.DESC ? SortDirection.ASC : SortDirection.DESC;
    }
    this.setState({
        pageRequest: {
            ...this.state.pageRequest,
            sortDirection,
            sortField
        }
    }, () => this.onPageChange(1))
  };

  onUpdateSpecialOffer = (specialOffer: ISpecialOffer) => {
    this.setState({ 
        isUpdateModalVisible: true,
        activeSpecialOffer: specialOffer
    })
  }

  onSyncSpecialOffers = async () => {
    this.setState({ isSyncing: true });
    try {
      await SpecialOfferService.syncSpecialOffers();
      this.onPageChange(1);
      toast("Eripakkumised uuendatud!", {
        position: "top-right",
        type: 'success'
      });
    } catch(e) {
      console.error(e);
      toast("Eripakkumiste uuendamine ebaõnnestus", {
        position: "top-right",
        type: 'error'
      })
    } finally {
      this.setState({ isSyncing: false });
    }
  }

  componentDidMount(): void {
    this.getSpecialOffers();
  }

  render() {
    return (
        <div className="container">
            <div className="row">
                <div className="col">
                  <SpecialOffersSearchBar
                      filters={this.state.filters}
                      onFilter={this.handleFilterChange}
                      isSyncing={this.state.isSyncing}
                      syncSpecialOffers={this.onSyncSpecialOffers}
                  />
                  {
                    this.state.specialOffers !== null ?
                      <SpecialOffersTable
                          onPageChange={this.onPageChange}
                          onSort={this.handleSortClick}
                          sortDirection={this.state.pageRequest.sortDirection}
                          sortField={this.state.pageRequest.sortField}
                          specialOffers={this.state.specialOffers}
                          updateSpecialOffer={this.onUpdateSpecialOffer}
                      /> :
                    null
                  }
                </div>
            </div>
            {(this.state.isUpdateModalVisible && this.state.activeSpecialOffer) ? 
              <UpdateSpecialOfferModal
                handleModalHide={() => {
                  this.setState({ 
                    isUpdateModalVisible: false
                   });
                }}
                specialOffer={this.state.activeSpecialOffer}
                onSuccess={() => this.searchSpecialOffers({
                  ...this.state.pageRequest,
                  searchQuery: this.state.filters.searchQuery
                })}
              />
            : null }
        </div>
    );
  }
}
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {Button, Spinner} from "react-bootstrap";
import {useHistory} from 'react-router-dom'
import { AuthenticationService, POLL_MAX_RETRY_COUNT_ON_FAILED_REQUEST } from "../services/authentication.service";
import {InitContextProps, useAuthContext} from "../services/context/authentication.context";
import { LOCAL_STORAGE_KEYS } from 'common/constants/localStorageKeys';
import { handleTextInputNumberChange } from 'helpers';
import * as webEid from '@web-eid/web-eid-library/web-eid';

interface ISmartIdForm {
  code: string,
  validationCode: string
}

interface IFakeAuthForm {
  code: string,
  password: string
}

interface MobileIdForm {
  code: string,
  phoneNumber: string,
  validationCode: string
}

interface IAuthenticationMethods {
  id: string;
  title: string;
  icon?: string;
  showInMobile: boolean
}

const AUTHENTICATION_METHODS: IAuthenticationMethods[] = [
  { id: 'smart-id', title: 'Smart-ID', icon: 'icon__smart-id', showInMobile: true },
  { id: 'id-card', title: 'ID-kaart', icon: 'icon__id-card', showInMobile: false },
  { id: 'mobile-id', title: 'Mobiil-ID', icon: 'icon__mobile-id', showInMobile: true },
  ...(process.env.REACT_APP_WITH_FAKE_AUTH ? [{ id: 'fake-auth', title: 'Fake-Auth', showInMobile: true }] : [])
];

export function Login(props: { onLoadValidatePage: () => void, match: any}) {
  useEffect(() => {
    props.onLoadValidatePage();
  }, [props.match.path]);

  let history = useHistory();
  const [activeMethod, setActiveMethod] = useState('smart-id');
  const [invalid, setInvalid] = useState(false);
  const [smartIdForm, setSmartIdForm]: [ISmartIdForm, any] = useState({ code: "", validationCode: "" });
  const [fakeAuthForm, setFakeAuthForm]: [IFakeAuthForm, any] = useState({ code: "", password: "" });
  const [mobileIdForm, setMobileIdForm]: [MobileIdForm, any] = useState({ code: "", phoneNumber: "", validationCode: "" });
  const [loading, setLoading]: [boolean, any] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  const {dispatch}: InitContextProps = useAuthContext();

  const handleFakeAuthSubmit = (e: any) => {
    e.preventDefault();
    if (loading)
      return;

    setLoading(true);
    setInvalid(false);

    if(fakeAuthForm.code.length !== 11 || !fakeAuthForm.password){
      setLoading(false);
      setInvalid(true);
    }

    AuthenticationService.loginWithFakeAuth(fakeAuthForm.code, fakeAuthForm.password)
    .then(() => handleSuccessfulLogin())
    .catch(() => {
      setLoading(false);
      setInvalid(true);
    });
  };

  const handleSmartIdSubmit = (e: any) => {
    e.preventDefault();
    if (loading)
      return;

    setLoading(true);
    setInvalid(false);
    setSmartIdForm({ ...smartIdForm, validationCode: "" });
    if (smartIdForm.code.length === 11) {
      AuthenticationService.loginWithSmartId(smartIdForm.code).then((verificationCode: string) => {
        setSmartIdForm({ ...smartIdForm, validationCode: verificationCode });
        pollSmartOrMobileIdLogin(verificationCode, 'smart-id')
      }).catch((reason: number) => {
        setLoading(false);
        setInvalid(true);
      });
    } else {
      setLoading(false);
      setInvalid(true);
    }
  };

  const handleMobileIdSubmit = (e: any) => {
    e.preventDefault();
    if (loading)
      return;

    setLoading(true);
    setInvalid(false);
    setSmartIdForm({ ...smartIdForm, validationCode: "" });
    if (mobileIdForm.code.length === 11 && mobileIdForm.phoneNumber.length) {
      AuthenticationService.loginWithMobileId(mobileIdForm.code, mobileIdForm.phoneNumber).then((verificationCode: string) => {
        setMobileIdForm({ ...smartIdForm, validationCode: verificationCode });
        pollSmartOrMobileIdLogin(verificationCode, 'mobile-id')
      }).catch((reason: number) => {
        setLoading(false);
        setInvalid(true);
      });
    } else {
      setLoading(false);
      setInvalid(true);
    }
  };

  const setMethod = (input: string) => {
    setActiveMethod(input);
    if (invalid) {
      setInvalid(false);
    }
  };

  const handleSuccessfulLogin = () => {
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION_HEADER)) {
      const token = localStorage.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION_HEADER);

      setInvalid(false);
      setLoading(false);

      dispatch({
        type: "setToken",
        value: token
      });

      dispatch({
        type: "setAuthenticated",
        value: true
      });
      
      history.push("tanklad");
    }
  }

  function pollSmartOrMobileIdLogin(verificationCode: string, type: 'smart-id' | 'mobile-id') {
    AuthenticationService.pollSmartOrMobileIdLogin(type === 'smart-id' ? smartIdForm.code : mobileIdForm.code, verificationCode, type)
      .then(
        (response: string) => {
          if (response === "RUNNING")
            setTimeout(() => pollSmartOrMobileIdLogin(verificationCode, type), 3000);
          else if (response === "FAILED") {
            setLoading(false);
            setInvalid(true);
          } else {
            handleSuccessfulLogin();
          }
        })
      .catch((error: any) => {
        if (retryCount === POLL_MAX_RETRY_COUNT_ON_FAILED_REQUEST) {
          console.error(error.message)
          setRetryCount(0);
          setLoading(false);
          setInvalid(true);

        } else {
          console.error(error)
          console.error(error.message)
          setRetryCount(retryCount + 1);
          setTimeout(() => pollSmartOrMobileIdLogin(verificationCode, type), 3000);
        }

      });
  }

  const loginIdCard = async () => {
    setLoading(true);
    setInvalid(false);

    try {
      const nonce = await AuthenticationService.getIdCardChallenge();
      const authToken = await webEid.authenticate(nonce, {lang: "et"});
      
      await AuthenticationService.postIdCardLogin(authToken);

      handleSuccessfulLogin()
      setLoading(false);
    } catch (error) {
      console.error(error);
      setInvalid(true);
      setLoading(false);
    }
  }

  const populateMethods = AUTHENTICATION_METHODS.map((method) => (
    <button
      key={method.id}
      type='button'
      className={
        'button-login flex-row align-items-center mx-1 ' +
        (method.id === activeMethod ? ' active' : '') +
        (!method.showInMobile ? ' d-none d-md-flex' : ' d-flex')
      }
      onClick={() => setMethod(method.id)}
    >
      {method.id !== 'fake-auth' ? (
        <i className={'mr-2 icon--24 ' + method.icon} />
      ) : null} 
      <span>
        {method.title}

      </span>
    </button>
  ));

  function methodInput() {
    const errorText = <small>
      <b>Viga sisselogimisel!</b>
      </small>
    switch (activeMethod) {
      case 'smart-id':
        return (
          <div className="d-flex flex-column align-items-center justify-content-center w-13-rem mb-auto">
            {
              loading && smartIdForm.validationCode.length > 0 &&
              <span className={"p-1"}>Teie kontrollkood on:  <strong>{smartIdForm.validationCode}</strong></span>
            }
            <form className="login-form" onSubmit={(e) => handleSmartIdSubmit(e)}>
              <input
                type="text"
                className="login__smart-id-input w-13-rem"
                placeholder='Isikukood'
                value={smartIdForm.code}
                onChange={(e) => setSmartIdForm({ ...smartIdForm, code: handleTextInputNumberChange(smartIdForm.code, e.target.value) })}
                disabled={loading}
              />
            </form>
            <Button variant="primary" type="submit" className={"mt-2 px-5"}
                disabled={loading}
                onClick={(e: any) => {
                  handleSmartIdSubmit(e)
                }}>
                {
                  !loading &&
                  <span>Sisene</span>
                }
                {
                  loading &&
                  <Spinner animation={"grow"} size={"sm"} />
                }
              </Button>
            {
              invalid &&
              <h5 className={"text-danger text-center text-bold mx-auto mt-2"}>{errorText}</h5>
            }
          </div>
        );
      case 'mobile-id':
        return (
          <div className="d-flex flex-column align-items-center justify-content-center w-13-rem mb-auto">
            {
              loading && mobileIdForm.validationCode.length > 0 &&
              <span className={"p-1"}>Teie kontrollkood on:  <strong>{mobileIdForm.validationCode}</strong></span>
            }
            <form className="login-form" onSubmit={(e: any) => handleMobileIdSubmit(e)}>
              <div className="d-flex flex-column">
                <input
                  type="text"
                  className="login__smart-id-input mb-1"
                  placeholder='Isikukood'
                  value={mobileIdForm.code}
                  onChange={(e) => setMobileIdForm({ ...mobileIdForm, code: handleTextInputNumberChange(mobileIdForm.code, e.target.value) })}
                  disabled={loading}
                />
                <div className="d-flex flex-row justify-content-center">
                  <input
                    type="text"
                    className="mobile-id-input__prefix mr-1"
                    placeholder='+372'
                    disabled
                  />
                  <input
                    type="tel"
                    className="mobile-id-input__main"
                    placeholder='Telefoninumber'
                    disabled={loading}
                    value={mobileIdForm.phoneNumber}
                    onChange={(e) => setMobileIdForm({ ...mobileIdForm, phoneNumber: handleTextInputNumberChange(mobileIdForm.phoneNumber, e.target.value) })}
                  />
                </div>
              </div>
            </form>
            <Button variant="primary" type="submit" className={"mt-2 px-5"}
                disabled={loading}
                onClick={(e: any) => {
                  handleMobileIdSubmit(e)
                }}>
                {
                  !loading &&
                  <span>Sisene</span>
                }
                {
                  loading &&
                  <Spinner animation={"grow"} size={"sm"} />
                }
              </Button>
            {
              invalid &&
              <h5 className={"text-danger text-center text-bold mx-auto mt-2"}>{errorText}</h5>
            }
          </div>
        );
        case 'id-card':
          return (
            <div className="login__id-card-input mb-auto">
              <p className="text-center">
                Sisesta ID-kaart kaardilugejasse ja vajuta nuppu "Sisene"
              </p>
              <div className="d-flex justify-content-center mt-4">
                <Button variant="primary" type="submit" className={"px-5"}
                  disabled={loading}
                  onClick={() => loginIdCard()}>
                  {
                    !loading &&
                    <span>Sisene</span>
                  }
                  {
                    loading &&
                    <Spinner animation={"grow"} size={"sm"} />
                  }
                </Button>
              </div>
              {
                invalid &&
                <h5 className={"text-danger text-center text-bold mx-auto mt-2"}>{errorText}</h5>
              }
            </div>
          );
        case 'fake-auth' :
          return (
            <div className="d-flex flex-column align-items-center justify-content-center w-13-rem mb-auto">
              <form className="login-form d-flex flex-column" onSubmit={(e) => handleSmartIdSubmit(e)}>
                <input
                  type="text"
                  className="login__smart-id-input w-13-rem mb-2"
                  placeholder='Isikukood'
                  value={fakeAuthForm.code}
                  onChange={(e) => setFakeAuthForm({ ...fakeAuthForm, code: handleTextInputNumberChange(fakeAuthForm.code, e.target.value) })}
                  disabled={loading}
                />
                 <input
                  type="password"
                  className="login__smart-id-input w-13-rem"
                  placeholder='Parool'
                  value={fakeAuthForm.password}
                  onChange={(e) => setFakeAuthForm({ ...fakeAuthForm, password: e.target.value })}
                  disabled={loading}
                />
              </form>
              <Button variant="primary" type="submit" className={"mt-2 px-5"}
                  disabled={loading}
                  onClick={(e: any) => {
                    handleFakeAuthSubmit(e)
                  }}>
                  {
                    !loading &&
                    <span>Sisene</span>
                  }
                  {
                    loading &&
                    <Spinner animation={"grow"} size={"sm"} />
                  }
                </Button>
              {
                invalid &&
                <h5 className={"text-danger text-center text-bold mx-auto mt-2"}>{errorText}</h5>
              }
            </div>
          );
    }
  }

  return (
    <div className="login__container d-flex flex-column justify-content-center align-items-center w-100">
      <div>
        {
          populateMethods.length > 1 &&
          <>
            <h1 className='font-weight-light text-center mb-0 text-black-50'>
              Vali tuvastamise viis
            </h1>
            <p className='mb-4 text-black-50 u-fs-14'>Kui kliendikontot ei ole, saad selle registreerida pärast tuvastamist</p>
          </>
        }
        <div className="d-flex flex-row flex-wrap justify-content-center mb-4 w-100">
          {populateMethods}
      </div>
      </div>
      {methodInput()}
    </div>
  )
}

import { AgreementSigningStatus, AGREEMENT_TYPE_FILTER_OPTIONS, IAgreement } from 'models/agreements'
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import AgreementsService from 'services/agreements.service'

import Button from 'react-bootstrap/Button'
import { SubmitModalProps } from 'common/common-models'

interface AgreementStatusChangeModalProps extends SubmitModalProps {
  agreement: IAgreement | null;
}

export default function AgreementStatusChangeModal(props: AgreementStatusChangeModalProps) {

  const [saving, setSaving]: [boolean, any] = useState(false);
  const [savingError, setSavingError]: [boolean, any] = useState(false);
  const [activeStatus, setActiveStatus] = useState<AgreementSigningStatus>((props.agreement && AgreementsService.getSigningStatus(props.agreement)) || AgreementSigningStatus.PREPARED);

  useEffect(() => {
    if (props.agreement) {
      setActiveStatus(AgreementsService.getSigningStatus(props.agreement));
    }
  }, [props.agreement]);

  const submit = async () => {
    if (!props.agreement) {
      return;
    }
    setSaving(true);
    AgreementsService.changeAgreementStatus(props.agreement.id, activeStatus).then(() => {
      setSaving(false);
      props.onSubmitSuccess();
    }).catch((e) => {
      setSavingError(true);
      setSaving(false);
    });
  };

  return (
      <Modal
        show={true}
        size={"lg"}
        onHide={props.handleModalHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Muuda staatus
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="agreement-status-change-statuses__wrapper">
            { AGREEMENT_TYPE_FILTER_OPTIONS.filter(opt => opt.value !== AgreementSigningStatus.EXPIRED).map((o, i) => (
            <div key={o.value} className={'d-flex p-3' + (activeStatus === o.value ? ' selected-card' : '')} onClick={() => setActiveStatus(o.value)}>
              <div className="custom-control custom-checkbox mr-sm-2">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={'check_' + o.value}
                  checked={activeStatus === o.value}
                  onChange={() => setActiveStatus(o.value)}
                  />
                <label className="custom-control-label cursor-pointer" htmlFor={'check_' + o.value}>{o.label}</label>
              </div>              
            </div>
          ))
          }
          </div>          
        </Modal.Body>
            <Modal.Footer className="d-flex flex-row align-items-end justify-content-around flex-wrap mb-1">
                <Button variant="info"
                        onClick={() => props.handleModalHide()}
                        type="button"
                        className={"d-flex align-items-center"}>
                    <span className='px-2'>Tühista</span>
                </Button>
                <Button variant="primary" onClick={submit} type="button" disabled={saving}
                        className="d-flex align-items-center">
                    {
                        !saving &&
                        <span className='px-2'>Salvesta</span>
                    }
                    {
                        saving &&
                        <>
                            <span className='px-2'>Salvestab…</span>
                            <Spinner size={"sm"} animation={"border"}/>
                        </>
                    }
                </Button>

            </Modal.Footer>
            {
                !saving && savingError &&
                <span className="d-flex justify-content-end text-danger px-2 pb-2">
                    Viga salvestamisel, proovi mõne hetke pärast uuesti.
                </span>
            }        
      </Modal>    
  )

}
import React, {PureComponent} from 'react';
import Select from 'react-select';
import Button from "react-bootstrap/Button";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from "react-bootstrap/Popover";
import {PermissionsModal} from "../Permissions/PermissionsModal";
import { ICustomerContactPerson } from './models';

interface IOptions {
    value: string;
    label: string;
}
interface ICustomerContactPersonsSearchBarState {
    selectedOptionA: string | any;
    selectedOptionB: string | any;
    searchQuery: any;
    isPermissionsModalOpen: boolean;
}

const optionsB: IOptions[] = [
    { value: 'Veebi ligipääs', label: 'Veebi ligipääs' },
    { value: 'Admin ligipääs', label: 'Admin ligipääs' },
];

const selectStyle = {
    indicatorSeparator: () => (
        {
            backgroundColor: 'red',
            width: '50px',
        }
    ),
};


export class CustomerContactPersonsSearchBar extends PureComponent<any, ICustomerContactPersonsSearchBarState> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedOptionA: null,
            selectedOptionB: null,
            searchQuery: null,
            isPermissionsModalOpen: false
        };
    }

    handleChangeB = (input: any) => {
        this.setState({
            selectedOptionB: input
        });
    };

    onInputChange = (inputEvent: any) => {
        this.setState({
            searchQuery: inputEvent.target.value
        });
        this.props.onFilter({
            searchQuery: inputEvent.target.value
        });

    };

    showPermissionsModal = () => this.setState({isPermissionsModalOpen: true});
    hidePermissionsModal = (refresh: boolean) => {
        if (refresh) {
            this.props.getCustomerContactPersons();
        }
        return this.setState({isPermissionsModalOpen: false});
    };

    render() {

        return (
            <div className="bar-position">
                <h2 className='text-light'>
                    Kliendikontaktid
                </h2>
                <div className="card mb-5 shadow">
                    <div className="card-body">
                        <div className='d-flex flex-wrap flex-md-nowrap'>
                            <input
                                placeholder="Isikukood / Nimi / Klient"
                                className='search-bar__input'
                                onChange={this.onInputChange}
                                value={this.state.searchQuery ? this.state.searchQuery : ""}
                            />

                            <Select
                                isDisabled={true}
                                style={selectStyle}
                                className='mw-160-px mr-4 customer-status__select'
                                value={this.state.selectedOptionB ? this.state.selectedOptionB : null}
                                onChange={this.handleChangeB}
                                options={optionsB}
                                placeholder='Vali ligipääs'
                            />

                            <div className="customer-contact-persons__search-row-buttons flex-wrap">
                                <OverlayTrigger
                                    trigger={["hover", "focus"]}
                                    placement={"top"}
                                    overlay={this.props.selectedRows.length === 0 ?
                                        <Popover id="popover-basic">
                                            <Popover.Title as="h4" className={"text-danger"}>Teade!</Popover.Title>
                                            <Popover.Content>
                                                Mitte ühtegi klienti ei ole valitud!
                                            </Popover.Content>
                                        </Popover>
                                        :
                                        <div></div> }>
                                    <div className="d-inline-block mt-2 mt-lg-0">
                                        <Button variant={"primary"}
                                                disabled={this.props.selectedRows.length === 0}
                                                className={"h-100"}
                                                onClick={() => this.showPermissionsModal()}
                                                style={{pointerEvents: (this.props.selectedRows.length === 0 ? 'none' : 'initial')}}>
                                            <span className="px-5">
                                                Õigused
                                            </span>
                                        </Button>
                                    </div>
                                </OverlayTrigger>
                                <Button
                                    variant='primary'
                                    className='d-flex align-items-center px-4'
                                    disabled={this.props.selectedRows.length === 0}
                                    onClick={() => this.props.onCustomerSync(Array.from(new Set(this.props.selectedRows.map((r: ICustomerContactPerson) => r.userId))))}
                                >
                                    <i className='icon__refresh-white text-white mr-2 ' />
                                    Uuenda kliente
                                </Button>
                                {
                                    this.state.isPermissionsModalOpen &&
                                    <PermissionsModal isVisible={this.state.isPermissionsModalOpen}
                                                      selectedCustomersContacts={this.props.selectedCustomerContactPersons}
                                                      handlePermissionsModalHide={this.hidePermissionsModal}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

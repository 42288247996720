import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {ICustomerContactPerson} from "../CustomerContactPersons/models";
import {PermissionsTable} from "./PermissionsTable";
import {PermissionsService} from "./Permissions.service";
import Spinner from "react-bootstrap/Spinner";
import { ADMIN_ROLES, ContactRole } from 'models/permissions';

interface IContactsMenuProps {
    isVisible: boolean;
    selectedCustomersContacts: Array<ICustomerContactPerson>;
    handlePermissionsModalHide: (refresh: boolean) => void;
}


export interface IAlteredCustomerContact {
    id: number;
    customerId: number;
    roles: ContactRole[];
}

export function PermissionsModal(props: IContactsMenuProps) {
    const [isConfirmationModalVisible, setIsConfirmationModalVisible]: [boolean, any] = useState(false);
    const [alteredCustomerContacts, setAlteredCustomerContacts]: [Array<IAlteredCustomerContact>, any] = useState([]);
    const [saving, setSaving]: [boolean, any] = useState(false);

    const submitConfirmation = () => setIsConfirmationModalVisible(true);
    const submitConfirmationCancel = () => setIsConfirmationModalVisible(false);

    const confirmChanges = () => {
        setSaving(true);
        const changedRows = [];
        for (const alteredCustomerContact of alteredCustomerContacts) {
            if (alteredCustomerContact.roles.includes(ContactRole.ADMIN)) {
                alteredCustomerContact.roles = alteredCustomerContact.roles.filter(r => !ADMIN_ROLES.includes(r))
            }
            const postPromise = PermissionsService.postRoleChanges(alteredCustomerContact);
            postPromise.catch(reason => console.error("Failed to change customer: " + alteredCustomerContact.id + " - " + reason));
            changedRows.push(postPromise)
        }
        Promise.all(changedRows).then(value => {
            setSaving(false);
            return props.handlePermissionsModalHide(true);
        }).catch(reason => {
            console.error('Saving failed', reason);
            setSaving(false);
        });
    };

    return (
        <>
            <Modal
                size={"xl"}
                show={props.isVisible}
                onHide={() => props.handlePermissionsModalHide(false)}>
                <Modal.Header>
                    <Modal.Title>
                        Halda õiguseid
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <PermissionsTable
                            alteredCustomerContacts={alteredCustomerContacts}
                            setAlteredCustomerContacts={setAlteredCustomerContacts}
                            customerContactPersons={props.selectedCustomersContacts}/>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex flex-row align-items-end justify-content-end flex-wrap mb-1">
                    <Button variant="info" onClick={() => props.handlePermissionsModalHide(false)} type="button" className={"d-flex align-items-center"}>
                        <span className='px-2'>Tühista</span>
                    </Button>
                    <Button variant="success" onClick={submitConfirmation} type="submit" className="d-flex align-items-center">
                        <span className='px-2'>Salvesta</span>
                    </Button>
                </Modal.Footer>
            </Modal>
            {
                isConfirmationModalVisible &&
                <Modal
                    size={"sm"}
                    show={props.isVisible}
                    backdrop={"static"}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={() => props.handlePermissionsModalHide(false)}>
                    <Modal.Header>
                        <Modal.Title>
                            Kinnita muudatused
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="">
                            Kas oled kindel, et soovid valitud kasutajatele õigused anda?
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="d-flex flex-row align-items-end justify-content-end flex-wrap mb-1">
                        <Button variant="info" onClick={submitConfirmationCancel} type="button" disabled={saving} className={"d-flex align-items-center"}>
                            <span className='px-2'>Tühista</span>
                        </Button>
                        <Button variant="success" onClick={confirmChanges} type="button" disabled={saving} className="d-flex align-items-center">
                            {
                                !saving &&
                                <span className='px-2'>Kinnita</span>
                            }
                            {
                                saving &&
                                <>
                                    <span className='px-2'>Salvestab…</span>
                                    <Spinner size={"sm"} animation={"border"}/>
                                </>
                            }
                        </Button>
                    </Modal.Footer>
                </Modal>

            }
        </>
    );
}
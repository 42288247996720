import React from "react";

interface FileComponentProps {
  onRemove: () => void;
  fileName: string
}

export function FileComponent({fileName, onRemove}: FileComponentProps){

  const getFormattedFilename = () => {
    if(!fileName){
      return fileName;
    }

    const parts = fileName.split(".");
    const extension = parts[parts.length - 1];

    return `${fileName.replace(extension, "").substring(0, 20)}.${extension}`
  }

  return (
    <div className="file-component__file d-flex justify-content-between">
      <div className="d-flex align-items-center">
        <div className='icon__img-file action-icon mr-2'/>
        <div className="mr-2 text-break" title={fileName}>{getFormattedFilename()}</div>
      </div>
      <div className="d-flex">
        <div className="seperator h-100"/>
        <div 
            className="d-flex align-items-center ml-2 mr-1"
            onClick={() => onRemove()}
        >
            <i className="icon__close-light"/>
        </div>
      </div>
    </div>
  )
}
import React from 'react';
import Pagination from "rc-pagination";
import {locale} from "../../common/table/paginatorConfig";
import {Button, OverlayTrigger, Spinner, Tooltip} from "react-bootstrap";
import { IPageResponse, SortDirection } from 'common/interfaces/IPageResponse';
import { ICustomer, ICustomerSortFields } from './model';
import { SortIcons } from 'components/SortIcons/SortIcons';

interface ElectricityCustomersTableProps {
  customers?: IPageResponse<ICustomer>;
  isRequesting: boolean;
  onPageChange: (page: number, pageSize: number) => void;
  onSort: (sortField: ICustomerSortFields) => void;
  sortField: ICustomerSortFields;
  sortDirection: SortDirection;
  configureCustomerSettings: (customer: ICustomer) => void;
  showProductionTables: (customer: ICustomer) => void;
}

export function ElectricityCustomersTable(props: ElectricityCustomersTableProps) {

    const customers: Array<ICustomer> = props.customers ? props.customers.content : [];
    const pageResponse = props.customers;

    const tableRows = customers.map((customer: ICustomer) => {
            return (
                <tr key={'row-' + customer.id}>
                  <td>
                      {customer.name}
                  </td>
                  <td>
                      {customer.eic}
                  </td>
                  <td>
                      {customer.registryNumber}
                  </td>
                  <td>
                      {customer.agreementsCount}
                  </td>
                  <td>
                      <div className="row__actions">
                        { 
                        <>
                            <OverlayTrigger delay={250}
                                overlay={
                                    <Tooltip id={`electricity-customer-tooltip-${customer.id}`}>
                                        <p style={{margin: '3px'}}>Kliendi seaded</p>
                                    </Tooltip>} placement={"left"}>
                                    <Button size="sm"
                                        variant={'secondary'}
                                        className="d-flex align-items-center justify-content-center btn-outline-icon ml-1" 
                                        onClick={() => props.configureCustomerSettings(customer)}>
                                    
                                        <div className='icon--16 icon__settings action-icon'/>
                                    </Button>
                            </OverlayTrigger>
                            <OverlayTrigger delay={250}
                                overlay={
                                <Tooltip id={`production-tables-tooltip-${customer.id}`}>
                                    <p style={{margin: '3px'}}>Tootmistabeleid</p>
                                </Tooltip>} placement={"left"}>
                                <Button size="sm"
                                    variant='secondary'
                                    className="d-flex align-items-center justify-content-center btn-outline-icon ml-1" 
                                    onClick={() => props.showProductionTables(customer)}>
                                
                                    <div className='icon--16 icon__table action-icon'/>
                                </Button>
                            </OverlayTrigger>
                        </>
                        }
                      </div>
                  </td>
              </tr>
            );
        }
    );

    return (
        <>
            <table className='customer-contact-persons__table table table-responsive-lg table-hover'>
                <thead>
                <tr>
                    <th className="sort-down-up-hover">
                      <div className="d-flex align-items-center"
                          onClick={() => props.onSort(ICustomerSortFields.NAME)}>
                          <span>Nimi</span>
                          <SortIcons
                              sortedByField={props.sortField}
                              sortDirection={props.sortDirection}
                              currentSortColumn={ICustomerSortFields.NAME} />
                      </div>
                    </th>        
                    <th className="sort-down-up-hover">
                      <div className="d-flex align-items-center"
                          onClick={() => props.onSort(ICustomerSortFields.EIC)}>
                          <span>Eic</span>
                          <SortIcons
                              sortedByField={props.sortField}
                              sortDirection={props.sortDirection}
                              currentSortColumn={ICustomerSortFields.EIC} />
                      </div>
                    </th>        
                    <th className="sort-down-up-hover">
                      <div className="d-flex align-items-center"
                          onClick={() => props.onSort(ICustomerSortFields.REGISTRY_NUMBER)}>
                          <span>Isikukood / Registrikood</span>
                          <SortIcons
                              sortedByField={props.sortField}
                              sortDirection={props.sortDirection}
                              currentSortColumn={ICustomerSortFields.REGISTRY_NUMBER} />
                      </div>
                    </th>        
                    <th>Lepingute arv</th>
                    <th>Tegevused</th>
                </tr>
                </thead>
                <tbody>
                    {props.isRequesting ?
                    <tr>
                        <td colSpan={10} className="spinner-cell">
                            <div className="p-4 w-100 d-flex align-items-center justify-content-center">
                                <Spinner animation="grow" />
                            </div>
                        </td>
                    </tr> :
                    tableRows}
                </tbody>
                {pageResponse ? <tfoot>
                <tr>
                    <td colSpan={10}>
                        <div className="pagination-wrapper">
                            <Pagination
                                locale={locale}
                                current={pageResponse.number + 1}
                                total={pageResponse.totalElements}
                                pageSize={pageResponse.size}
                                prefixCls={"pagination"}
                                onChange={props.onPageChange}
                                showSizeChanger={false}
                                hideOnSinglePage={true}
                            />
                        </div>
                    </td>
                </tr>
                </tfoot> : null}
            </table>
        </>
    )
}
import React, {PureComponent} from 'react';
import {Button, Spinner} from "react-bootstrap";
import Select from 'react-select';
import { ElectricityAgreementStatus, ELECTRICITY_AGREEMENT_STATUS_MAP, IElectricityAgreementsSearchBarProps } from './models';

const statuses = [
    { value: ElectricityAgreementStatus.ACTIVE, label: ELECTRICITY_AGREEMENT_STATUS_MAP[ElectricityAgreementStatus.ACTIVE] },
    { value: ElectricityAgreementStatus.ENDED, label: ELECTRICITY_AGREEMENT_STATUS_MAP[ElectricityAgreementStatus.ENDED] },
    { value: ElectricityAgreementStatus.PENDING, label: ELECTRICITY_AGREEMENT_STATUS_MAP[ElectricityAgreementStatus.PENDING] },
    { value: ElectricityAgreementStatus.REJECTED, label: ELECTRICITY_AGREEMENT_STATUS_MAP[ElectricityAgreementStatus.REJECTED] },
    { value: ElectricityAgreementStatus.CANCELED, label: ELECTRICITY_AGREEMENT_STATUS_MAP[ElectricityAgreementStatus.CANCELED] },
];

const selectStyle = {
    indicatorSeparator: () => (
        {
            backgroundColor: 'red',
            width: '50px',
        }
    ),
};

export class ElectricityAgreementsSearchBar extends PureComponent<IElectricityAgreementsSearchBarProps, any> {

    onInputChange = (inputEvent: any) => {
        this.props.onFilter({
            search: inputEvent.target.value
        });
    };

    onStatusChange = (value: any) => {
        this.props.onFilter({
            status: value?.value
        });
    }

    onPackageChange = (value: any) => {
        this.props.onFilter({
            packageName: value?.value
        });
    }

    render() {
        return (
            <div className="bar-position">
                <h2 className="text-light">
                    Elektrilepingud
                </h2>
                <div className="card mb-5 shadow">
                    <div className="card-body">
                        <div className="d-flex flex-wrap flex-sm-nowrap">
                            <input
                                placeholder="Klient / Lepingu number"
                                className='search-bar__input'
                                onChange={this.onInputChange}
                                value={this.props.filters.search || ''}
                            />

                            <Select
                                style={selectStyle}
                                className='mw-160-px mr-2 customer-status__select'
                                value={statuses.find(s => s.value === this.props.filters.status) || null}
                                onChange={this.onStatusChange}
                                options={statuses}
                                isClearable
                                placeholder='Vali staatus'
                            />

                            <Select
                                style={selectStyle}
                                className='w-13-rem mr-4 customer-status__select'
                                value={this.props.packages.find(s => s.value === this.props.filters.packageName) || null}
                                onChange={this.onPackageChange}
                                options={this.props.packages}
                                isClearable
                                placeholder='Vali pakett'
                            />
                            <div className="d-flex flex-nowrap ml-auto">
                                { process.env.REACT_APP_FEATURE_ELECTRICITY_AGREEMENT_ADD === 'true' ? <Button className="ml-auto d-flex align-items-center"
                                        onClick={this.props.createAgreementOffer}
                                        variant={"primary"}>
                                    <i className="icon--16 icon__add d-flex mr-1"/>
                                    <span>Uus pakkumine</span>
                                </Button> : null} 
                                { process.env.REACT_APP_FEATURE_ELECTRICITY_AGREEMENT_ADD === 'true' ? <Button className="ml-2 d-flex align-items-center"
                                        onClick={this.props.createAgreement}
                                        variant={"primary"}>
                                    <i className="icon--16 icon__add d-flex mr-1"/>
                                    <span>Uus leping</span>
                                </Button> : null} 
                                <Button className="ml-2 d-flex align-items-center"
                                        onClick={this.props.getElectricityAgreementsReport}
                                        variant={"primary"}
                                        disabled={this.props.isDisabled}
                                >
                                    {
                                    this.props.exporting ?
                                    <>
                                        <span className='px-2'>Eksportimine…</span>
                                        <Spinner size={"sm"} animation={"border"}/>
                                    </> : <span>Eksport</span>
                                    }
                                    
                                </Button>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        )
    }
}
import { PdfPreview } from "components/ElectricityAgreements/models";
import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { Document, Page } from "react-pdf";

interface PDFPreviewModalProps {
  show: boolean;
  onHide: () => void;
  onPrint?: () => void;
  onDownload?: () => void;
  isDownloading?: boolean;
  preview?: PdfPreview;
}

export default function PDFPreviewModal({
  show,
  onHide,
  onPrint,
  onDownload,
  isDownloading,
  preview
}: PDFPreviewModalProps) {
  const [pages, setPages] = useState<number>(0);

  const onLoadSuccess = ({ numPages }: { numPages: number }) => {
    setPages(numPages);
  };

  return (
    <Modal
      show={show}
      size={'lg'}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="pdf-preview-modal"
      centered
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Button
          variant="primary"
          className="d-flex align-items-center mr-2 pl-4 pr-4"
          onClick={onPrint}
        >
          Prindi
        </Button>
        <Button
          variant="primary"
          className="d-flex align-items-center pl-4 pr-4"
          onClick={onDownload}
        >
          <span>Lae alla</span>
          {isDownloading ? (
            <Spinner size={'sm'} animation={'border'} className="ml-1" />
          ) : null}
        </Button>
      </Modal.Header>

      <Modal.Body>
        <div className="pdf-preview-modal__container">
          { !!preview ? 
              <Document 
                  className="mx-n4 mx-lg-0" 
                  file={preview.objectUrl} 
                  onLoadError={console.error}
                  onLoadSuccess={onLoadSuccess}
              >
                  {Array.from({ length: pages }).map((_, index) => (
                      <Page key={index + 1} pageNumber={index + 1} />
                  ))}
              </Document> : null
          }
        </div>
      </Modal.Body>
    </Modal>
  );
}

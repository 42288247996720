import { ElectricityCustomerSettingsModalFormState } from "components/ElectrcityCustomers/modals/ElectricityCustomerSettingsModal";
import ElectricityContentBlock from "components/ElectricityAgreements/components/ElectricityAgreementContentBlock";
import { MeteringPoint } from "components/ElectricityAgreements/models";
import React, { Dispatch, SetStateAction } from "react"
import { Form } from "react-bootstrap";
import { CustomerInfo } from "./CustomerInfo";

interface CustomerMeteringPointProps {
  form: ElectricityCustomerSettingsModalFormState;
  setForm: Dispatch<SetStateAction<ElectricityCustomerSettingsModalFormState>>;
  meteringPoint: MeteringPoint;
}


export const CustomerMeteringPoint = ({form, setForm, meteringPoint}: CustomerMeteringPointProps) => {
  return (
    <div className="customer-invoice-groups w-100">
      <ElectricityContentBlock 
        title="Mõõtepunkt"
      >
        <CustomerInfo 
          title="EIC"
          value={meteringPoint.eic}
        />
        <CustomerInfo 
          title="Aadress"
          value={meteringPoint.address}
        />
        <CustomerInfo 
          title="Tootmisvõimsus"
          value={meteringPoint.productionCapacity ?? '-'}
        />
         <Form noValidate validated={form.validated}>
          <div className="col-3 p-0">
          <Form.Label>Laadimisjaama NOOM ID</Form.Label>
                <Form.Control
                    value={form.meteringPoint?.externalChargingStationId ?? ''}
                    onChange={(e: any) => setForm({
                        ...form, 
                        meteringPoint : {
                          ...form.meteringPoint,
                          externalChargingStationId: e.target.value
                        }
                    })}
                />
          </div>
        </Form>
      </ElectricityContentBlock>
    </div>
  )
}
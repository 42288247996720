import { IPageRequest } from "common/interfaces/IPageRequest";
import { IPageResponse } from "common/interfaces/IPageResponse";
import { PdfPreview } from "components/ElectricityAgreements/models";
import { ISimplifiedProductionTable } from "components/ElectricityProductionTables/models";

export interface IElectricityInvoice {
  id: number;
  invoiceNumber: number;
  dateIssued: string;
  dateDue: string;
  amountIncludingVat: number;
  amountExcludingVat: number;
  totalConsumption: number;
  totalProduction: number;
  consumptionAveragePrice: number;
  productionAveragePrice: number;
  fileName: string;
  customer: ElectricityInvoiceCustomer;
  agreement?: ElectricityInvoiceAgreement;
  invoiceGroup?: ElectricityInvoiceInvoiceGroup;
}

interface ElectricityInvoiceCustomer {
  id: number;
  name: string;
  eic: string;
}

interface ElectricityInvoiceAgreement {
  id: number;
  contractNumber: string;
}

interface ElectricityInvoiceInvoiceGroup {
  id: number;
  name: string;
}

export interface ISimplifiedInvoice {
  id: number;
  invoiceNumber: number;
  dateIssued: string;
}

export interface IElectricityInvoicesState {
  electricityInvoices: IPageResponse<IElectricityInvoice> | null;
  pageRequest: IPageRequest<IElectricityInvoiceSortFields>;
  filters: ElectricityInvoiceFilters;
  isRequesting: boolean;
  isDownloading: boolean;
  isExporting: boolean;
  pdfPreview?: PdfPreview;
  pdfPreviewLoading?: number;
}

export enum IElectricityInvoiceSortFields {
  ID = "ID",
  INVOICE_NUMBER = "INVOICE_NUMBER",
  DATE_ISSUED = 'DATE_ISSUED',
  DATE_DUE = 'DATE_DUE',
  AMOUNT_INCLUDING_VAT = 'AMOUNT_INCLUDING_VAT',
  AMOUNT_EXCLUDING_VAT = 'AMOUNT_EXCLUDING_VAT'
}

export interface ElectricityInvoiceFilters {
  search?: string;
}

export interface IInvoiceCreationModel {
  invoice: ISimplifiedInvoice,
  table: ISimplifiedProductionTable
}
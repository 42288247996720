import axios from 'axios';
import {apiService} from "../../services/api.service";
import {convertToPageable, extractData, handleResponse} from "../../helpers/apiResponseHelper";
import { UpdateLoyaltyCampaignModalForm } from './models';
import { LanguageOption } from 'common/common-models';

async function getLoyaltyCampaigns(pageRequest: any) {
  return axios.get(apiService.getApiUrl() + '/campaigns/admin', {
      params: pageRequest
  }).then(handleResponse).then(convertToPageable);
}

async function updateLoyaltyCampaign(request: UpdateLoyaltyCampaignModalForm) {  
  return axios.put(apiService.getApiUrl() + `/campaigns/admin/${request.campaign.id}`, {
    type: request.campaign.type,
    active: request.campaign.active,
    icon: request.icon,
    titleEt: request.campaign.titleEt,
    titleEn: request.campaign.titleEn,
    participatable: request.campaign.participatable
  }).then(handleResponse).then(extractData);
}

async function getLoyaltyCampaignsImage(id: number, lang: LanguageOption) {
  return axios.get(apiService.getApiUrl() + `/campaigns/admin/${id}/file?lang=${lang}`,
    {
      responseType: 'blob'
    }
  ).then(handleResponse);
}

async function uploadLoyaltyCampaignsImage(file: File, campaignId: number, lang: LanguageOption) {
  const formData = new FormData();
  
  formData.append("file", file)

  return axios({
    method: 'POST',
    url: apiService.getApiUrl() + `/campaigns/admin/${campaignId}/file?lang=${lang}`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  }).then(handleResponse).then(extractData);
}

async function syncCampaigns() {
  return axios.post(`${apiService.getApiUrl()}/campaigns/admin/sync`).then(handleResponse);
}

export const LoyaltyCampaignService = {
  getLoyaltyCampaigns,
  updateLoyaltyCampaign,
  getLoyaltyCampaignsImage,
  uploadLoyaltyCampaignsImage,
  syncCampaigns
};
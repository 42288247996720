import { ADMIN_ROLES, ContactRole } from 'models/permissions';
import React from 'react';
import {ICustomerContactPerson} from "../CustomerContactPersons/models";
import {IAlteredCustomerContact} from "./PermissionsModal";

export function PermissionsTable(props: {
    alteredCustomerContacts: Array<IAlteredCustomerContact>,
    setAlteredCustomerContacts: any,
    customerContactPersons: Array<ICustomerContactPerson>}
    ) {

    const handleToggleEvent = (role: ContactRole, contactId: number, customerId: number, state: boolean) => {
        const alreadyAltered = props.alteredCustomerContacts.find(item => item.id === contactId);
        if (alreadyAltered) {
            if (state === false) {
                alreadyAltered.roles = alreadyAltered.roles.filter(r => r !== role);
            } else if (!alreadyAltered.roles.includes(role)) {
                alreadyAltered.roles.push(role);
            }
            const restOfContacts = props.alteredCustomerContacts.filter(contact => contact.id !== contactId);
            props.setAlteredCustomerContacts([...restOfContacts, alreadyAltered]);
        } else {
            const contact = props.customerContactPersons.find((contact: ICustomerContactPerson) => contact.id === contactId);
            let roles: ContactRole[] = [];
            // init initial text roles to id-s
            if (contact && contact.customerRoles !== null && contact.customerRoles.length > 0) {
                for (const customerRole of contact.customerRoles) {
                    roles.push(customerRole);
                }
            }
            if (state === false) {
                roles = roles.filter(r => r !== role);
            } else {
                roles.push(role);
            }

            const newAlteredContact: IAlteredCustomerContact = { id: contactId, customerId, roles };
            props.setAlteredCustomerContacts([...props.alteredCustomerContacts, newAlteredContact]);
        }

        if (role === ContactRole.ADMIN) {
            ADMIN_ROLES.forEach(r => handleToggleEvent(r, contactId, customerId, true));
        }
    };

    const contactPersons = props.customerContactPersons.map((contactPerson: ICustomerContactPerson) =>
        <tr key={'row-' + contactPerson.id}>
            <td>
                {contactPerson.personalIdentificationCode}
            </td>
            <td>
                {contactPerson.contactName}
            </td>
            <td>
                {contactPerson.customerName}
            </td>
            <td className="text-center p-0">
                <label htmlFor={`admin-check-${contactPerson.id}`} className="m-0 px-3 cursor-pointer-hover">
                    <input id={`admin-check-${contactPerson.id}`} className="cursor-pointer-hover"
                           type="checkbox"
                           checked={
                               isInputChecked(props.alteredCustomerContacts, contactPerson.id, ContactRole.ADMIN, props.customerContactPersons)
                           }
                           onChange={(event => handleToggleEvent(ContactRole.ADMIN, contactPerson.id, contactPerson.customerId, event.target.checked))}/>
                </label>
            </td>
            <td className="text-center p-0">
                <label htmlFor={`agreements-check-${contactPerson.id}`} className="m-0 px-3 cursor-pointer-hover">
                    <input id={`agreements-check-${contactPerson.id}`} className="cursor-pointer-hover"
                           type="checkbox"
                           checked={
                               isInputChecked(props.alteredCustomerContacts, contactPerson.id, ContactRole.VIEW_AGREEMENTS, props.customerContactPersons) ||
                               isInputChecked(props.alteredCustomerContacts, contactPerson.id, ContactRole.ADMIN, props.customerContactPersons)
                           }
                           disabled={isInputChecked(props.alteredCustomerContacts, contactPerson.id, ContactRole.ADMIN, props.customerContactPersons)}
                           onChange={(event => handleToggleEvent(ContactRole.VIEW_AGREEMENTS, contactPerson.id, contactPerson.customerId, event.target.checked))}/>
                </label>
            </td>
            <td className="text-center p-0">
                <label htmlFor={`documents-check-${contactPerson.id}`} className="m-0 px-3 cursor-pointer-hover">
                    <input id={`documents-check-${contactPerson.id}`} className="cursor-pointer-hover"
                           type="checkbox"
                           checked={
                               isInputChecked(props.alteredCustomerContacts, contactPerson.id, ContactRole.VIEW_DOCUMENTS, props.customerContactPersons) ||
                               isInputChecked(props.alteredCustomerContacts, contactPerson.id, ContactRole.ADMIN, props.customerContactPersons)
                           }
                           disabled={isInputChecked(props.alteredCustomerContacts, contactPerson.id, ContactRole.ADMIN, props.customerContactPersons)}
                           onChange={(event => handleToggleEvent(ContactRole.VIEW_DOCUMENTS, contactPerson.id, contactPerson.customerId, event.target.checked))}/>
                </label>
            </td>
            <td className="text-center p-0">
                <label htmlFor={`document-rows-check-${contactPerson.id}`} className="m-0 px-3 cursor-pointer-hover">
                    <input id={`document-rows-check-${contactPerson.id}`} className="cursor-pointer-hover"
                           type="checkbox"
                           checked={
                               isInputChecked(props.alteredCustomerContacts, contactPerson.id, ContactRole.VIEW_DOCUMENT_ROWS, props.customerContactPersons) ||
                               isInputChecked(props.alteredCustomerContacts, contactPerson.id, ContactRole.ADMIN, props.customerContactPersons)
                           }
                           disabled={isInputChecked(props.alteredCustomerContacts, contactPerson.id, ContactRole.ADMIN, props.customerContactPersons)}
                           onChange={(event => handleToggleEvent(ContactRole.VIEW_DOCUMENT_ROWS, contactPerson.id, contactPerson.customerId, event.target.checked))}/>
                </label>
            </td>
            <td className="text-center p-0">
                <label htmlFor={`cards-check-${contactPerson.id}`} className="m-0 px-3 cursor-pointer-hover">
                    <input id={`cards-check-${contactPerson.id}`} className="cursor-pointer-hover"
                           type="checkbox"
                           checked={
                               isInputChecked(props.alteredCustomerContacts, contactPerson.id, ContactRole.VIEW_CARDS, props.customerContactPersons) ||
                               isInputChecked(props.alteredCustomerContacts, contactPerson.id, ContactRole.ADMIN, props.customerContactPersons)
                           }
                           disabled={isInputChecked(props.alteredCustomerContacts, contactPerson.id, ContactRole.ADMIN, props.customerContactPersons)}
                           onChange={(event => handleToggleEvent(ContactRole.VIEW_CARDS, contactPerson.id, contactPerson.customerId, event.target.checked))}/>
                </label>
            </td>
            <td className="text-center p-0">
                <label htmlFor={`sale-orders-check-${contactPerson.id}`} className="m-0 px-3 cursor-pointer-hover">
                    <input id={`sale-orders-check-${contactPerson.id}`} className="cursor-pointer-hover"
                           type="checkbox"
                           checked={
                               isInputChecked(props.alteredCustomerContacts, contactPerson.id, ContactRole.VIEW_SALE_ORDERS, props.customerContactPersons) ||
                               isInputChecked(props.alteredCustomerContacts, contactPerson.id, ContactRole.ADMIN, props.customerContactPersons)
                           }
                           disabled={isInputChecked(props.alteredCustomerContacts, contactPerson.id, ContactRole.ADMIN, props.customerContactPersons)}
                           onChange={(event => handleToggleEvent(ContactRole.VIEW_SALE_ORDERS, contactPerson.id, contactPerson.customerId, event.target.checked))}/>
                </label>
            </td>
            <td className="text-center p-0">
                <label htmlFor={`storage-check-${contactPerson.id}`} className="m-0 px-3 cursor-pointer-hover">
                    <input id={`storage-check-${contactPerson.id}`} className="cursor-pointer-hover"
                           type="checkbox"
                           checked={
                               isInputChecked(props.alteredCustomerContacts, contactPerson.id, ContactRole.SUPPLIER, props.customerContactPersons)
                           }
                           onChange={(event => handleToggleEvent(ContactRole.SUPPLIER, contactPerson.id, contactPerson.customerId, event.target.checked))}/>
                </label>
            </td>
            <td className="text-center p-0">
                <label htmlFor={`bookings-check-${contactPerson.id}`} className="m-0 px-3 cursor-pointer-hover">
                    <input id={`bookings-check-${contactPerson.id}`} className="cursor-pointer-hover"
                           checked={
                               isInputChecked(props.alteredCustomerContacts, contactPerson.id, ContactRole.BOOKER, props.customerContactPersons)
                           }
                           type="checkbox"
                           onChange={(event => handleToggleEvent(ContactRole.BOOKER, contactPerson.id, contactPerson.customerId, event.target.checked))}/>
                </label>
            </td>
        </tr>
    );

    return (
        <table className='table table-responsive-md table-hover'>
            <thead>
            <tr>
                <th>Isikukood</th>
                <th>Nimi</th>
                <th>Klient</th>
                <th className='text-center'>Peakasutaja</th>
                <th className='text-center'>Lepingud</th>
                <th className='text-center'>Arved</th>
                <th className='text-center'>Väljavõte</th>
                <th className='text-center'>Kaardid</th>
                <th className="text-center">Müügitellimused</th>
                <th className="text-center">Laoseis</th>
                <th className="text-center">Broneeringud</th>
            </tr>
            </thead>
            <tbody>
            {contactPersons}
            </tbody>
        </table>
    )
}

function isInputChecked(
    alteredCustomerContacts: Array<IAlteredCustomerContact>,
    contactId: number,
    role: ContactRole,
    customerContactPersons: Array<ICustomerContactPerson>
) {
    return alteredCustomerContacts.findIndex(
        contact => contact.id === contactId &&
            contact.roles.indexOf(role) !== -1) !== -1 ?
        true :
        customerContactPersons.findIndex((contact: ICustomerContactPerson) =>
            contact.id === contactId &&
            contact.customerRoles.indexOf(role) !== -1) !== -1 && alteredCustomerContacts.findIndex(
        contact => contact.id === contactId) === -1

}

import React, {useState} from 'react';
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import TanksService from 'services/tanks.service';
import {ModalType} from "../../../common/common-models";
import { ITank, PostPutTankRequest } from '../models';
import { EditTankConfirmModal } from "./EditTankConfirmModal";
import Select from 'react-select/async';
import { selectTheme } from 'components/Agreements/AgreementsSearchBar';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import CustomerService from 'services/customers.service';

const searchApi = AwesomeDebouncePromise(
    request => CustomerService.getCustomers(request),
    300
);

interface EditTankModalProps {
    modalType: ModalType;
    rejectCloseHandler: () => void;
    submitCloseHandler: () => void;
    tank?: ITank;
}

export function EditTankModal(props: EditTankModalProps) {
    const [saving, setSaving]: [boolean, any] = useState(false);
    const [savingError, setSavingError]: [boolean, any] = useState(false);
    const [validated, setValidated]: [boolean, any] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible]: [boolean, any] = useState(false);
    const [form, setForm] = useState({
        name: props.tank ? props.tank.name : '',
        deviceSerialNumber: props.tank ? props.tank.deviceSerialNumber : '',
        sensor: props.tank ? props.tank.sensor : '',
        capacity: props.tank && props.tank.capacity,
        notificationThreshold: props.tank && props.tank.notificationThreshold,
        customers: (props.tank && props.tank.customers && props.tank.customers.map(c => ({ value: c.id, label: c.name }))) || []
    });

    const validateForm = () => {
        if (!form.name.trim().length)
            return false;
        if (!form.deviceSerialNumber.trim().length)
            return false;
        if (!form.sensor.trim().length) {
            return false;
        }
        return true;
    };

    const validateSubmit = () => {
        setValidated(false);
        const isValidForm = validateForm();
        setValidated(true);
        if (isValidForm) {
            setIsConfirmModalVisible(true);
        } else {
            setSaving(false);
        }
    };

    const handleSubmit = () => {
        setSaving(true);
        setSavingError(false);
        const mappedForm = {
                ...form,
                customers: form.customers.map(c => ({ id: c.value, name: c.label})
            )} as PostPutTankRequest;
        if (props.modalType === ModalType.CREATE) {
            TanksService.postTank(mappedForm).then(() => {
                setSaving(false);
                setTimeout(() => {
                    props.submitCloseHandler();
                }, 300);
            }).catch((reason: any) => {
                console.error('Saving failed', reason);
                setSaving(false);
                setSavingError(true);
            });
        } else {
            if (!props.tank || !props.tank.id) {
                setSaving(false);
                console.error('Saving failed, no editable tank', props.tank);
                return;
            }

            TanksService.putTank(props.tank.id, mappedForm).then(() => {
                setSaving(false);
                setTimeout(() => {
                    props.submitCloseHandler();
                }, 300);
                props.submitCloseHandler();
            }).catch((reason: any) => {
                console.error('Saving failed', reason);
                setSaving(false);
                setSavingError(true);
            });
        }
    };

    const loadCustomers = (searchValue: string, callback: Function) => {
        if (!searchValue || searchValue.length < 3) {
            return callback([]);
        }
        searchApi(searchValue).then(res => {
            callback(res.map(c => ({ label: c.name, value: c.id })))
        });
    }

    return (
        <Modal
            show={true}
            size={"lg"}
            onHide={props.rejectCloseHandler}
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    {
                        props.modalType === ModalType.CREATE &&
                        <span>Mahuti lisamine</span>
                    }
                    {
                        props.modalType === ModalType.EDIT &&
                        <span>Mahuti andmete muutmine</span>
                    }
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={(e: any) => e.preventDefault()}
                      id={"user-form"} autoComplete="new-password"
                      className={'mt-1 row align-self-center m-0'}>
                    <Form.Group controlId={'form-name'} className="row col-12 p-0 m-0">
                        <Form.Label className={'mb-0 align-self-center m-1 text-start col-12 col-lg-3'}>
                            <span className="text-nowrap">Mahuti nimetus</span>
                        </Form.Label>
                        <Form.Control required className="col-12 col-lg-8"
                                      value={form.name}
                                      onChange={(e) => setForm({
                                          ...form,
                                          name: e.target.value
                                      })}
                                      disabled={saving}
                                      type="text"/>
                        <Form.Control.Feedback className={'col-12 m-1'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId={'form-serialnumber'} className="row col-12 p-0 m-0 mt-2">
                        <Form.Label className={'mb-0 align-self-center m-1 text-start col-12 col-lg-3'}>
                            <span className="text-nowrap">Seerianumber</span>
                        </Form.Label>
                        <Form.Control required className="col-12 col-lg-8"
                                      value={form.deviceSerialNumber}
                                      onChange={(e) => setForm({
                                          ...form,
                                          deviceSerialNumber: e.target.value
                                      })}
                                      disabled={saving}
                                      type="text"/>
                        <Form.Control.Feedback className={'col-12 m-1'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId={'form-sensor'} className="row col-12 p-0 m-0 mt-2">
                        <Form.Label className={'mb-0 align-self-center m-1 text-start col-12 col-lg-3'}>
                            <span className="text-nowrap">Sensor</span>
                        </Form.Label>
                        <Form.Control required className="col-12 col-lg-8"
                                    value={form.sensor}
                                    onChange={(e) => setForm({
                                        ...form,
                                        sensor: e.target.value
                                    })}
                                    disabled={saving}
                                    type="text"
                        />
                        <Form.Control.Feedback className={'col-12 m-1'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId={'form-capacity'} className="row col-12 p-0 m-0 mt-2">
                        <Form.Label className={'mb-0 align-self-center m-1 text-start col-12 col-lg-3'}>
                            <span className="text-nowrap">Maht</span>
                        </Form.Label>
                        <Form.Control required className="col-12 col-lg-8"
                                    type="number"
                                    value={form.capacity}
                                    onChange={(e) => setForm({
                                        ...form,
                                        capacity: e.target.value ? Number(e.target.value) : undefined
                                    })}
                                    disabled={saving}
                        />
                        <Form.Control.Feedback className={'col-12 m-1'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId={'form-threshold'} className="row col-12 p-0 m-0 mt-2">
                        <Form.Label className={'mb-0 align-self-center m-1 text-start col-12 col-lg-3'}>
                            <span className="text-nowrap">Näidu teavituskünnis</span>
                        </Form.Label>
                        <Form.Control required className="col-12 col-lg-8"
                                    type="number"
                                    value={form.notificationThreshold}
                                    onChange={(e) => setForm({
                                        ...form,
                                        notificationThreshold: e.target.value ? Number(e.target.value) : undefined
                                    })}
                                    disabled={saving}
                        />
                        <Form.Control.Feedback className={'col-12 m-1'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId={'form-threshold'} className="row col-12 p-0 m-0 mt-2">
                        <Form.Label className={'mb-0 align-self-center m-1 text-start col-12 col-lg-3'}>
                            <span className="text-nowrap">Kliendid</span>
                        </Form.Label>

                            <Select
                                cacheOptions
                                defaultOptions
                                loadOptions={loadCustomers}
                                value={form.customers}
                                onChange={(val: any) => setForm({
                                    ...form,
                                    customers: val || []
                                })}
                                className='w-100 col-12 col-lg-8 px-0'
                                placeholder='Vali kliendid'
                                isClearable
                                isMulti
                                theme={selectTheme}
                            />
                    </Form.Group>
                </Form>
            </Modal.Body>

            <Modal.Footer className="d-flex flex-row align-items-end justify-content-around flex-wrap mb-1">
                <Button variant="info"
                        onClick={() => props.rejectCloseHandler()}
                        type="button"
                        className={"d-flex align-items-center"}>
                    <span className='px-2'>Tühista</span>
                </Button>
                <Button variant="primary" onClick={validateSubmit} type="button" disabled={saving}
                        className="d-flex align-items-center">
                    {
                        !saving &&
                        <span className='px-2'>Salvesta</span>
                    }
                    {
                        saving &&
                        <>
                            <span className='px-2'>Salvestab…</span>
                            <Spinner size={"sm"} animation={"border"}/>
                        </>
                    }
                </Button>

            </Modal.Footer>
            {
                !saving && savingError &&
                <span className="d-flex justify-content-end text-danger px-2 pb-2">
                    Viga salvestamisel, proovi mõne hetke pärast uuesti.
                </span>
            }

            {
                validated && isConfirmModalVisible &&
                    <EditTankConfirmModal
                        tank={form as PostPutTankRequest}
                        handleConfirmSubmit={() => {
                            setIsConfirmModalVisible(false);
                            handleSubmit();
                        }}
                        handleConfirmReject={() => {
                            setSaving(false);
                            setIsConfirmModalVisible(false);
                        }}
                        modalType={props.modalType}/>
            }
        </Modal>
    );
}

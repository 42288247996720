import React, {PureComponent} from 'react';
import { ElectricityInvoiceFilters } from './models';
import { Button, Spinner } from 'react-bootstrap';

interface IElectricityInvoicesSearchBarProps {
  filters: ElectricityInvoiceFilters;
  onFilter: (filter: ElectricityInvoiceFilters) => void;
  exporting: boolean;
  generateInvoicesReport: () => void;
}

export class ElectricityInvoicesSearchBar extends PureComponent<IElectricityInvoicesSearchBarProps, any> {

    onInputChange = (inputEvent: any) => {
        this.props.onFilter({
            search: inputEvent.target.value
        });
    };

    render() {
        return (
            <div className="electricity-invoices__search-bar bar-position">
                <h2 className="text-light">
                    Elektriarved
                </h2>
                <div className="card mb-5 shadow">
                    <div className="card-body">
                        <div className="d-flex flex-wrap flex-sm-nowrap">
                            <input
                                placeholder="Klient / Lepingu number / Grupp"
                                className='search-bar__input p-2'
                                onChange={this.onInputChange}
                                value={this.props.filters.search || ''}
                            />
                            <div className="d-flex flex-nowrap ml-auto">
                                <Button 
                                    className="ml-2 d-flex align-items-center"
                                    onClick={this.props.generateInvoicesReport}
                                    variant={"primary"}
                                >
                                    {
                                    this.props.exporting ?
                                        <>
                                            <span className='px-2'>Eksportimine…</span>
                                            <Spinner size={"sm"} animation={"border"}/>
                                        </> : <span>Eksport</span>
                                    }
                                    
                                </Button>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        )
    }
}
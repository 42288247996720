import AddressAutocomplete from "components/AddressAutocomplete/AddressAutocomplete";
import { ICustomerContactPerson } from "components/CustomerContactPersons/models";
import React from "react";
import { Form, Spinner } from "react-bootstrap";
import Select from "react-select";
import { ElectricityAgreementForm } from "../../models";

interface NewElectricityAgreementCustomerFormProps {
    isCompany: boolean;
    form: ElectricityAgreementForm;
    setForm: (form: ElectricityAgreementForm) => void;
    isFormDisabled: boolean;
    showConsolidatedInvoiceCheckbox: boolean;
    onSearch: () => void;
    contactPersons: ICustomerContactPerson[];
    customerLoading: boolean;
    customerLoaded: boolean;
}

export default function NewElectricityAgreementCustomerForm(
    { isCompany, form, setForm, isFormDisabled, showConsolidatedInvoiceCheckbox, onSearch, contactPersons, customerLoading, customerLoaded }:
    NewElectricityAgreementCustomerFormProps
) {
    const currentContactPersons = contactPersons.filter(c => c.contactName === form.contactName).map(c => ({ value: c.id, label: c.contactName }));

    const isSearchDisabled = !form.registryNumber?.length;

    function onEnterKey(e: React.KeyboardEvent<HTMLDivElement>){        
        if(isSearchDisabled)
            return;

        if(e.keyCode === 13 || e.key === 'Enter'){
            e.preventDefault();
            onSearch()
        }
    }

    return (
        <>
            <div className="row" onKeyDown={onEnterKey}>
                <div className="col-12 col-md-6">
                    <Form.Label className={'mb-0 align-self-start m-1 text-center'}>
                        <span className="text-nowrap">Isiku / registrikood</span>
                    </Form.Label>
                    <div className="input-group">
                        <Form.Control
                            value={form.registryNumber}
                            disabled={false}
                            type="number"
                            onChange={(e) => setForm({ ...form, registryNumber: e.target.value })}
                        />
                        <div className="input-group-append">
                            <button
                                className="btn btn-primary rounded d-flex align-items-center"
                                type="button"
                                disabled={isSearchDisabled}
                                onClick={() => {
                                    if(!isSearchDisabled)
                                        onSearch()
                                }}
                            >
                                { customerLoading ? <Spinner size="sm" animation="border" /> : <i className="icon icon__search d-inline-block"></i>}
                                
                            </button>
                        </div>
                    </div>
                    { customerLoaded && !contactPersons.length ? <div className="text-danger">Klient ei ole veel Terminali kliendibaasis, pakkumuse esitamisel luuakse klient automaatselt</div> : null }
                </div>
                { isCompany ? <div className="col-12 col-md-6 mt-3 mt-lg-0">
                    <Form.Label className={'mb-0 align-self-start m-1 text-center'}>
                        <span className="text-nowrap">{ 'Käibemaksukohuslase number' }</span>
                    </Form.Label>
                    <Form.Control
                        value={form.vatRegistryNumber}
                        disabled={true}
                        type="text"
                    />
                </div> : null }
            </div>
            { customerLoaded ? <><div className="row mt-3">
                {
                    !form.customerId && !isCompany ?
                    <>
                        <div className="col-12 col-md-6">
                            <Form.Label className={'mb-0 align-self-start m-1 text-center'}>
                                <span className="text-nowrap">Eesnimi*</span>
                            </Form.Label>                    
                            <Form.Control
                                value={form.firstName}
                                type="text"
                                onChange={(val: any) => setForm({
                                    ...form,
                                    firstName: val.target.value
                                })}
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <Form.Label className={'mb-0 align-self-start m-1 text-center'}>
                                <span className="text-nowrap">Perenimi*</span>
                            </Form.Label>                    
                            <Form.Control
                                value={form.lastName}
                                type="text"
                                onChange={(val: any) => setForm({
                                    ...form,
                                    lastName: val.target.value
                                })}
                            />
                        </div>                        
                    </> : 
                    <div className="col-12 col-md-6">
                        <Form.Label className={'mb-0 align-self-start m-1 text-center'}>
                            <span className="text-nowrap">{ isCompany ? 'Ettevõtte nimi' : 'Nimi' }{ !form.customerId ? '*' : ''}</span>
                        </Form.Label>
                        <Form.Control
                            value={form.customerName}
                            disabled={!!form.customerId}
                            type="text"
                            onChange={(val: any) => setForm({
                                ...form,
                                customerName: val.target.value
                            })}
                        />
                    </div>
                }
                { isCompany && contactPersons.length ? 
                    <div className="col-12 col-md-6">
                        <Form.Label>
                            Kontaktisik*
                        </Form.Label>
                        <Select
                            options={contactPersons.map(c => ({ value: c.id, label: c.contactName }))}
                            value={ currentContactPersons.length ? currentContactPersons[0] : undefined }
                            onChange={(val: any) => setForm({
                                ...form,
                                contactName: val?.label,
                                contactId: val?.value,
                                phone: contactPersons.find(c => c.id === val?.value)?.phone || contactPersons.find(c => c.id === val?.value)?.mobileNumber,
                                email: contactPersons.find(c => c.id === val?.value)?.contactEmail,
                                contactPersonCode: contactPersons.find(c => c.id === val?.value)?.personalIdentificationCode,
                            })}
                            className={'w-100 px-0 form-control form-select'}
                            placeholder='Vali kontaktisik'
                            required
                        />
                    </div>
                    : null
                }
                { isCompany && !form.customerId ?
                    <div className="col-12 col-md-6">
                        <Form.Label className={'mb-0 align-self-start m-1 text-center'}>
                            <span className="text-nowrap">Kontakti isikukood*</span>
                        </Form.Label>
                        <Form.Control
                            value={form.contactPersonCode}
                            type="text"
                            onChange={(val: any) => setForm({
                                ...form,
                                contactPersonCode: val.target.value
                            })}
                        />
                    </div>

                : null} 
            </div>
            { isCompany && !form.customerId ? <div className="row mt-3">
                <div className="col-12 col-md-6">
                    <Form.Label className={'mb-0 align-self-start m-1 text-center'}>
                        <span className="text-nowrap">Kontakti eesnimi*</span>
                    </Form.Label>                    
                    <Form.Control
                        value={form.firstName}
                        type="text"
                        onChange={(val: any) => setForm({
                            ...form,
                            firstName: val.target.value
                        })}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <Form.Label className={'mb-0 align-self-start m-1 text-center'}>
                        <span className="text-nowrap">Kontakti perenimi*</span>
                    </Form.Label>                    
                    <Form.Control
                        value={form.lastName}
                        type="text"
                        onChange={(val: any) => setForm({
                            ...form,
                            lastName: val.target.value
                        })}
                    />
                </div>            
            </div> : null}
            <div className="row mt-3">
                <div className="col-12 col-md-6 mt-3 mt-lg-0">
                    <Form.Label className={'mb-0 align-self-start m-1 text-center'}>
                        <span className="text-nowrap">Kontakttelefon*</span>
                    </Form.Label>
                    <Form.Control
                        required
                        value={form.phone}
                        disabled={isFormDisabled}
                        onChange={(e) => setForm({ ...form, phone: e.target.value })}
                        type="tel"
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
                <div className={"col-12 col-md-6 mt-3 mt-lg-0"}>
                    <Form.Label className={'mb-0 align-self-start m-1 text-center'}>
                        <span className="text-nowrap">E-post*</span>
                    </Form.Label>                    
                    <Form.Control
                        required
                        value={form.email}
                        disabled={isFormDisabled}
                        onChange={(e) => setForm({ ...form, email: e.target.value })}
                        type="email"
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
                <div className="col-12 col-xxl-6 mt-3">
                    <Form.Label className={'mb-0 align-self-start m-1 text-center'}>
                        <span className="text-nowrap">Aadress*</span>
                    </Form.Label>
                    <AddressAutocomplete
                        address={form.address}
                        setAddress={(address) => setForm({
                            ...form,
                            address: address.fullAddress || address.address,
                            city: address.city,
                            zip: address.zip,
                            county: address.county
                        })}
                        disabled={isFormDisabled}
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
            </div></> : null}
        { (customerLoaded && showConsolidatedInvoiceCheckbox) && (
            <div className="row my-3">
                <div className="col">
                    <Form.Check
                        id='check-consolidated-invoice'
                        custom
                        type="checkbox"
                        className='checkbox-large'
                        label='Koondarve'
                        onChange={() => setForm({ ...form, consolidatedInvoice: !form.consolidatedInvoice })}
                        checked={form.consolidatedInvoice}
                    />
                </div>
            </div>
        )}
        </>
    )
}
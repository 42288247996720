import axios from "axios";
import { SimplifiedMeteringPoint } from "components/ElectricityAgreements/models";
import { extractData, handleResponse } from "helpers/apiResponseHelper";
import { apiService } from "services/api.service";

async function getMeteringPointsExistingCustomer(customerId: number) {
  return axios.get(apiService.getApiUrl() + `/electricity/metering-points/admin/customer/${customerId}/with-sync`)
    .then(handleResponse).then(extractData);
}

async function getMeteringPointsNewCustomer(registryNumber: string, name: string) {
  return axios.get(apiService.getApiUrl() + '/electricity/metering-points/admin', {
      params: { registryNumber, name }
  }).then(handleResponse).then(extractData);
}

async function findMeteringPointsForCustomer(customerId: number, includeWithInvoiceGroup: boolean) : Promise<SimplifiedMeteringPoint[]> {
  return axios.get(apiService.getApiUrl() + `/electricity/metering-points/admin/customer/${customerId}?includeWithInvoiceGroup=${includeWithInvoiceGroup}`)
    .then(handleResponse).then(extractData);
}

interface UpdateMeteringPointRequest {
  externalChargingStationId?: string;
}

async function updateMeteringPoint(meteringPointId: number, request: UpdateMeteringPointRequest) : Promise<SimplifiedMeteringPoint[]> {
  return axios.patch(apiService.getApiUrl() + `/electricity/metering-points/admin/${meteringPointId}`, request)
    .then(handleResponse).then(extractData);
}

const MeteringPointService = {
  getMeteringPointsExistingCustomer,
  getMeteringPointsNewCustomer,
  findMeteringPointsForCustomer,
  updateMeteringPoint
};

export default MeteringPointService;
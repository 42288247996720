import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { LOCAL_STORAGE_KEYS } from 'common/constants/localStorageKeys';

window.onload = () => {
  const tc = localStorage.getItem(LOCAL_STORAGE_KEYS.TC);
  const tcTime = localStorage.getItem(LOCAL_STORAGE_KEYS.TC_TIME);
  if (tcTime && Number(tcTime) < new Date().getTime() - 120000 && tc === '0') {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.AUTHORIZATION_HEADER);
    window.location.replace('/login');
  }
  localStorage.setItem(LOCAL_STORAGE_KEYS.TC, (tc && String((Number(tc)) + 1)) || '1');

}
window.onunload = () => {
    const tc = localStorage.getItem(LOCAL_STORAGE_KEYS.TC);
    localStorage.setItem(LOCAL_STORAGE_KEYS.TC, (tc && String(Math.max(0, (Number(tc)) - 1))) || '0');
    localStorage.setItem(LOCAL_STORAGE_KEYS.TC_TIME, new Date().getTime() + '');
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();

import { IAgreement } from 'models/agreements'
import { SwitchButton, SwitchButtonState } from 'components/SwitchButton/SwitchButton'
import { handleTextInputNumberChange, isMobileDevice, base64ToByteArray, toHexString, hexToBase64 } from 'helpers'
import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import AgreementsService from 'services/agreements.service'
import { InitContextProps, useAuthContext } from 'services/context/authentication.context'
import { SubmitModalProps } from 'common/common-models'
import * as webEid from '@web-eid/web-eid-library/web-eid';


interface AgreementSigningModalProps extends SubmitModalProps {
  agreement: IAgreement | null;
}

export default function AgreementSigningModal(props: AgreementSigningModalProps) {

  const isMobile = isMobileDevice();

  const { state }: InitContextProps = useAuthContext();
  const { user } = state;

  const [saving, setSaving]: [boolean, any] = useState(false);
  const [savingError, setSavingError]: [boolean, any] = useState(false);
  const [errorMessage, setErrorMessage]: [string | null, any] = useState(null);
  const [method, setMethod] = useState<string>('smart-id');
  const [personalCode, setPersonalCode] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>((user && user.phone) || '');
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [intervalId, setIntervalId] = useState<number | undefined>();
  const confirmSubmit = () => {
    if (props.agreement) {
      if (method === 'smart-id') {
        beginSmartIdSigning();
      } else if (method === 'id-card') {
        beginIdCardSigning();  
      } else {
        beginMobileIdSigning();
      } 
    }
  }

  const beginSmartIdSigning = () => {
    if (!props.agreement || !personalCode) {
      return;
    }
    setSaving(true);
    AgreementsService.initSmartIdSigning(props.agreement.id, personalCode).then(res => {
      setVerificationCode(res);
      const interval: any = setInterval(() => {
        if (!props.agreement) {
          return clearInterval(interval);
        }
        AgreementsService.pollSmartIdSigning(props.agreement.id, personalCode, res).then(pollResult => {
          if (pollResult.result === 'COMPLETED') {
            clearInterval(interval);
            setSaving(false);
            setVerificationCode('');
            props.onSubmitSuccess();
          } else if (pollResult.result === 'FAILED') {
            clearInterval(interval);
            setSaving(false);
            setSavingError(true);
            setErrorMessage('Ilmnes viga allkirjastamisel')
          }
        }).catch(e => {
          clearInterval(interval);
          setSaving(false);
          setSavingError(true);
          setErrorMessage('Ilmnes viga allkirjastamisel')
        })
      }, 1000);
      setIntervalId(interval);
    }).catch(e => {
      setSaving(false);
      setSavingError(true);
      setErrorMessage('Ilmnes viga allkirjastamisel')
    })
  }

  const beginIdCardSigning = () => {
    webEid.status()
      .then(() => {
        webEid.getSigningCertificate({ lang: 'et' })
          .then(({ certificate }) => {
            if (!props.agreement) {
              return null;
            }
            setSaving(true);
            AgreementsService.initIdCardSigning(props.agreement.id, toHexString(base64ToByteArray(certificate))).then((initResult) => {
              webEid.sign(certificate, hexToBase64(initResult.hex), 'SHA-256', {lang: 'et'}).then(
                ({ signature }) => {
                  if (!props.agreement) {
                    return null;
                  }
                  AgreementsService.signIdCardAgreement(props.agreement.id, toHexString(base64ToByteArray(signature)))
                  .then(() => {
                    setSaving(false);
                    props.onSubmitSuccess();
                  }).catch((e) => {
                    setSaving(false);
                    setSavingError(true);
                    setErrorMessage('Ilmnes viga allkirjastamisel');
                  });
                }
              );
            }).catch((e) => {
            setSaving(false);
            setSavingError(true);
            setErrorMessage('Ilmnes viga allkirjastamisel');
          });
          }).catch((e) => {
            setSaving(false);
            setSavingError(true);
            console.error(e)
            setErrorMessage('Ilmnes viga allkirjastamisel');
          });
      }).catch((e) => {
        setSaving(false);
        setSavingError(true);
        console.error(e)
        setErrorMessage('Ilmnes viga allkirjastamisel');
      });
  }

  const beginMobileIdSigning = () => {
    if (!props.agreement || !phoneNumber || !personalCode) {
      return;
    }
    setSaving(true);
    AgreementsService.initMobileIdSigning(props.agreement.id, personalCode, '+372' + phoneNumber).then(res => {
      setVerificationCode(res);
      const interval: any = setInterval(() => {
        if (!props.agreement) {
          return clearInterval(interval);
        }
        AgreementsService.pollMobileIdSigning(props.agreement.id, personalCode, res).then(pollResult => {
          if (pollResult.result === 'COMPLETED') {
            clearInterval(interval);
            setSaving(false);
            setVerificationCode('');
            props.onSubmitSuccess();
          } else if (pollResult.result === 'FAILED') {
            clearInterval(interval);
            setSaving(false);
            setSavingError(true);
            setErrorMessage('Ilmnes viga allkirjastamisel')
          }
        }).catch(e => {
          clearInterval(interval);
          setSaving(false);
          setSavingError(true);
          setErrorMessage('Ilmnes viga allkirjastamisel')
        })
      }, 1000);
      setIntervalId(interval);
    }).catch(e => {
      setSaving(false);
      setSavingError(true);
      setErrorMessage('Ilmnes viga allkirjastamisel')
    })

  }

  const onMethodChange = (active: SwitchButtonState) => {
    let methodState = '';
    if (active === SwitchButtonState.LEFT) {
      methodState = 'smart-id';
    } else if (active === SwitchButtonState.MIDDLE) {
      methodState = 'id-card';
    } else {
      methodState = 'mobile-id';
    }
    
    setMethod(methodState);
  }

  const onCancel = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    props.handleModalHide();
  }

  const getForm = () => {
    if (method === 'smart-id') {
      return (
        <div className="d-flex flex-column align-items-center login__container">
          <input
            type="text"
            className="login__smart-id-input mb-1 mw-phone-input"
            placeholder='Isikukood'
            value={personalCode}
            onChange={(e) => setPersonalCode(handleTextInputNumberChange(personalCode, e.target.value))}                  
            disabled={saving}
          />
      </div>
      );
    } else if (method === 'id-card') {
      return (
        <div>Sisesta ID-kaart lugejasse ja vajuta nupule "Allkirjasta"</div>
      );
    } else {
      return (
        <div className="d-flex flex-column align-items-center login__container">
          <input
            type="text"
            className="login__smart-id-input mb-1 mw-phone-input"
            placeholder='Isikukood'
            value={personalCode}
            onChange={(e) => setPersonalCode(handleTextInputNumberChange(personalCode, e.target.value))}                  
            disabled={saving}
          />
          <div className="d-flex flex-row justify-content-center">
            <input
              type="text"
              className="mobile-id-input__prefix mr-1"
              placeholder='+372'
              disabled
            />
            <input
              type="tel"
              className="mobile-id-input__main"
              placeholder='Telefoninumber'
              disabled={saving}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(handleTextInputNumberChange(phoneNumber, e.target.value))}
            />
          </div>
        </div>
      );
    }
  }  

  return (
      <Modal
        show={true}
        size={"lg"}
        onHide={props.handleModalHide}
        backdrop={'static'}
      >
        <Modal.Header>
          <Modal.Title>
            Lepingu allkirjastamine
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <Form noValidate onSubmit={(e: any) => {
            e.preventDefault();
            confirmSubmit();
          }}
            id={"card-form"}
          >
            {isMobile ? null : <div className="mt-4 row">
              <SwitchButton
                className={"default-switch mx-auto "}
                onActiveChange={onMethodChange}
                labelLeft={"Smart-ID"}
                labelMiddle={"ID-kaart"}
                labelRight={"Mobiil-ID"}
              />
            </div>}
            <div className="mt-5 row">
              <div className="col-12 col-lg-8 offset-lg-2 text-center">
              {
                saving && verificationCode.length > 0 &&
                <>
                  <p>Teie kontrollkood on</p>
                  <strong className="h1">{verificationCode}</strong>
                </>
              }
              {
                getForm()
              }
              </div>
            </div>

          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex flex-row align-items-end justify-content-around flex-wrap mb-1">
          <Button variant="outline-dark"
            onClick={onCancel}
            type="button"
            className={"d-flex align-items-center"}>
            <span className='px-2'>Loobu</span>
          </Button>
          <Button variant="primary" type="submit" disabled={saving} form="card-form"
            className="d-flex align-items-center">
            {
              !saving &&
              <span className='px-2'>Allkirjasta</span>
            }
            {
              saving &&
              <>
                <span className='px-2'>Allkirjastamine…</span>
                <Spinner size={"sm"} animation={"border"} />
              </>
            }
          </Button>
        </Modal.Footer>
        {
          !saving && savingError &&
          <span className="d-flex justify-content-end text-danger px-5 pb-2">
            {errorMessage || 'Viga allkirjastamisel, proovi mõne hetke pärast uuesti.'}
          </span>
        }
      </Modal>    
  )

}
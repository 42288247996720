import React, {PureComponent} from 'react';
import Select from 'react-select';
import { LoyaltyCampaignCalculationType, LoyaltyCampaignType, LOYALTY_CAMPAIGN_CALCULATION_TYPE_MAP, LOYALTY_CAMPAIGN_TYPE_MAP, LoyaltyCampaignsSearchBarProps } from './models';
import { BASIC_STATUS_OPTIONS } from 'common/common-models';
import { Button, Spinner } from 'react-bootstrap';

const CALCULATION_TYPES = [
    { value: LoyaltyCampaignCalculationType.AMOUNT, label: LOYALTY_CAMPAIGN_CALCULATION_TYPE_MAP[LoyaltyCampaignCalculationType.AMOUNT] },
    { value: LoyaltyCampaignCalculationType.QUANTITY, label: LOYALTY_CAMPAIGN_CALCULATION_TYPE_MAP[LoyaltyCampaignCalculationType.QUANTITY] },
];

const CAMPAIGN_TYPES = [
    { value: LoyaltyCampaignType.FOOD, label: LOYALTY_CAMPAIGN_TYPE_MAP[LoyaltyCampaignType.FOOD] },
    { value: LoyaltyCampaignType.MONEY, label: LOYALTY_CAMPAIGN_TYPE_MAP[LoyaltyCampaignType.MONEY] },
    { value: LoyaltyCampaignType.COFFEE, label: LOYALTY_CAMPAIGN_TYPE_MAP[LoyaltyCampaignType.COFFEE] },
    { value: LoyaltyCampaignType.BONUS_MONEY, label: LOYALTY_CAMPAIGN_TYPE_MAP[LoyaltyCampaignType.BONUS_MONEY] },
    { value: LoyaltyCampaignType.UNCATEGORIZED, label: LOYALTY_CAMPAIGN_TYPE_MAP[LoyaltyCampaignType.UNCATEGORIZED] },
];

const selectStyle = {
    indicatorSeparator: () => (
        {
            backgroundColor: 'red',
            width: '50px',
        }
    ),
};

export class LoyaltyCampaignsSearchBar extends PureComponent<LoyaltyCampaignsSearchBarProps, any> {

    onInputChange = (inputEvent: any) => {
        this.props.onFilter({
            searchQuery: inputEvent.target.value
        });
    };

    onCalculationTypeChange = (value: any) => {
        this.props.onFilter({
            calculationType: value?.value
        });
    }

    onCampaignTypeChange = (value: any) => {
        this.props.onFilter({
            campaignType: value?.value
        });
    }

    onStatusChange = (value: any) => {
        this.props.onFilter({
            active: value?.value
        });
    }

    render() {
        return (
            <div className="bar-position">
                <h2 className="text-light">
                    Lojaalsuskampaaniad
                </h2>
                <div className="card mb-5 shadow">
                    <div className="card-body">
                        <div className="d-flex flex-wrap flex-sm-nowrap">
                            <input
                                placeholder="Kampaania nimi"
                                className='search-bar__input'
                                onChange={this.onInputChange}
                                value={this.props.filters.searchQuery || ''}
                            />
                            <Select
                                style={selectStyle}
                                className='mw-192-px mr-2 customer-status__select'
                                value={CAMPAIGN_TYPES.find(s => s.value === this.props.filters.campaignType) || null}
                                onChange={this.onCampaignTypeChange}
                                options={CAMPAIGN_TYPES}
                                isClearable
                                placeholder='Kampaania tüüp'
                            />
                            <Select
                                style={selectStyle}
                                className='mw-192-px mr-2 customer-status__select'
                                value={CALCULATION_TYPES.find(s => s.value === this.props.filters.calculationType) || null}
                                onChange={this.onCalculationTypeChange}
                                options={CALCULATION_TYPES}
                                isClearable
                                placeholder='Kalkulatsiooni tüüp'
                            />
                            <Select
                                style={selectStyle}
                                className='mw-192-px mr-2 customer-status__select'
                                value={BASIC_STATUS_OPTIONS.find(s => s.value === this.props.filters.active) || null}
                                onChange={this.onStatusChange}
                                options={BASIC_STATUS_OPTIONS}
                                isClearable
                                placeholder='Staatus'
                            />
                            <div className="d-flex ml-auto">
                                <Button
                                    variant='primary'
                                    className='d-flex align-items-center px-4'
                                    disabled={this.props.isSyncing}
                                    onClick={this.props.syncCampaigns}
                                >
                                    { this.props.isSyncing ? <Spinner size="sm" animation="border" className="mr-2" /> : <i className='icon__refresh-white text-white mr-2 ' />}
                                    Uuenda kampaaniaid
                                </Button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
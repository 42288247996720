import React from "react";
import {dateFormatOptions} from "../../common/dateFormatterConfig";
import Pagination from "rc-pagination";
import {locale} from "../../common/table/paginatorConfig";
import { SortIcons } from "components/SortIcons/SortIcons";
import { IPageResponse, SortDirection } from "common/interfaces/IPageResponse";
import { ISpecialOffer, SPECIAL_OFFER_TYPE_MAP, SpecialOfferSortFields } from "./model";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

interface SpecialOffersTableProps {
  specialOffers: IPageResponse<ISpecialOffer>;
  onPageChange: (page: number, size: number) => void;
  onSort: (sortField: SpecialOfferSortFields) => void;
  sortField: SpecialOfferSortFields;
  sortDirection: SortDirection;
  updateSpecialOffer: (specialOffer: ISpecialOffer) => void;
}

export function SpecialOffersTable(props: SpecialOffersTableProps) {
    const specialOffers: Array<ISpecialOffer> = props.specialOffers.content;
    const pageResponse = props.specialOffers;

    const specialOfferTableRows = specialOffers.map((specialOffer: ISpecialOffer) =>
        <tr key={'row-' + specialOffer.id}>
            <td>
                {specialOffer.name}
            </td>
            <td>
                {specialOffer.type ? SPECIAL_OFFER_TYPE_MAP[specialOffer.type] : ''}
            </td>
            <td>
                {specialOffer.comment}
            </td>
            <td>
                {specialOffer.amount}
            </td>
            <td>
                {specialOffer.startDate ? new Intl.DateTimeFormat('et', dateFormatOptions).format(new Date(specialOffer.startDate)) : ""}
            </td>
            <td>
                {specialOffer.endDate ? new Intl.DateTimeFormat('et', dateFormatOptions).format(new Date(specialOffer.endDate)) : ""}
            </td>
            <td>
                {specialOffer.active ? "Aktiivne" : "Mitteaktiivne"}
            </td>    
            <td>
                <div className="row__actions">
                    <OverlayTrigger
                            delay={250}
                            overlay={
                                <Tooltip id={`update-special-offer-tooltip-${specialOffer.id}`}>
                                    <p style={{margin: '3px'}}>Muuda eripakkumist</p>
                                </Tooltip>
                            } 
                            placement={"left"}
                    >
                        <Button 
                            size="sm"                             
                            variant="secondary"
                            className="d-flex align-items-center btn-outline-icon" 
                            onClick={() => props.updateSpecialOffer(specialOffer)}
                            >
                                <i className="icon--16 my-2 icon__edit--outline-mask"/>
                        </Button>
                    </OverlayTrigger>
                </div>
            </td>
        </tr>
    );

    return (
        <table className='generic-table table table-responsive-md table-hover'>
            <thead>
            <tr>
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(SpecialOfferSortFields.NAME)}>
                        <span>Nimi</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={SpecialOfferSortFields.NAME} />
                    </div>
                </th> 
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(SpecialOfferSortFields.TYPE)}>
                        <span>Tüüp</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={SpecialOfferSortFields.TYPE} />
                    </div>
                </th> 
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(SpecialOfferSortFields.COMMENT)}>
                        <span>Kommentaar</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={SpecialOfferSortFields.COMMENT} />
                    </div>
                </th> 
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(SpecialOfferSortFields.AMOUNT)}>
                        <span>Summa</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={SpecialOfferSortFields.AMOUNT} />
                    </div>
                </th> 
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(SpecialOfferSortFields.START_DATE)}>
                        <span>Alguskuupäev</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={SpecialOfferSortFields.START_DATE} />
                    </div>
                </th> 
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(SpecialOfferSortFields.END_DATE)}>
                        <span>Lõppkuupäev</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={SpecialOfferSortFields.END_DATE} />
                    </div>
                </th>  
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(SpecialOfferSortFields.ACTIVE)}>
                        <span>Staatus</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={SpecialOfferSortFields.ACTIVE} />
                    </div>
                </th>    
            </tr>
            </thead>
            <tbody>
                {specialOfferTableRows}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan={9}>
                        <div className="pagination-wrapper">
                            <Pagination
                                locale={locale}
                                current={pageResponse.number + 1}
                                total={pageResponse.totalElements}
                                pageSize={pageResponse.size}
                                prefixCls={"pagination"}
                                onChange={props.onPageChange}
                                showSizeChanger={false}
                                hideOnSinglePage={true}
                            />
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>
    )
}
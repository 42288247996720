import React, {PureComponent} from 'react';
import {Button} from "react-bootstrap";
import {ModalType} from "../../common/common-models";
import { IElectricityPackagesSearchBarProps } from './models';

export class ElectricityPackagesSearchBar extends PureComponent<IElectricityPackagesSearchBarProps, any> {

    render() {
        return (
            <div className="bar-position">
                <h2 className="text-light">
                    Elektripaketid
                </h2>
                <div className="card mb-5 shadow">
                    <div className="card-body">
                        <div className="d-flex flex-wrap flex-sm-nowrap">
                            <Button className="ml-auto d-flex align-items-center"
                                    onClick={() => this.props.openElectricityPackageModal(ModalType.CREATE)}
                                    variant={"primary"}>
                                <i className="icon--16 icon__add d-flex mr-1"/>
                                <span>Lisa uus</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
import React, {PureComponent} from 'react';

import {IPageResponse, SortDirection} from "../common/interfaces/IPageResponse";
import {ITank, ITanksSortFields} from "../components/Tanks/models";
import {TanksTable} from "../components/Tanks/TanksTable";
import {TanksSearchBar} from "../components/Tanks/TanksSearchBar";
import { ModalType } from 'common/common-models';
import { IPageRequest } from 'common/interfaces/IPageRequest';
import { DeleteTankConfirmModal } from 'components/Tanks/modals/DeleteTankConfirmModal';
import { EditTankModal } from 'components/Tanks/modals/EditTankModal';
import TanksService from 'services/tanks.service';

export interface ITanksState {
    tanks: IPageResponse<ITank> | null;
    pageRequest: IPageRequest<ITanksSortFields>;
    isEditModalVisible: boolean;
    isDeleteModalVisible: boolean;
    modalType: ModalType;
    tank?: ITank;
}

class Tanks extends PureComponent<any, ITanksState> {
    constructor(props: any) {
        super(props);
        this.state = {
            tanks: null,
            pageRequest: {
                page: 0,
                size: 25,
                sortDirection: SortDirection.ASC,
                sortField: ITanksSortFields.ID,
                searchQuery: null
            },
            isEditModalVisible: false,
            isDeleteModalVisible: false,
            modalType: ModalType.CREATE
        };
        this.props.onLoadValidatePage();
    }

    getTanks = () => {
        TanksService.getAllTanks(this.state.pageRequest).then((response: IPageResponse<ITank>) => {
            this.setState({
                tanks: response
            });
        });
    };
    onPageChange = (targetPage: number) => {
        if (this.state.pageRequest.page + 1 !== targetPage) {
            this.setState({
                pageRequest: {
                    ...this.state.pageRequest,
                    page: targetPage - 1
                }
            }, () => this.getTanks());
        }
    };

    openEditOrAddTankModal = (modalType: ModalType, tank?: ITank) => {
        this.setState({
            isEditModalVisible: true,
            isDeleteModalVisible: false,
            modalType: modalType,
            tank: tank
        })
    };
    openDeleteTankModal = (tank: ITank) => {
        this.setState({
            isEditModalVisible: false,
            isDeleteModalVisible: true,
            tank: tank
        })
    };

    componentDidMount(): void {
        this.getTanks();
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col">
                        <TanksSearchBar onAddTank={this.openEditOrAddTankModal}/>
                        {this.state.tanks !== null ?
                            <TanksTable tanks={this.state.tanks}
                                        onPageChange={this.onPageChange}
                                        openEditModal={(tank: ITank) => this.openEditOrAddTankModal(ModalType.EDIT, tank)}
                                        openDeleteModal={this.openDeleteTankModal}
                            /> :
                            null
                        }
                    </div>
                </div>
                {
                    this.state.isEditModalVisible && this.state.isDeleteModalVisible === false &&
                    <EditTankModal
                        modalType={this.state.modalType}
                        rejectCloseHandler={() => {
                            this.setState({isEditModalVisible: false})
                        }}
                        submitCloseHandler={() => {
                            this.setState({isEditModalVisible: false});
                            this.getTanks();
                        }}
                        tank={this.state.tank}
                    />
                }
                {
                    this.state.isDeleteModalVisible && this.state.tank &&
                    <DeleteTankConfirmModal
                        handleRejectClose={() => {
                            this.setState({isDeleteModalVisible: false})
                        }}
                        handleSubmitClose={() => {
                            this.setState({isDeleteModalVisible: false},
                                () => {
                                this.getTanks();
                            });
                        }}
                        tank={this.state.tank}/>
                }                
            </div>
        );
    }
}



export default Tanks;
import { ModalProps } from "common/common-models";
import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { ISimplifiedProductionTable } from "../models";
import { ICustomer } from "components/ElectrcityCustomers/model";

interface ElectricityProductionTableModalProps extends ModalProps {
  activeCustomer: ICustomer;
  findProductionTablesForCustomer: (customerId: number) => Promise<ISimplifiedProductionTable[]>;
  getFile: (productionTable: ISimplifiedProductionTable, isCorrection: boolean) => void;
}

export function ElectricityProductionTableModal(props: ElectricityProductionTableModalProps) {
    const [productionTables, setProdutionTables] = useState<ISimplifiedProductionTable[]>([]);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
      findProductionTables()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const findProductionTables = async () => {
      setIsFetching(true)

      try{
        const response = await props.findProductionTablesForCustomer(props.activeCustomer.id);
        if(!!response.length){
          setProdutionTables(response)
        }
      }catch(e){
        console.error(e)
      }

      setIsFetching(false)
    }

    return (
      <Modal size="lg" show={true} onHide={props.handleModalHide} dialogClassName="production-table-modal">
        <Modal.Header>
            <Modal.Title>
                Tootmistabelid
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {isFetching ? (
              <div className="d-flex justify-content-center align-items-center">
                <Spinner size="sm" animation="border"/>
              </div>
            ) : (
              <div className="d-flex">
                {
                  !productionTables.length ? (
                    <div>Tootmistabelid puuduvad...</div>
                  ) : 
                  (
                    <table className="w-100">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Perioodi algus</th>
                          <th>Perioodi Lõpp</th>
                          <th className="text-center">Tootmistabel</th>
                          <th className="text-center">Korrektsioonid</th>
                        </tr>
                      </thead>
                      <tbody>
                          {productionTables.sort((a,b) => new Date(a.periodStart).getTime() - new Date(b.periodStart).getTime()).map((productionTable, idx) => (
                              <tr key={productionTable.id} className="production-table-modal__row">
                                <td>{idx + 1}</td>
                                <td>{productionTable.periodStart}</td>
                                <td>{productionTable.periodEnd}</td>
                                <td className="text-center">
                                  <Button 
                                        size="sm"
                                        variant="secondary"
                                        className="btn-outline-icon" 
                                        style={{position: 'relative'}} 
                                        onClick={() => props.getFile(productionTable, false)} 
                                      >
                                        <div className='icon__file action-icon' style={{ position: 'absolute', top: "5px", bottom: "0px", left: "2px", right: "0px"}}/>
                                  </Button>
                                </td>
                                <td className="text-center">
                                  {
                                    productionTable.correctionsFileName ? (
                                      <Button 
                                        size="sm"
                                        variant="secondary"
                                        className="btn-outline-icon" 
                                        style={{position: 'relative'}} 
                                        onClick={() => props.getFile(productionTable, true)} 
                                      >
                                        <div className='icon__file action-icon' style={{ position: 'absolute', top: "5px", bottom: "0px", left: "2px", right: "0px"}}/>
                                      </Button>
                                    ) : <span>-</span>
                                  }
                                </td>
                              </tr>
                          ))}
                        </tbody>
                      </table>
                  )
                }
            </div>
            )}
        </Modal.Body>
        <Modal.Footer>
            <Button
                variant="primary" 
                onClick={props.handleModalHide}
                type="button"
            >
                <span className='px-3'>Sulge</span>
            </Button>
        </Modal.Footer>
    </Modal>      
    );
}
import { CreateElectricityAgreementModalProps } from "common/common-models";
import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { ElectricityAgreementsService } from "../ElectricityAgreements.service";
import { ElectricityAgreementForm, INDIVIDUAL_PACKAGE_VALUE, MeteringPoint } from "../models";
import NewElectricityAgreementData from "../components/NewElectricityAgreement/NewElectricityAgreementData";
import { BANK_ACCOUNT_NUMBER_REGEX_EXPRESSION } from "common/constants/regex";
import { validateCustomersInvoiceRecipientEmail } from "helpers";
import { toast } from "react-toastify";

const initialFormState = {
    validated: false,
    sellCheck: false,
    consolidatedInvoice: true,
    eInvoice: false,
    registryNumber: '',
    isJointInvoicePossible: false,
    consumptionPackage: {}
}

export function CreateElectricityAgreementModal(props: CreateElectricityAgreementModalProps) {
    const [form, setForm] = useState<ElectricityAgreementForm>(initialFormState);

    const [meteringPoints, setMeteringPoints] = useState<MeteringPoint[]>([]);
    const [saving, setSaving] = useState(false);
    
    const validateSubmit = () => {
        if (validate()) {
            submit();
        }
    }

    const validate = (): boolean => {
        setForm({ ...form, validated: true });

        return validateCustomer() && validateCustomersInvoiceRecipientEmail(form.invoiceRecipientEmail) && validateBankAccountNumber() && validateMeteringPoints() && validatePackage() && validatePrice() && validateAgreement()
    }

    const validateCustomer = (): boolean => {
        if (!form.registryNumber || !form.address || !form.email || !form.phone) {
            return false;
        }
        return true;
    }

    const validateMeteringPoints = (): boolean => {
        if (meteringPoints.some(mp => mp.checked)) {
            return true;
        }
        return false;
    }

    const validatePackage = (): boolean => {
        if(!form.consumptionPackage.id){
            return false
        }
        if (form.consumptionPackage.id === INDIVIDUAL_PACKAGE_VALUE) {
            if (!form.consumptionPackage.nameEt || !form.consumptionPackage.termsAndConditionsId || !form.consumptionPackage.contractTitle || !form.consumptionPackage.pdfFooterDisclaimer) {
                return false;
            }
            if (form.consumptionPackage.marketPricePackage ? !form.consumptionPackage.basePriceTextEt : !form.consumptionPackage.basePrice) {
                return false;
            }
        }
        return true;
    }

    const validatePrice = (): boolean => {
        if (!form.consumptionMarginal) {
            return false;
        }
        if (form.sellCheck && !form.productionMarginal && !form.productionPackageId) {
            return false;
        }
        return true;
    }

    const validateAgreement = (): boolean => {       
        if ((props.isOffer && !form.offerDeadline) || !form.contractPeriodId) {
            return false;
        }
        return true;
    }

    const validateBankAccountNumber = () => {
        if (form.eInvoice) {
            return BANK_ACCOUNT_NUMBER_REGEX_EXPRESSION.test(form.bankAccountNumber!);
        }
        
        return true;
    }

    const submit = () => {
        setSaving(true);
        const requestMeteringPoints = meteringPoints.filter(mp => mp.checked)
            .map(mp => !mp.address ? ({ ...mp, address: mp.newAddress || '' }) : mp);
        
        let pendingPromise;
        if(props.isOffer){
            pendingPromise = ElectricityAgreementsService.createElectricityAgreementOffer(
                form, 
                requestMeteringPoints
            )
        }else{
            pendingPromise = ElectricityAgreementsService.createElectricityAgreement(
                form, 
                requestMeteringPoints
            )
        }
        pendingPromise.then(() => {
            setSaving(false);
            props.handleModalHide();
            props.onSuccess?.()
        })
        .catch((e) => {
            console.error(e);
            setSaving(false);
            toast.error("Lepingu loomine ebaõnnestus")
        });
    }

    const modalTitle = props.isOffer ? "Lisa uus pakkumine" : "Lisa uus leping"
    const btnTitle = props.isOffer ? "Esita pakkumine" : "Loo leping"

    return (
      <Modal
        show={true}
        size={"xl"}
        dialogClassName="bg-light"
        onHide={props.handleModalHide}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {modalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <NewElectricityAgreementData 
                form={form}
                setForm={setForm}
                meteringPoints={meteringPoints}
                setMeteringPoints={setMeteringPoints}
                isOffer={props.isOffer}
            />
        </Modal.Body>
        <Modal.Footer className="d-flex flex-row align-items-end justify-content-end flex-wrap mb-1">
            <Button variant="info"
                    onClick={() => props.handleModalHide()}
                    type="button"
                    className={"d-flex align-items-center"}>
                <span className='px-2'>Tühista</span>
            </Button>
            <Button variant="primary" onClick={validateSubmit} type="button" disabled={saving}
                    className="d-flex align-items-center">
                {
                    !saving &&
                    <span className='px-2'>{btnTitle}</span>
                }
                {
                    saving &&
                    <>
                        <span className='px-2'>Salvestamine…</span>
                        <Spinner size={"sm"} animation={"border"}/>
                    </>
                }
            </Button>

        </Modal.Footer>
      </Modal>    
    );
}
import React from "react";
import {ITermsAndCondition, ITermsAndConditionsTableProps} from "./models";
import {dateFormatOptions} from "../../common/dateFormatterConfig";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {ModalType} from "../../common/common-models";
import Pagination from "rc-pagination";
import {locale} from "../../common/table/paginatorConfig";



export function TermsAndConditionsTable(props: ITermsAndConditionsTableProps) {
    const termsAndConditions: Array<ITermsAndCondition> = props.termsAndConditions.content;
    const pageResponse = props.termsAndConditions;

    const termsAndConditionsTableRows = termsAndConditions.map((termsAndConditions: ITermsAndCondition) =>
        <tr key={'row-' + termsAndConditions.id}>
            <td>
                {termsAndConditions.name}
            </td>
            <td>
                {termsAndConditions.latestVersion.version}
            </td>       
            <td>
                {termsAndConditions.latestVersion.dateModified ? new Intl.DateTimeFormat('et', dateFormatOptions).format(new Date(termsAndConditions.latestVersion.dateModified)) : ""}
            </td>
            <td>
                <div className="row__actions">
                    <OverlayTrigger delay={250}
                                        overlay={
                                            <Tooltip id={`tooltip-${termsAndConditions.id}`}>
                                                <p style={{margin: '3px'}}>Muuda</p>
                                            </Tooltip>} placement={"left"}>
                            <Button size="sm" variant="outline-dark"
                                    className="d-flex align-items-center border-color-background border-radius-circle btn-outline-icon"
                                    onClick={(e: any) => {
                                        props.openTermsAndConditionsModal(ModalType.EDIT, termsAndConditions);
                                    }}>
                                <i className="icon--16 my-2 icon__edit--outline-mask"/>
                            </Button>
                        </OverlayTrigger>
                </div>
            </td>
        </tr>
    );

    return (
        <table className='generic-table table table-responsive-md table-hover'>
            <thead>
            <tr>
                <th>Nimi</th>
                <th>Versioon</th>
                <th>Viimati muudetud</th>
                <th>Tegevused</th>
            </tr>
            </thead>
            <tbody>
            {termsAndConditionsTableRows}
            </tbody>
            <tfoot>
            <tr>
                <td colSpan={5}>
                    <div className="pagination-wrapper">
                        <Pagination
                            locale={locale}
                            current={pageResponse.number + 1}
                            total={pageResponse.totalElements}
                            pageSize={pageResponse.size}
                            prefixCls={"pagination"}
                            showSizeChanger={false}
                            hideOnSinglePage={true}
                        />
                    </div>
                </td>
            </tr>
            </tfoot>
        </table>
    )
}
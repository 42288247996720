import React from 'react';
import Pagination from "rc-pagination";
import {dateFormatOptions, dateFormatWithTimeOptions} from "../../common/dateFormatterConfig";
import {locale} from "../../common/table/paginatorConfig";
import {ITank} from "./models";
import {TankProgressBar} from "../TankProgressBar/TankProgressBar";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";


export function TanksTable(props: any) {
    const tanks: Array<ITank> = props.tanks.content;
    const pageResponse = props.tanks;
    const tableRows = tanks.map((tank: ITank) =>
        <tr key={'row-' + tank.id}>
            <td>
                {tank.name}
            </td>
            <td>
                <TankProgressBar tankRow={tank}/>
            </td>
            <td>
                {tank.capacity}
            </td>
            <td className={tank.latestAmount <= tank.notificationThreshold ? "under-threshold" : "over-threshold"}>
                {tank.latestAmount}
            </td>
            <td>
                {tank.notificationThreshold}
            </td>
            <td>
                {tank.latestUpdateTime ? new Intl.DateTimeFormat('et', dateFormatWithTimeOptions).format(new Date(tank.latestUpdateTime)) : ""}
            </td>
            <td>
                {tank.dateModified ? new Intl.DateTimeFormat('et', dateFormatOptions).format(new Date(tank.dateModified)) : ""}
            </td>
            <td>
                <div className="row__actions">
                    <OverlayTrigger delay={250}
                                    overlay={
                                        <Tooltip id={`tooltip-${tank.id}`}>
                                            <p style={{margin: '3px'}}>Muuda</p>
                                        </Tooltip>} placement={"left"}>
                        <Button size="sm" variant="outline-dark"
                                className="d-flex align-items-center border-color-background border-radius-circle btn-outline-icon"
                                onClick={(e: any) => props.openEditModal(tank)}
                        >
                            <i className="icon--16 my-2 icon__edit--outline-mask"/>
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger delay={250}
                                    overlay={
                                        <Tooltip id={`tooltip-${tank.id}`}>
                                            <p style={{margin: '3px'}}>Kustuta</p>
                                        </Tooltip>} placement={"left"}>
                        <Button size="sm" variant="outline-danger"
                                className="d-flex align-items-center border-color-background border-radius-circle btn-outline-icon"
                                onClick={(e: any) => props.openDeleteModal(tank)}
                            >
                            <i className="icon--16 my-2 icon__delete--outline-mask"/>
                        </Button>
                    </OverlayTrigger>
                </div>                
            </td>
        </tr>
    );

    return (
        <table className='tanks__table table table-responsive-md table-hover'>
            <thead>
            <tr>
                <th>Nimetus</th>
                <th>Hetkeseis</th>
                <th>Maht</th>
                <th>Näit</th>
                <th>Teavituskünnis</th>
                <th>Näidu aeg</th>
                <th>Viimati muudetud</th>
                <th>Tegevused</th>
            </tr>
            </thead>
            <tbody>
            {tableRows}
            </tbody>
            <tfoot>
            <tr>
                <td colSpan={6}>

                </td>
                <td colSpan={2}>
                    <div className="pagination-wrapper">
                        <Pagination
                            locale={locale}
                            current={pageResponse.number + 1}
                            total={pageResponse.totalElements}
                            pageSize={pageResponse.size}
                            prefixCls={"pagination"}
                            onChange={props.onPageChange}
                            showSizeChanger={false}
                            hideOnSinglePage={true}
                        />
                    </div>
                </td>
            </tr>
            </tfoot>
        </table>
    )
}
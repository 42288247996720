/* eslint-disable react-hooks/exhaustive-deps */
import { ElectricityPackagesService } from "components/ElectricityPackages/ElectricityPackages.service";
import { ElectricityPackageType, IElectricityPackage } from "components/ElectricityPackages/models";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { ElectricityAgreementForm, INDIVIDUAL_PACKAGE_VALUE } from "../../models";
import { getLastMonthWithFirstDay, getLastMonthWithLastDay, getMarketPricePackageProductDescription } from "helpers";

interface NewElectricityAgreementPackageFormProps {
    form: ElectricityAgreementForm;
    setForm: (form: ElectricityAgreementForm) => void;
}

export default function NewElectricityAgreementPackageForm(
    { form, setForm } : NewElectricityAgreementPackageFormProps
) {

    const [packages, setPackages] = useState<IElectricityPackage[]>([]);

    useEffect(() => {
        getPackages();
    }, []);

    const getPackages = () => {
        ElectricityPackagesService.getElectricityPackages({
            page: 0,
            size: 999
        }).then(res => {
            const packagesWithConsumptionType = res.content.filter(pckg => pckg.type === ElectricityPackageType.CONSUMPTION);
            const packageWithProductionType = res.content.filter(pckg => pckg.type === ElectricityPackageType.PRODUCTION)[0];

            setPackages(packagesWithConsumptionType);
            setForm({
                ...form,
                productionPackageId: packageWithProductionType.id
            })
        });
    }

    const setPackageValue = (option?: any) => {
        const p = packages.find(p => p.id === option?.value);

        setForm({
            ...form,
            consumptionPackage: {
                id: option?.value === INDIVIDUAL_PACKAGE_VALUE ? INDIVIDUAL_PACKAGE_VALUE : p?.id,
                termsAndConditionsId: p?.terms?.id,
                basePrice: p?.basePrice,
                basePriceTextEt: p?.basePriceTextEt ?? '',
                basePriceTextEn: p?.basePriceTextEn ?? '',
                contractTitle: p?.contractTitle ?? '',
                pdfFooterDisclaimer: (form.isCompany ? p?.pdfFooterDisclaimerCompany : p?.pdfFooterDisclaimerPerson) ?? '',
                monthlyFee: p?.monthlyFee,
                marketPricePackage: !!p?.marketPricePackage,
                jointInvoicePackage: !!p?.jointInvoicePackage
            },
            endDate: p?.contractEndDate ? new Date(p?.contractEndDate) : form.endDate,
            contractPeriodId: p?.contractPeriodId
        })
    }
    const packageOptions = packages.filter(p => {
        if(!form.isJointInvoicePossible && p.jointInvoicePackage){
            return false;
        }else{
            return true;
        }
    }).map(p => ({ value: p.id, label: p.nameEt }));

    packageOptions.push({ value: INDIVIDUAL_PACKAGE_VALUE, label: 'Individuaalne' });

    const currentPackageOption = packageOptions.find(p => p.value === form.consumptionPackage.id);

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-6">
                    <Form.Label>Pakett*</Form.Label>
                        <Select
                            required
                            options={packageOptions}
                            value={currentPackageOption ?? null}
                            onChange={setPackageValue}
                            placeholder='Vali elektripakett'
                            className={'w-100 form-control form-select' + (!form.consumptionPackage.id && form.validated ? ' is-invalid' : '')}
                        />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
            </div>
            { currentPackageOption?.value === INDIVIDUAL_PACKAGE_VALUE ?
                <div className="row mt-3">
                    <div className="col-12 col-md-6">
                        <Form.Label>Paketi eestikeelne nimetus*</Form.Label>
                        <Form.Control
                            required
                            value={form.consumptionPackage.nameEt}
                            onChange={(e: any) => setForm({
                                ...form,
                                consumptionPackage: {
                                    ...form.consumptionPackage,
                                    nameEt: e.target.value
                                }
                            })}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <Form.Label>Paketi inglisekeelne nimetus</Form.Label>
                        <Form.Control
                            value={form.consumptionPackage.nameEn}
                            onChange={(e: any) => setForm({
                                ...form,
                                consumptionPackage: {
                                    ...form.consumptionPackage,
                                    nameEn: e.target.value
                                }
                            })}
                        />
                    </div>
                </div> 
                : null
            }
            <div className="row mt-3">
                <div className="col-12 col-md-6">
                    <Form.Check
                        className='form-check-input-no-label'
                        id='market-price-check'
                        label='Börsipakett'
                        checked={form.consumptionPackage.marketPricePackage}
                        disabled={currentPackageOption?.value !== INDIVIDUAL_PACKAGE_VALUE}
                        onChange={(e: any) => setForm({
                            ...form,
                            consumptionPackage: {
                                ...form.consumptionPackage,
                                marketPricePackage: e.target.checked,
                                basePrice: !form.consumptionPackage.marketPricePackage ? undefined : form.consumptionPackage.basePrice,
                                basePriceTextEt: !form.consumptionPackage.marketPricePackage ? !form.consumptionPackage.basePriceTextEt ? 'Börsihind + marginaal' : form.consumptionPackage.basePriceTextEt : '',
                                basePriceTextEn: !form.consumptionPackage.marketPricePackage ? !form.consumptionPackage.basePriceTextEn ? 'Market price + margin' : form.consumptionPackage.basePriceTextEn : ''
                            }
                        })}
                        type='checkbox'
                    />
                    {form.isJointInvoicePossible && (
                        <Form.Check
                            className='form-check-input-no-label'
                            id='joint-invoice-check'
                            label='Ühisarve'
                            checked={form.consumptionPackage.jointInvoicePackage}
                            disabled={currentPackageOption?.value !== INDIVIDUAL_PACKAGE_VALUE}
                            onChange={(e: any) => setForm({
                                ...form,
                                consumptionPackage: {
                                    ...form.consumptionPackage,
                                    jointInvoicePackage: !form.consumptionPackage.jointInvoicePackage,
                                }
                            })}
                            type='checkbox'
                        />
                    )}
                </div>
            </div>
            {currentPackageOption?.value === INDIVIDUAL_PACKAGE_VALUE ? (
                <>
                   <div className="row mt-3">
                        <div className="col-12 col-md-6">
                            <Form.Label>Arve toote kirjeldus PDF-il</Form.Label>
                            <Form.Control
                                value={form.consumptionPackage.pdfProductDescriptionOnInvoice}
                                onChange={(e) => setForm({
                                        ...form,
                                        consumptionPackage: {
                                            ...form.consumptionPackage,
                                            pdfProductDescriptionOnInvoice: e.target.value
                                        }
                                    })
                                }
                                type="text"
                            />
                        </div>
                    </div>
                    <div className='text-break'>{form.consumptionPackage.pdfProductDescriptionOnInvoice ?? (form.consumptionPackage.marketPricePackage ? getMarketPricePackageProductDescription(form.consumptionPackage.nameEt) : "Elektrienergia universaalteenuse hinnaga")} ({getLastMonthWithFirstDay()} - {getLastMonthWithLastDay()}) mõõtepunktis <b>X</b> aadressiga <b>X</b></div>
                    <div className="row mt-3">
                        <div className="col-12 col-md-6">
                            <Form.Label>Arve toote kirjeldus meritis</Form.Label>
                            <Form.Control
                                className='col-12 col-md-6'
                                value={form.consumptionPackage.externalProductDescriptionOnInvoice}
                                onChange={(e) => setForm({
                                        ...form,
                                        consumptionPackage: {
                                            ...form.consumptionPackage,
                                            externalProductDescriptionOnInvoice: e.target.value
                                        }
                                    })
                                }
                                maxLength={150}
                                type="text"
                            />
                        </div>
                    </div>
                    <div className='col-12 p-0 text-break'>{form.consumptionPackage.externalProductDescriptionOnInvoice ?? (form.consumptionPackage.marketPricePackage ? getMarketPricePackageProductDescription(form.consumptionPackage.nameEt) : "Elektri universaalteenus")} ({getLastMonthWithFirstDay()} - {getLastMonthWithLastDay()}) - (<b>X</b>) <b>X</b></div>
                </>
            ) : null} 
        </>
    );
}
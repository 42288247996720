import React, {PureComponent} from 'react';
import { SPECIAL_OFFER_TYPE_MAP, SpecialOfferFilters, SpecialOfferType } from './model';
import Select from 'react-select';
import { BASIC_STATUS_OPTIONS } from 'common/common-models';
import { Button, Spinner } from 'react-bootstrap';

const SPECIAL_OFFER_TYPES = [
    { value: SpecialOfferType.FOOD, label: SPECIAL_OFFER_TYPE_MAP[SpecialOfferType.FOOD] },
    { value: SpecialOfferType.UNCATEGORIZED, label: SPECIAL_OFFER_TYPE_MAP[SpecialOfferType.UNCATEGORIZED] }
];

const selectStyle = {
    indicatorSeparator: () => (
        {
            backgroundColor: 'red',
            width: '50px',
        }
    ),
};

interface SpecialOffersSearchBarProps {
  filters: SpecialOfferFilters;
  onFilter: (filter: SpecialOfferFilters) => void;
  isSyncing: boolean;
  syncSpecialOffers: () => void;
}

export class SpecialOffersSearchBar extends PureComponent<SpecialOffersSearchBarProps, any> {

    onInputChange = (inputEvent: any) => {
        this.props.onFilter({
            searchQuery: inputEvent.target.value
        });
    };

    onTypeChange = (value: any) => {
        this.props.onFilter({
            type: value?.value
        });
    }

    onStatusChange = (value: any) => {
        this.props.onFilter({
            active: value?.value
        });
    }

    render() {
        return (
            <div className="bar-position">
                <h2 className="text-light">
                    Eripakkumised
                </h2>
                <div className="card mb-5 shadow">
                    <div className="card-body">
                        <div className="d-flex flex-wrap flex-sm-nowrap">
                            <input
                                placeholder="Eripakkumise nimi"
                                className='search-bar__input p-2'
                                onChange={this.onInputChange}
                                value={this.props.filters.searchQuery || ''}
                            />
                            <Select
                                style={selectStyle}
                                className='mw-192-px mr-2 customer-status__select'
                                value={SPECIAL_OFFER_TYPES.find(s => s.value === this.props.filters.type) || null}
                                onChange={this.onTypeChange}
                                options={SPECIAL_OFFER_TYPES}
                                isClearable
                                placeholder='Tüüp'
                            />
                            <Select
                                style={selectStyle}
                                className='mw-192-px mr-2 customer-status__select'
                                value={BASIC_STATUS_OPTIONS.find(s => s.value === this.props.filters.active) || null}
                                onChange={this.onStatusChange}
                                options={BASIC_STATUS_OPTIONS}
                                isClearable
                                placeholder='Staatus'
                            />
                            <div className="d-flex ml-auto">
                                <Button
                                    variant='primary'
                                    className='d-flex align-items-center px-4'
                                    disabled={this.props.isSyncing}
                                    onClick={this.props.syncSpecialOffers}
                                >
                                    { this.props.isSyncing ? <Spinner size="sm" animation="border" className="mr-2" /> : <i className='icon__refresh-white text-white mr-2 ' />}
                                    Uuenda eripakkumisi
                                </Button>
                            </div>                            
                        </div>    
                    </div>
                </div>
            </div>
        )
    }
}
import axios from "axios";
import { convertToPageable, downloadFile, extractData, extractFileNameFromResponse, handleResponse } from "helpers/apiResponseHelper";
import { SynchronizationData, SynchronizationErrorData, SynchronizationType } from "models/syncronization";
import { apiService } from "services/api.service";

async function findInvoices(pageRequest: any) {
  return axios.get(apiService.getApiUrl() + `/electricity/invoices/admin`, {
      params: pageRequest
  }).then(handleResponse).then(convertToPageable);
}

async function createInvoiceForAgreement(agreementId: number) {
  return axios.post(apiService.getApiUrl() + `/electricity/invoices/admin/agreement/${agreementId}`, {})
    .then(handleResponse).then(extractData);
}

async function createInvoices(isPerson : boolean) {
  return axios.post(apiService.getApiUrl() + `/electricity/invoices/admin?isPerson=${isPerson}`, {});
}

async function findInvoicesForAgreement(contractNumber: string) {
  return axios.get(apiService.getApiUrl() + `/electricity/invoices/admin/agreement/${contractNumber}`)
    .then(handleResponse).then(extractData);
}

async function getSynchronizationStatus(
  type: SynchronizationType
) : Promise<SynchronizationData> {
  return axios.get(apiService.getApiUrl() + `/electricity/invoices/admin/sync/status/type/${type}`)
    .then(handleResponse).then(extractData);
}

async function findLatestSynchronizationErrors(
  type: SynchronizationType
) : Promise<SynchronizationErrorData[]> {
  return axios.get(apiService.getApiUrl() + `/electricity/invoices/admin/sync/errors/latest/type/${type}`)
    .then(handleResponse).then(extractData);
}

async function getInvoiceFileWithDownload(invoiceId: number, fileName: string) {
  return axios.get(apiService.getApiUrl() + `/electricity/invoices/admin/${invoiceId}/file`, {
      responseType: 'blob'
  }).then(handleResponse).then((res) => downloadFile(fileName, res));
}

async function getInvoiceFile(invoiceId: number) : Promise<Blob> {
  return axios.get(apiService.getApiUrl() + `/electricity/invoices/admin/${invoiceId}/file`, {
      responseType: 'blob'
  }).then(handleResponse);
}

async function generateInvoicesReport() {
  return axios.post(apiService.getApiUrl() + `/electricity/invoices/admin/report`, {}, {
      responseType: 'blob'
  }).then(res => handleResponse(res, true)).then((res) => downloadFile(
    extractFileNameFromResponse(res, "Arvete eksport.xlsx"),
    res.data
  ));
}

export const ElectricityInvoicesService = {
  findInvoices,
  createInvoiceForAgreement,
  findInvoicesForAgreement,
  createInvoices,
  getSynchronizationStatus,
  findLatestSynchronizationErrors,
  getInvoiceFileWithDownload,
  getInvoiceFile,
  generateInvoicesReport
};
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Button, Form, Modal, ModalProps, Spinner } from "react-bootstrap";
import { ISpecialOffer, SpecialOfferType } from "../model";
import { SpecialOfferService } from "../SpecialOffer.service";
import Select from 'react-select';
import { toast } from "react-toastify";
import { FileUploadFormGroup, UploadedFile } from "components/FileUpload/FileUploadFormGroup";

const SPECIAL_OFFER_TYPES = [
    { label: "Toit", value: SpecialOfferType.FOOD },
    { label: "Kategooriata", value: SpecialOfferType.UNCATEGORIZED },
];

export interface UpdateSpecialOfferModalForm {
  validated: boolean;
  titleEt?: string;
  titleEn?: string;
  active: boolean;
  type?: SpecialOfferType;
  image?: UploadedFile
}

export interface UpdateSpecialOfferModalProps extends ModalProps {
  specialOffer: ISpecialOffer
}

const ACCEPTED_MIME_TYPES = {
  'image/svg+xml': ['.svg'],
  'image/png': ['.png'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/webp': ['.webp']
};

export function UpdateSpecialOfferModal(props: UpdateSpecialOfferModalProps) {
    const [form, setForm] = useState<UpdateSpecialOfferModalForm>({
        validated: false,
        active: props.specialOffer.active,
        type: props.specialOffer.type,
        titleEt: props.specialOffer.titleEt,
        titleEn: props.specialOffer.titleEn,
        image : {
            fileName: props.specialOffer.originalFileName,
            uploaded: false
        }
    });

    const [saving, setSaving] = useState(false);

    const validateSubmit = () => {
        if (validate()) {
            submit();
        }
    }

    const validate = (): boolean => {
        setForm({ ...form, validated: true });

        if(form.active){
            if(!form?.image?.file){
                return false
            }
            if(!form.titleEt){
                return false;
            }
        }

        return true;
    }
    
    const submit = async () => {
        setSaving(true);
        
        try{
            if(form.image?.file && form.image?.uploaded){
                try{
                    await SpecialOfferService.uploadSpecialOffersImage(
                        form.image?.file,
                        props.specialOffer.id
                    );
                }catch(e){
                    toast("Faili üleslaadimine ebaõnnestus", {
                        position: "top-right",
                        type: 'error'
                    })
                    return;
                }
            }

            await SpecialOfferService.updateSpecialOffer(form, props.specialOffer.id);

            setSaving(false);

            props.onSuccess?.();
            props.handleModalHide();
        }catch(e){
            console.error(e);
        }finally{
            setSaving(false);
        }
    }

    const getImageFile = async () => {
        const file = await SpecialOfferService.getSpecialOffersImage(props.specialOffer.id);
        
        setForm({
            ...form,
            image : {
                ...form.image,
                file,
                uploaded: false
            }
        })
    }

    const onImageDrop = (file: File) => {
      setForm({
        ...form,
        image: {
            file,
            fileName: file.name,
            uploaded: true
        }
      })
    }

    const onImageRemove = () => {
      setForm({
        ...form,
        image: {
            fileName: undefined,
            file: undefined,
            uploaded: false
        }
      })
    }

    const btnIsDisabled = saving;

    const uploadFormIsInvalid = !form?.image?.file && form.active && form.validated;
    const currentSpecialOfferType = SPECIAL_OFFER_TYPES.find(specialOffer => specialOffer.value === form.type);

    return (
      <Modal
        size="lg"
        show={true}
        onHide={props.handleModalHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Muuda eripakkumist
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form 
                noValidate 
                validated={form.validated} 
                onSubmit={(e: any) => e.preventDefault()}
                id={"update-special-offer-form"}
                className={'w-full mt-1 align-self-center m-0'}
            >
                <Form.Group 
                    controlId={'form-special-offer-active'} 
                    className="row"
                >
                   <Form.Label 
                        className="col-3"
                    >
                        <span className="text-nowrap">Tüüp*</span>
                    </Form.Label>
                    <div className="col">
                        <Form.Group 
                            controlId={'form-special-offer-type'} 
                        >
                            <Select
                                options={SPECIAL_OFFER_TYPES}
                                value={currentSpecialOfferType}
                                onChange={(val: any) => setForm({
                                    ...form,
                                    type: val ? val.value : val
                                })}
                                isDisabled={saving}
                                className={'form-control form-select'}
                                placeholder='Vali tüüp'
                                required
                            />
                            <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                            <Form.Group 
                                controlId={'form-special-offer-active'} 
                                className="mt-3"
                            >
                                <Form.Check
                                    className='w-full form-check-input-no-label'
                                    id='form-special-offer-active-check'
                                    label='Aktiivne'
                                    checked={form.active}
                                    onChange={(e: any) => setForm({
                                        ...form,
                                        active: e.target.checked
                                    })}
                                    disabled={saving}
                                    type='checkbox'
                                />
                            </Form.Group>
                        </Form.Group>
                    </div>
                    <div className="col-5"/>
                </Form.Group> 
                <Form.Group className="row">
                    <Form.Label 
                        className="col-3"
                    >
                        <span className="text-nowrap d-flex flex-column">
                            <span>Lubatud grupid</span>
                        </span>
                    </Form.Label>
                    <div className="col-4">
                        {props.specialOffer?.allowedGroups ? (
                            props.specialOffer.allowedGroups.map((group, index) => (
                                <span key={index} className="mr-2 p-1 border">
                                    {group}
                                </span>
                            ))
                        ) : (
                            <span>Puudub</span>
                        )}
                    </div>
                </Form.Group>
                <Form.Group className="row">
                    <Form.Label 
                        className="col-3"
                    >
                        <span className="text-nowrap d-flex flex-column">
                            <span>Eripakkumise</span>
                            <span>eestikeelne tiitel*</span>
                        </span>
                    </Form.Label>
                    <div className="col-4">
                        <Form.Control
                            value={form.titleEt ?? ''}
                            onChange={(e: any) => setForm({
                                ...form, 
                                titleEt: e.target.value
                            })}
                            minLength={4}
                            type="text"
                            required
                        />
                        <Form.Control.Feedback className={'text-right'} type="invalid">Eestikeelne tiitel on kohustuslik!</Form.Control.Feedback>
                    </div>
                </Form.Group>
                <Form.Group className="row">
                    <Form.Label 
                        className="col-3"
                    >
                        <span className="text-nowrap d-flex flex-column">
                            <span>Eripakkumise</span>
                            <span>inglisekeelne tiitel</span>
                        </span>
                    </Form.Label>
                    <div className="col-4">
                        <Form.Control
                            value={form.titleEn ?? ''}
                            onChange={(e: any) => setForm({
                                ...form, 
                                titleEn: e.target.value
                            })}
                            minLength={4}
                            type="text"
                        />
                    </div>
                </Form.Group>
                <FileUploadFormGroup 
                    acceptedMimeTypes={ACCEPTED_MIME_TYPES}
                    isInvalid={uploadFormIsInvalid}
                    uploadedFile={form.image}
                    label="Pilt*"
                    onImageDrop={(file) => onImageDrop(file)}
                    onImageRemove={() => onImageRemove()}
                    getFile={() => getImageFile()}
                />
            </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex flex-row align-items-end justify-content-end flex-wrap mb-1">
            <Button 
                variant="info"
                onClick={() => props.handleModalHide()}
                type="button"
                className={"d-flex align-items-center"}
            >
                <span className='px-2'>Tühista</span>
            </Button>
            <Button variant="primary" onClick={validateSubmit} type="button" disabled={btnIsDisabled}
                    className="d-flex align-items-center">
                {
                    !saving &&
                    <span className='px-2'>Salvesta</span>
                }
                {
                    saving &&
                    <>
                        <span className='px-2'>Salvestamine…</span>
                        <Spinner size={"sm"} animation={"border"}/>
                    </>
                }
            </Button>
        </Modal.Footer>
      </Modal>    
    );
}
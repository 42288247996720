import {SortDirection} from "../../common/interfaces/IPageResponse";
import React from "react";

export interface ISortIcons<T> {
  sortDirection: SortDirection,
  sortedByField: T,
  currentSortColumn: T
}

export function SortIcons(props: ISortIcons<any>) {
  if (props.sortedByField.match(props.currentSortColumn)) {

    if (props.sortDirection === SortDirection.ASC)
      return (<i className='icon ml-1 icon--16 icon__sort-ascending'/>);

    return <i className='icon ml-1 icon--16 icon__sort-descending'/>

  } else {
    return (<i className='icon ml-1 icon--16 icon__sort-down-up--hover'/>)
  }
}
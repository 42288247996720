import React, {PureComponent} from 'react';

import {IPageResponse, SortDirection} from "../common/interfaces/IPageResponse";
import {IUser, IUsersSortFields} from "../components/Users/models";
import {UsersTable} from "../components/Users/UsersTable";
import {UsersService} from "../components/Users/Users.service";
import {UsersSearchBar} from "../components/Users/UsersSearchBar";
import {ModalType} from "../common/common-models";
import {EditUserModal} from "../components/Users/modals/EditUserModal";
import { IPageRequest } from 'common/interfaces/IPageRequest';
import { AddUsersModal } from 'components/Users/modals/AddUsersModal';
import { RemoveUserAdminRightsModal } from 'components/Users/modals/RemoveUserAdminRightsModal';

export interface IUsersState {
    users: IPageResponse<IUser> | null;
    pageRequest: IPageRequest<IUsersSortFields>;
    isEditModalVisible: boolean;
    isRemoveModalVisible: boolean;
    isAddUsersModalVisible: boolean;
    modalType: ModalType;
    user?: IUser;
    isRequesting: boolean;
}

class Users extends PureComponent<any, IUsersState> {
    constructor(props: any) {
        super(props);
        this.state = {
            users: null,
            isRequesting: false,
            pageRequest: {
                page: 0,
                size: 25,
                sortDirection: SortDirection.ASC,
                sortField: IUsersSortFields.ID,
                searchQuery: null
            },
            isEditModalVisible: false,
            isAddUsersModalVisible: false,
            isRemoveModalVisible: false,
            modalType: ModalType.CREATE
        };
        this.props.onLoadValidatePage();
    }

    getUsers = () => {
        this.setState({
            isRequesting: true
        })
        UsersService.getAllUsers(this.state.pageRequest).then((response: IPageResponse<IUser>) => {
            this.setState({
                users: response,
                isRequesting: false
            });
        }).catch(reason => {
            console.error(reason);
            this.setState({
                isRequesting: false
            });
        });
    };

    onPageChange = (targetPage: number) => {
        if (this.state.pageRequest.page + 1 !== targetPage) {
            this.setState({
                pageRequest: {
                    ...this.state.pageRequest,
                    page: targetPage - 1
                }
            }, () => {
                this.getUsers();
            })
        }
    };

    openEditModal = (user: IUser) => {
        this.setState({
            isEditModalVisible: true,
            user: user
        })
    };

    openRemoveModal = (user: IUser) => {
        this.setState({
            isRemoveModalVisible: true,
            user: user
        })
    };

    openAddUsersModal = () => {
        this.setState({
            isAddUsersModalVisible: true,
        })
    };

    componentDidMount(): void {
        this.getUsers();
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col">
                        <UsersSearchBar openAddUsersModal={this.openAddUsersModal}/>
                            <UsersTable 
                                users={this.state.users}
                                isRequesting={this.state.isRequesting}
                                onPageChange={this.onPageChange}
                                openEditModal={this.openEditModal}
                                openRemoveModal={this.openRemoveModal}
                            />
                        
                    </div>
                </div>
                {
                    (this.state.isEditModalVisible && this.state.user) &&
                        <EditUserModal
                            rejectCloseHandler={() => {
                                this.setState({isEditModalVisible: false})
                            }}
                            submitCloseHandler={() => {
                                this.setState({isEditModalVisible: false});
                                this.getUsers();
                            }}
                            editingUser={this.state.user}
                        />
                }
                {
                    this.state.isAddUsersModalVisible &&
                        <AddUsersModal
                            rejectCloseHandler={() => {
                                this.setState({isAddUsersModalVisible: false})
                            }}
                            submitCloseHandler={() => {
                                this.setState({isAddUsersModalVisible: false});
                                this.getUsers();
                            }}
                        />
                }
                {
                    (this.state.isRemoveModalVisible && this.state.user) &&
                        <RemoveUserAdminRightsModal
                            user={this.state.user}
                            handleConfirmReject={() => {
                                this.setState({isRemoveModalVisible: false})
                            }}
                            handleConfirmSubmit={() => {
                                this.setState({isRemoveModalVisible: false});
                                this.getUsers();
                            }}
                        />
                }
            </div>
        );
    }
}



export default Users;
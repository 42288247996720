import axios from 'axios';
import {apiService} from "../../services/api.service";
import {convertToPageable, extractData, handleResponse} from "../../helpers/apiResponseHelper";
import {IPageResponse} from "../../common/interfaces/IPageResponse";
import {IUser} from "./models";

async function getAllUsers(pageRequest: any): Promise<IPageResponse<any>> {
    return axios.get(apiService.getApiUrl() + '/users/admin', {
        params: pageRequest
    }).then(handleResponse).then(convertToPageable);
}

async function searchNonAdminUsers(searchTerm: string): Promise<IUser[]> {
    return axios.get(apiService.getApiUrl() + '/users/admin/search', {
        params: {
            searchTerm
        }
    }).then(handleResponse).then(extractData);
}

async function patchUser(userId: number, adminActive: boolean) {
    return axios.patch(apiService.getApiUrl() + '/users/admin/' + userId,
        {
            adminActive
        }).then(handleResponse).then(extractData);
}

async function postAdminUsers(userIds: number[]) {
    return axios.post(apiService.getApiUrl() + '/users/admin',
        {
            userIds
        }).then(handleResponse).then(extractData);
}

async function syncUserData(userId: number) {
    return axios.get(apiService.getApiUrl() + '/users/admin/sync/' + userId)
        .then(handleResponse).then(extractData);
}

async function removeAdminRightsFromUser(userId: number){
    return axios.delete(apiService.getApiUrl() + `/users/admin/${userId}/rights`)
}

export const UsersService = {
    getAllUsers,
    postAdminUsers,
    patchUser,
    syncUserData,
    searchNonAdminUsers,
    removeAdminRightsFromUser
};
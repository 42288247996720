import React from 'react';

export function Footer(props: { utilityPageState: boolean }) {
    const ifUtilityFooter = props.utilityPageState ? 'utilityPage' : '';
    return (
        <>
            <footer className={ifUtilityFooter}>
                <div className='footer__illustration' />
                <div className="footer__container">
                    <div className={'d-none d-md-flex flex-column col-md ' + (props.utilityPageState ? 'text-primary': 'text-white')}>
                        <span>AS Terminal</span>
                        <span>tel + 372 749 7248</span>
                        <span>Kärkna, Tartu vald 60513 Tartumaa</span>
                        <a
                            className={props.utilityPageState ? 'text-primary': 'text-white'}
                            href='mailto:info@terminaloil.ee'>
                            info@terminaloil.ee
                        </a>
                    </div>
                    <div className='d-none d-xl-block col-xl'/>
                </div>
            </footer>
        </>
    )
}
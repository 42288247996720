import React from "react";
import { ADVERTISEMENT_TYPE_MAP, AdvertisementSortFields, IAdvertisement } from "./models";
import { SortIcons } from "components/SortIcons/SortIcons";
import { SortDirection } from "common/interfaces/IPageResponse";
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";

interface AdvertisementsTableProps {
  advertisements: IAdvertisement[];
  onSort: (sortField: AdvertisementSortFields) => void;
  sortField: AdvertisementSortFields;
  sortDirection: SortDirection;
  onUpdateAdvertisement: (advertisement: IAdvertisement) => void;
  onDeleteAdvertisement: (advertisement : IAdvertisement) => void;
  getAdvertisementParticipantsExport : (advertisementId: number) => void;
  advertisementToLoad?: number;
}

export function AdvertisementsTable(props: AdvertisementsTableProps) {
    const advertisementTableRows = props.advertisements.map((advertisement: IAdvertisement) =>
        <tr key={'row-' + advertisement.id}>
            <td>
                {advertisement.id}
            </td>
            <td>
                {advertisement.textEt}
            </td>
            <td>
                {ADVERTISEMENT_TYPE_MAP[advertisement.type]}
            </td>
            <td>
                {advertisement.orderNumber}
            </td>
            <td>
                {advertisement.active ? "Aktiivne" : "Mitteaktiivne"}
            </td>   
            <td>
                <div className="row__actions">
                    <OverlayTrigger
                            delay={250}
                            overlay={
                                <Tooltip id={`update-advertisement-tooltip-${advertisement.id}`}>
                                    <p style={{margin: '3px'}}>Muuda</p>
                                </Tooltip>
                            } 
                            placement={"left"}
                    >
                        <Button 
                            size="sm"                             
                            variant="secondary"
                            className="d-flex align-items-center btn-outline-icon" 
                            onClick={() => props.onUpdateAdvertisement(advertisement)}
                            >
                                <i className="icon--16 my-2 icon__edit--outline-mask"/>
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger delay={250}
                                    overlay={
                                        <Tooltip id={`tooltip-${advertisement.id}`}>
                                            <p style={{margin: '3px'}}>Kustuta</p>
                                        </Tooltip>} placement={"left"}>
                        <Button size="sm" variant="outline-danger"
                                className="d-flex align-items-center border-color-background border-radius-circle btn-outline-icon"
                                onClick={() => props.onDeleteAdvertisement(advertisement)}>
                            <i className="icon--16 my-2 icon__delete--outline-mask"/>
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                            delay={250}
                            overlay={
                                <Tooltip id={`advertisement-participants-export-tooltip-${advertisement.id}`}>
                                    <p style={{margin: '3px'}}>Reklaamis osalejate eksport</p>
                                </Tooltip>
                            } 
                            placement={"left"}
                    >
                         <Button 
                            size="sm"                             
                            variant="secondary"
                            className="d-flex align-items-center btn-outline-icon" 
                            onClick={() => props.getAdvertisementParticipantsExport(advertisement.id)}
                        >
                            {
                                props.advertisementToLoad === advertisement.id ? (
                                    <Spinner animation="border" size="sm"/> 
                                ) : (
                                    <i className="icon--16 my-2 icon__file action-icon"/>
                                )
                            }
                        </Button>
                    </OverlayTrigger>
                </div>
            </td>
        </tr>
    );

    return (
        <table className='generic-table table table-responsive-md table-hover'>
            <thead>
            <tr>
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(AdvertisementSortFields.ID)}>
                        <span>Id</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={AdvertisementSortFields.ID} />
                    </div>
                </th> 
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(AdvertisementSortFields.TEXT)}>
                        <span>Tekst</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={AdvertisementSortFields.TEXT} />
                    </div>
                </th> 
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(AdvertisementSortFields.TYPE)}>
                        <span>Tüüp</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={AdvertisementSortFields.TYPE} />
                    </div>
                </th> 
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(AdvertisementSortFields.ORDER_NUMBER)}>
                        <span>Järjekorra number</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={AdvertisementSortFields.ORDER_NUMBER} />
                    </div>
                </th> 
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(AdvertisementSortFields.ACTIVE)}>
                        <span>Staatus</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={AdvertisementSortFields.ACTIVE} />
                    </div>
                </th>    
            </tr>
            </thead>
            <tbody>
                {advertisementTableRows}
            </tbody>
        </table>
    )
}
import React, {useState} from 'react';
import {Button, Modal, Spinner} from "react-bootstrap";
import TanksService from 'services/tanks.service';
import { ITank } from '../models';

interface DeleteTankConfirmModalProps {
    tank: ITank;
    handleSubmitClose: () => void;
    handleRejectClose: () => void;
}

export function DeleteTankConfirmModal(props: DeleteTankConfirmModalProps) {
    const [saving, setSaving]: [boolean, any] = useState(false);
    const [savingError, setSavingError]: [boolean, any] = useState(false);

    const confirmSubmit = () => {
        setSaving(true);
        setSavingError(false);
        TanksService.deleteTank(props.tank.id)
            .then(() => {
                setTimeout(() => {
                    setSaving(false);
                    props.handleSubmitClose();
                }, 100);
            }).catch((reason: any) => {
            console.error('Saving failed', reason);
            setSaving(false);
            setSavingError(true);
        });
    };
    const rejectSubmit = () => {
        props.handleRejectClose();
    };

    return (
        <>
            <Modal
                show={true}
                size={"sm"}
                centered
            >
                <Modal.Header>
                    <Modal.Title>
                        Mahuti kustutamine
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="">
                        <>
                            <span>Kas oled kindel, et soovid mahuti</span>
                            <strong>&nbsp;{props.tank.name}&nbsp;</strong>
                            <span>kustutada?</span>
                        </>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex flex-row align-items-end justify-content-around flex-wrap mb-1">
                    <Button variant="info"
                            onClick={() => rejectSubmit()}
                            type="button"
                            className={"d-flex align-items-center"}>
                        <span className='px-2'>Loobu</span>
                    </Button>
                    <Button variant="danger"
                            onClick={confirmSubmit}
                            type="button"
                            className="d-flex align-items-center">
                        {
                            !saving &&
                            <span className='px-2'>Kustuta</span>
                        }
                        {
                            saving &&
                            <>
                                <span className='px-2'>Kustutamine…</span>
                                <Spinner size={"sm"} animation={"border"}/>
                            </>
                        }
                    </Button>
                </Modal.Footer>
                {
                    !saving && savingError &&
                    <span className="d-flex justify-content-end text-danger px-2 pb-2">
                        Viga salvestamisel, proovi mõne hetke pärast uuesti.
                    </span>
                }
            </Modal>
        </>
    );
}

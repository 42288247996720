import React from 'react'
import {useDropzone} from 'react-dropzone'

interface FileUploadFormProps {
  onDrop: (file: File) => void;
  className?: string;
  acceptedMimeTypes: Record<string, string[]>
}

export function FileDropzone({
  onDrop, 
  className,
  acceptedMimeTypes
}: FileUploadFormProps){
  
  const handleOnDrop = (acceptedFiles: File[]) => {
    onDrop(acceptedFiles[0]);
  }

  const {getRootProps, getInputProps} = useDropzone({
    onDrop: handleOnDrop,
    multiple: false,
    accept: acceptedMimeTypes
  })

  return (
    <div {...getRootProps()} className={`file-upload-form ${className}`}>
      <input {...getInputProps()} />
        <div className='text-container'>
          <div className='icon__upload action-icon mb-2' />
          <p>Faili üleslaadimiseks lohista fail siia või</p>
          <p>kliki, et valida arvutist</p>
        </div>
    </div>
  )
}
import React from 'react';


export function Frontpage() {
    return (
        <main className='admin-header-margin'>
            <div className='frontpage__left-half'>
            </div>
        </main>
    )
}
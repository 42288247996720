import axios from 'axios';
import {apiService} from "../../services/api.service";
import {IAlteredCustomerContact} from "./PermissionsModal";

export const PermissionsService = {
    postRoleChanges: postRoleChanges
};

async function postRoleChanges(changedCustomerRoleData: IAlteredCustomerContact): Promise<string> {
    return new Promise((resolve, reject) => {
        axios.patch(
            apiService.getApiUrl() + "/customer-role",
            {
                customerId: changedCustomerRoleData.customerId,
                contactPersonId: changedCustomerRoleData.id,
                roleCodes: changedCustomerRoleData.roles
            }).then(
            response => {
                resolve(response.data.data)
            }
        ).catch(error => {
            if (error.response) {
                reject(error.response.status)
            } else {
                reject(500)
            }
        });
    });
}

export interface ITank {
    id: number;
    deviceSerialNumber: string;
    name: string;
    sensor: string;
    latestAmount: number;
    capacity: number;
    notificationThreshold: number;
    latestUpdateTime: string | null;
    dateModified: string | null;
    customers: { name: string, id: number }[];
}

export interface PostPutTankRequest {
    deviceSerialNumber: string;
    name: string;
    sensor: string;
    capacity: number;
    notificationThreshold: number;
}

export enum ITanksSortFields {
    ID = "ID",
    NAME = "NAME",
    SENSOR = "SENSOR",
    DEVICE_SERIAL_NUMBER = "DEVICE_SERIAL_NUMBER",
    LATEST_AMOUNT = "LATEST_AMOUNT",
    LATEST_UPDATE_TIME = "LATEST_UPDATE_TIME",
    NOTIFICATION_THRESHOLD = "NOTIFICATION_THRESHOLD",
    CAPACITY = "CAPACITY"
}

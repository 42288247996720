import React, { useState } from 'react';
import {Button, Modal} from "react-bootstrap";
import { IUser } from '../models';
import { UsersService } from '../Users.service';

interface IRemoveUserAdminRightsModalProps {
    user: IUser;
    handleConfirmSubmit: () => void;
    handleConfirmReject: () => void;
}

export function RemoveUserAdminRightsModal(props: IRemoveUserAdminRightsModalProps) {
    const [saving, setSaving]: [boolean, any] = useState(false);
    const [savingError, setSavingError]: [boolean, any] = useState(false);
    
    const onSubmit = async () => {
        setSaving(true);
        setSavingError(false);

        try{
            await UsersService.removeAdminRightsFromUser(
                props.user.id
            );

            setSaving(false);
            setTimeout(() => {
                props.handleConfirmSubmit();
            }, 300);
        }catch(e){
            console.error('Saving failed', e);
            setSaving(false);
            setSavingError(true);
        }
    }

    return (
        <>
            <Modal
                show={true}
                size={"sm"}
                centered
            >
                <Modal.Header>
                    <Modal.Title>
                        Kasutaja eemaldamine
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <span>
                      Kas oled kindel, et soovid kasutajat<strong>&nbsp;{props.user.name}&nbsp;</strong> eemaldada administraatorite hulgast?
                    </span>
                </Modal.Body>
                <Modal.Footer className="d-flex flex-row align-items-end justify-content-around flex-wrap mb-1">
                    <Button variant="info"
                            onClick={() => props.handleConfirmReject()}
                            type="button"
                            className={"d-flex align-items-center"}>
                        <span className='px-2'>Tühista</span>
                    </Button>
                    <Button variant="primary"
                            onClick={onSubmit}
                            type="button"
                            className="d-flex align-items-center">
                        <span className='px-2'>Eemalda</span>
                    </Button>
                </Modal.Footer>
                {
                    !saving && savingError &&
                    <span className="d-flex justify-content-end text-danger px-2 pb-2">
                        Eemaldamisel tekkis viga, proovi mõne hetke pärast uuesti.
                    </span>
                }
            </Modal>
        </>
    );
}
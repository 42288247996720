import { ISearchRequest, ISortRequest } from "common/interfaces/IPageRequest";

export interface IAdvertisement {
  id: number;
  textEt?: string;
  textEn?: string;
  linkEt?: string;
  linkEn?: string;
  orderNumber: number;
  active: boolean;
  isAdTextActive: boolean;
  type : AdvertisementType;
  originalFileNameEt: string;
  originalFileNameEn: string;
  participatable: boolean;
}

export enum AdvertisementType {
  FOOD = "FOOD",
  DASHBOARD = "DASHBOARD"
}

export const ADVERTISEMENT_TYPE_MAP: Record<AdvertisementType, string> = {
  FOOD: 'Toit',
  DASHBOARD: 'Töölaud'
};

export interface AdvertisementsState {
  advertisements: IAdvertisement[] | null;
  request: ISortRequest<AdvertisementSortFields> & ISearchRequest;
  filters: AdvertisementFilters;
  isRequesting: boolean;
  advertisementToLoad?: number;
  activeAdvertisement?: IAdvertisement;
  isCreateModalVisible: boolean;
  isUpdateModalVisible: boolean;
  isDeleteModalVisible: boolean;
}

export enum AdvertisementSortFields {
  ID = "ID",
  TEXT = 'TEXT',
  TYPE = 'TYPE',
  ORDER_NUMBER = 'ORDER_NUMBER',
  ACTIVE = 'ACTIVE'
}

export interface AdvertisementFilters {
  search?: string;
  type?: AdvertisementType;
  active?: boolean;
}
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';

interface ISwitchButton {
    className?: string
    onActiveChange: (activeSide: SwitchButtonState) => void,
    labelLeft: string,
    labelMiddle: string,
    labelRight: string,
    size?: 'sm' | 'md'
}

export enum SwitchButtonState {
    LEFT,
    MIDDLE,
    RIGHT
}

export function SwitchButton(props: ISwitchButton) {
    const [activeButton, setActiveButton]: [SwitchButtonState, any] = useState(SwitchButtonState.LEFT);

    const labelLeft = props.labelLeft;
    const labelMiddle = props.labelMiddle;
    const labelRight = props.labelRight;
    const size = props.size ? props.size : 'md';

    const leftButtonClicked = () => {
        setActiveButton(SwitchButtonState.LEFT);
    };
    const middleButtonClicked = () => {
        setActiveButton(SwitchButtonState.MIDDLE);
    }
    const rightButtonClicked = () => {
        setActiveButton(SwitchButtonState.RIGHT);
    };
    const {onActiveChange} = props;
    useEffect(() => {
        onActiveChange(activeButton);
    }, [activeButton]);

    const wrapperClassNames = "button__switch switch-"  + size;

    return (
        <div className={props.className ? wrapperClassNames + " " + props.className : wrapperClassNames}>
            <button
                onClick={leftButtonClicked}
                type="button"
                className={`button-action text-nowrap ${activeButton === SwitchButtonState.LEFT ? 'active-left' : ''}`}>
                <span>{labelLeft}</span>
            </button>
            <button
                onClick={middleButtonClicked}
                type="button"
                className={`button-action text-nowrap ${(activeButton !== SwitchButtonState.LEFT && activeButton !== SwitchButtonState.RIGHT) ? 'active-middle' : ''}`}>
                <span>{labelMiddle}</span>
            </button>
            <button
                onClick={rightButtonClicked}
                type="button"
                className={`button-action text-nowrap ${(activeButton !== SwitchButtonState.LEFT && activeButton !== SwitchButtonState.MIDDLE) ? 'active-right' : ''}`}>
                <span>{labelRight}</span>
            </button>
        </div>
    );
}

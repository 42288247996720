import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { LOCAL_STORAGE_KEYS } from "common/constants/localStorageKeys";
import { AuthenticationService } from "services/authentication.service";

export const AXIOS_AUTHORIZATION_HEADER = "x-auth-token";

async function handleUnauthorized(config : AxiosRequestConfig) {
  if (config.url?.toString().includes('/admin/auth/refresh')) {
    handleForbidden();
  } else {
    await AuthenticationService.refresh();
    return await axios.request(getConfig(config));
  }
}

async function handleForbidden(){
  AuthenticationService.logout();
}

function handleToken(jwt : string){
  localStorage.setItem(LOCAL_STORAGE_KEYS.AUTHORIZATION_HEADER, jwt);
}

function getConfig(config : AxiosRequestConfig) : AxiosRequestConfig {
  if (!config.url?.toString().includes('/admin/auth/refresh')) {
      const newConfig = config ? config : {};
  
      if (!newConfig.headers) {
        newConfig.headers = new Headers();
      }
  
      const authToken = localStorage.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION_HEADER);
      const adjustedToken = process.env.REACT_APP_API_AUTHORIZATION_PREFIX! + authToken;

      if (newConfig.headers instanceof Headers) {
        newConfig.headers.set(LOCAL_STORAGE_KEYS.AUTHORIZATION_HEADER, authToken ? adjustedToken : '');
      } else if (newConfig.headers instanceof Array) {
        newConfig.headers.push([LOCAL_STORAGE_KEYS.AUTHORIZATION_HEADER, authToken ? adjustedToken : '']);
      } else {
        newConfig.headers[LOCAL_STORAGE_KEYS.AUTHORIZATION_HEADER] = authToken ? adjustedToken : '';
      }
  
      return newConfig;
    } else {
      return config;
    }
}

export function axiosInterceptor(){
  axios.interceptors.request.use(function (config: AxiosRequestConfig) {
    // Do something before request is sent
    return getConfig(config);
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });


  axios.interceptors.response.use(function (response: AxiosResponse) {
      // Status code range of 2xx
      const authToken = response.headers[AXIOS_AUTHORIZATION_HEADER];
      if (authToken) {
          handleToken(
              authToken
          );
      }

      return response;
    }, function (error : AxiosError) {
        // Status codes that falls outside the range of 2xx
        if (error && error.response) {
            if(401 === error.response.status){
              return handleUnauthorized(error.config)
            }else if(403 === error.response.status){
              return handleForbidden();
            }
        }
        return Promise.reject(error);
  });
}
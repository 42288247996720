import { AgreementFilterType, AGREEMENT_TYPE_FILTER_OPTIONS } from 'models/agreements';
import React, {PureComponent} from 'react';
import Select from 'react-select';


export function selectTheme(theme: any) {
    return ({
        ...theme,
        borderWidth: 2,
        colors: {
            ...theme.colors,
            text: '#a5d6db',
            primary25: '#a5d6db',
            primary: '#008C9A',
        },
    })
}

export class AgreementsSearchBar extends PureComponent<any, any> {
    state = {
        selectedOptionA: null,
        selectedOptionB: null,
    };


    customStyles = {
        option: (provided: any, state: any) => ({
            ...provided,
            borderBottom: '2px dotted green',
            color: state.isSelected ? 'yellow' : 'black',
            backgroundColor: state.isSelected ? 'green' : 'white'
        }),
        control: (provided: any) => ({
            ...provided,
            marginTop: "5%",
        })
    };

    onInputChange = (inputEvent: any) => {
        this.props.onSearch({
            searchQuery: inputEvent.target.value
        });

    };

    render() {

        return (
            <div className="bar-position">
                <h2 className='text-light'>
                    Lepingud
                </h2>
                <div className="card mb-5 shadow">
                    <div className="card-body">
                        <div className='d-flex flex-wrap flex-sm-nowrap'>
                            <input
                                placeholder="Klient / Lepingu number"
                                className='search-bar__input'
                                onChange={this.onInputChange}
                                value={this.props.filters[AgreementFilterType.SEARCH]}
                            />

                            <Select
                                options={AGREEMENT_TYPE_FILTER_OPTIONS}
                                value={this.props.filters[AgreementFilterType.STATUS]}
                                onChange={(val) => this.props.onFilterChange(AgreementFilterType.STATUS, val)}
                                className='mw-192-px mr-md-4 mr-0 mt-2 mt-md-0 invoices__select'
                                placeholder='Vali staatus'
                                isClearable
                                theme={selectTheme}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

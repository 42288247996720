/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { AdvertisementType, IAdvertisement } from "../models";
import Select from 'react-select';
import { AdvertisementService } from "../Advertisement.service";
import { LanguageOption, ModalProps, ModalType } from "common/common-models";
import { toast } from "react-toastify";
import { getImageProperty } from "helpers";
import { FileUploadFormGroup, UploadedFile } from "components/FileUpload/FileUploadFormGroup";

const ACCEPTED_MIME_TYPES = {
  'image/svg+xml': ['.svg'],
  'image/png': ['.png'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/webp': ['.webp']
};

const ADVERTISEMENT_TYPES = [
    { label: "Töölaud", value: AdvertisementType.DASHBOARD },
    { label: "Toit", value: AdvertisementType.FOOD }
]

export interface EditAdvertisementModalForm {
    validated: boolean;
    active: boolean;
    isAdTextActive: boolean;
    textEt?: string;
    textEn?: string;
    linkEt?: string;
    linkEn?: string;
    orderNumber?: number;
    type?: AdvertisementType;
    imageEt?: UploadedFile;
    imageEn?: UploadedFile;
    participatable: boolean;
}

interface EditAdvertisementModalProps extends ModalProps {
    advertisement?: IAdvertisement;
  }

export function EditAdvertisementModal(props: EditAdvertisementModalProps) {
    const getInitialState = () : EditAdvertisementModalForm => {
        let baseState : Omit<EditAdvertisementModalForm, "validated">;
        if(props.advertisement){
            baseState = {
                active: props.advertisement.active,
                isAdTextActive: props.advertisement.isAdTextActive,
                textEt: props.advertisement.textEt,
                textEn: props.advertisement.textEn,
                linkEt: props.advertisement.linkEt,
                linkEn: props.advertisement.linkEn,
                orderNumber : props.advertisement.orderNumber,
                type: props.advertisement.type,
                imageEt: {
                    fileName: props.advertisement.originalFileNameEt,
                    uploaded: false
                },
                imageEn: {
                    fileName: props.advertisement.originalFileNameEn,
                    uploaded: false
                },
                participatable: props.advertisement.participatable
            }
        }else{
            baseState = {
                active: false,
                isAdTextActive: false,
                participatable: false
            }
        }

        return {
            validated: false,
            ...baseState
        }
    }

    const [form, setForm] = useState<EditAdvertisementModalForm>(getInitialState());
    const [saving, setSaving] = useState(false);

    const validateSubmit = () => {
        if (validate()) {
            submit();
        }
    }

    const validate = (): boolean => {
        setForm({ ...form, validated: true });

        if(form.active && !form.imageEt?.file){
            return false;
        }

        if(!form.type || !form.orderNumber){
            return false;
        }

        return true;
    }
    
    const submit = async () => {
        setSaving(true);
        try{
            let response;
            if(props.modalType === ModalType.EDIT){
                response = await AdvertisementService.updateAdvertisement(form, props.advertisement!.id);
            }else{
                response = await AdvertisementService.createAdvertisement(form);
            }
        
            await uploadFile(response.id, LanguageOption.ET)
            await uploadFile(response.id, LanguageOption.EN)

            setSaving(false);

            props.onSuccess?.();
            props.handleModalHide();
        }catch(e){
            console.error(e);
        }finally{
            setSaving(false);
        }
    }

    const uploadFile = async (advertisementId: number, lang: LanguageOption) => {
        const file = form[getImageProperty(lang)]?.file;
        const isUploaded = form[getImageProperty(lang)]?.uploaded;
        
        if(!file || !isUploaded){
            return;
        }

        try{
            await AdvertisementService.uploadAdvertisementsImage(
                file,
                advertisementId,
                lang
            );
        }catch(e){
            let languageType = "Eestikeelse";
            if(lang === LanguageOption.EN){
                languageType = "Inglisekeelse" 
            }
            toast(`${languageType} faili üleslaadimine ebaõnnestus`, {
                position: "top-right",
                type: 'error'
            })
            return;
        }
    }

    const getImageFile = async (lang: LanguageOption) => {
        const file = await AdvertisementService.getAdvertisementsImage(
            props.advertisement?.id!,
            lang
        );
        setForm((prevForm) => ({
            ...prevForm,
            [getImageProperty(lang)]: {
                ...prevForm[getImageProperty(lang)],
                file,
                uploaded: false
            }
        }))
    }

    const onImageDrop = (file: File, lang: LanguageOption) => {
        setForm({
            ...form,
            validated: false,
            [getImageProperty(lang)] : {
              file,
              fileName: file.name,
              uploaded: true
            }
          })
    }

    const onImageRemove = (lang: LanguageOption) => {
        setForm({
            ...form,
            [getImageProperty(lang)] : {
                fileName: undefined,
                file: undefined,
                uploaded: false
            }
        })
    }

    const btnIsDisabled = saving;

    const uploadFormIsInvalid = !form?.imageEt?.file && form.active && form.validated;
    const currentTypeOption = props.advertisement ? ADVERTISEMENT_TYPES.find(ad=> ad.value === form.type) : undefined;
    const modalTitle = props.modalType === ModalType.CREATE ? "Loo reklaam" : "Uuenda reklaami";

    return (
      <Modal
        size="lg"
        show={true}
        onHide={props.handleModalHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {modalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form 
                noValidate 
                validated={form.validated} 
                onSubmit={(e: any) => e.preventDefault()}
                id={"edit-advertisement-form"}
                className={'w-full mt-1 align-self-center m-0'}
            >
                <Form.Group 
                    controlId={'form-advertisement-text-in-estonian'} 
                    className="row"
                >
                   <Form.Label 
                        className="col-3"
                    >
                        <span className="text-nowrap d-flex flex-column">
                            <span>Reklaami</span>
                            <span>eestikeelne tekst</span>
                        </span>                    
                    </Form.Label>
                    <div className="col">
                        <Form.Control
                            placeholder="Reklaami eestikeelne tekst"
                            as="textarea"
                            value={form.textEt}
                            rows={2}
                            onChange={(e: any) => setForm({
                                ...form, 
                                textEt : e.target.value
                            })}
                        />
                    </div>
                    <div className="col-3"/>
                </Form.Group> 
                <Form.Group 
                    controlId={'form-advertisement-text-in-english'} 
                    className="row"
                >
                   <Form.Label 
                        className="col-3"
                    >
                         <span className="text-nowrap d-flex flex-column">
                            <span>Reklaami</span>
                            <span>inglisekeelne tekst</span>
                        </span>
                    </Form.Label>
                    <div className="col">
                        <Form.Control
                            placeholder="Reklaami inglisekeelne tekst"
                            as="textarea"
                            value={form.textEn}
                            rows={2}
                            onChange={(e: any) => setForm({
                                ...form, 
                                textEn : e.target.value
                            })}
                        />
                    </div>
                    <div className="col-3"/>
                </Form.Group> 
                <Form.Group 
                    controlId={'form-advertisement-active'} 
                    className="row"
                >
                   <Form.Label 
                        className="col-3"
                    >
                        <span className="text-nowrap">Kuva teksti reklaami all*</span>
                    </Form.Label>
                    <div className="col">
                        <Form.Check
                            className='w-full form-check-input-no-label'
                            id='form-advertisement-is-ad-text-active-check'
                            checked={form.isAdTextActive}
                            onChange={(e: any) => setForm({
                                ...form,
                                isAdTextActive: e.target.checked
                            })}
                            disabled={saving}
                            type='checkbox'
                        />
                    </div>
                    <div className="col-3"/>
                </Form.Group> 
                <Form.Group 
                    controlId={'form-advertisement-link-in-estonian'} 
                    className="row"
                >
                   <Form.Label 
                        className="col-3"
                    >
                         <span className="text-nowrap d-flex flex-column">
                            <span>Reklaami</span>
                            <span>eestikeelne link</span>
                        </span>
                    </Form.Label>
                    <div className="col">
                        <Form.Control
                            placeholder="Reklaami eestikeelne link"
                            type="text"
                            value={form.linkEt}
                            onChange={(e: any) => setForm({
                                ...form, 
                                linkEt : e.target.value
                            })}
                        />
                    </div>
                    <div className="col-3"/>
                </Form.Group> 
                <Form.Group 
                    controlId={'form-advertisement-link-in-english'} 
                    className="row"
                >
                   <Form.Label 
                        className="col-3"
                    >
                         <span className="text-nowrap d-flex flex-column">
                            <span>Reklaami</span>
                            <span>inglisekeelne link</span>
                        </span>
                    </Form.Label>
                    <div className="col">
                        <Form.Control
                            placeholder="Reklaami inglisekeelne link"
                            type="text"
                            value={form.linkEn}
                            onChange={(e: any) => setForm({
                                ...form, 
                                linkEn : e.target.value
                            })}
                        />
                    </div>
                    <div className="col-3"/>
                </Form.Group> 
                <Form.Group 
                    controlId={'form-advertisement-type'} 
                    className="row"
                >
                    <Form.Label 
                        className="col-3"
                    >
                        <span className="text-nowrap">Tüüp*</span>
                    </Form.Label>
                    <div className="col">
                        <Select
                            options={ADVERTISEMENT_TYPES}
                            value={currentTypeOption}
                            onChange={(val: any) => setForm({
                                ...form,
                                type: val.value
                            })}
                            isDisabled={saving}
                            className={'form-control form-select' + (!form.type && form.validated ? ' is-invalid' : '')}
                            placeholder='Vali tüüp'
                            required
                        />
                        <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                    </div>
                    <div className="col-3"/>
                </Form.Group>
                <Form.Group 
                    controlId={'form-advertisement-order-number'} 
                    className="row"
                >
                   <Form.Label 
                        className="col-3"
                    >
                        <span className="text-nowrap">Järjekorra number*</span>
                    </Form.Label>
                    <div className="col">
                        <Form.Control
                            required
                            className="col-3"
                            value={form.orderNumber}
                            onChange={(e) => {
                                const valueAsNumber = Number(e.target.value);
                                setForm({
                                    ...form,
                                    orderNumber: isNaN(valueAsNumber) ? form.orderNumber : Math.abs(valueAsNumber)
                                })
                            }}
                            disabled={saving}
                            type="number"
                        />
                        <Form.Control.Feedback className={'text-left'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                    </div>
                    <div className="col-3"/>
                </Form.Group>  
                <Form.Group 
                    controlId={'form-advertisement-active'} 
                    className="row"
                >
                   <Form.Label 
                        className="col-3"
                    >
                        <span className="text-nowrap">Staatus*</span>
                    </Form.Label>
                    <div className="col">
                        <Form.Check
                            className='w-full form-check-input-no-label'
                            id='form-advertisement-status-check'
                            label='Aktiivne'
                            checked={form.active}
                            onChange={(e: any) => setForm({
                                ...form,
                                active: e.target.checked
                            })}
                            disabled={saving}
                            type='checkbox'
                        />
                    </div>
                    <div className="col-3"/>
                </Form.Group> 
                <Form.Group 
                    controlId={'form-advertisement-participatable'} 
                    className="row"
                >
                   <Form.Label 
                        className="col-3"
                    >
                        <span className="text-nowrap">Staatus*</span>
                    </Form.Label>
                    <div className="col">
                        <Form.Check
                            className='w-full form-check-input-no-label'
                            id='form-advertisement-participatable-check'
                            label='Osalemise võimalus'
                            checked={form.participatable}
                            onChange={(e: any) => setForm({
                                ...form,
                                participatable: e.target.checked
                            })}
                            disabled={saving}
                            type='checkbox'
                        />
                    </div>
                    <div className="col-3"/>
                </Form.Group> 
                <FileUploadFormGroup 
                    acceptedMimeTypes={ACCEPTED_MIME_TYPES}
                    isInvalid={uploadFormIsInvalid}
                    uploadedFile={form.imageEt}
                    label="Eestikeelne pilt*"
                    onImageDrop={(file) => onImageDrop(file, LanguageOption.ET)}
                    onImageRemove={() => onImageRemove(LanguageOption.ET)}
                    getFile={() => getImageFile(LanguageOption.ET)}
                />
                <FileUploadFormGroup 
                    acceptedMimeTypes={ACCEPTED_MIME_TYPES}
                    uploadedFile={form.imageEn}
                    label="Inglisekeelne pilt"
                    onImageDrop={(file) => onImageDrop(file, LanguageOption.EN)}
                    onImageRemove={() => onImageRemove(LanguageOption.EN)}
                    getFile={() => getImageFile(LanguageOption.EN)}
                />
            </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex flex-row align-items-end justify-content-end flex-wrap mb-1">
            <Button 
                variant="info"
                onClick={() => props.handleModalHide()}
                type="button"
                className={"d-flex align-items-center"}
            >
                <span className='px-2'>Tühista</span>
            </Button>
            <Button variant="primary" onClick={validateSubmit} type="button" disabled={btnIsDisabled}
                    className="d-flex align-items-center">
                {
                    !saving &&
                    <span className='px-2'>Salvesta</span>
                }
                {
                    saving &&
                    <>
                        <span className='px-2'>Salvestamine…</span>
                        <Spinner size={"sm"} animation={"border"}/>
                    </>
                }
            </Button>
        </Modal.Footer>
      </Modal>    
    );
}
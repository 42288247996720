import React, { PureComponent } from 'react';
import { Button } from 'react-bootstrap';
import { ModalType } from 'common/common-models';

export class TanksSearchBar extends PureComponent<any, any> {
    state = {
        selectedOptionA: null,
        selectedOptionB: null,
    };

    render() {

        return (
            <div className="bar-position">
                <h2 className='text-light'>
                    Mahutid
                </h2>
                <div className="card mb-5 shadow">
                    <div className="card-body">
                        <div className='d-flex flex-wrap flex-sm-nowrap'>
                            <Button className="ml-auto d-flex align-items-center"
                                    onClick={() => this.props.onAddTank(ModalType.CREATE)}
                                    variant={"primary"}>
                                <i className="icon--16 icon__add d-flex mr-1"/>
                                <span>Lisa uus</span>
                            </Button>                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
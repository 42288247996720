import React from 'react';
import {ITank} from "../Tanks/models";

export function TankProgressBar(props: any) {
    const tank: ITank = props.tankRow;
    let filledPercentage: any = Number((tank.latestAmount * 100) / tank.capacity);
    let thresholdPercentage: any = Number((tank.notificationThreshold * 100) / tank.capacity);
    if (filledPercentage > 100) {
        filledPercentage = 100;
    }
    return (<div className="tank__progress-bar-container progress progress-striped active">
        <div style={{width: filledPercentage + '%'}} aria-valuemax={100} aria-valuemin={0} aria-valuenow={filledPercentage} role="progressbar"
             className={filledPercentage <= thresholdPercentage ? "progress-bar-danger progress-bar tank__progress-bar" : "progress-bar tank__progress-bar" }>
        </div>
        {tank.notificationThreshold !== null &&
        (<div className="threshold-line" style={{left: thresholdPercentage + '%'}}>
        </div>)}
    </div>)
}
import React from "react";
import { Form } from "react-bootstrap";
import { UpdateElectricityAgreementPackageModalForm } from "../../models";

interface UpdateElectricityAgreementPackageProductionPriceFormProps {
    form: UpdateElectricityAgreementPackageModalForm;
    setForm: (form: UpdateElectricityAgreementPackageModalForm) => void;
}

export default function UpdateElectricityAgreementPackageProductionPriceForm(
    { form, setForm } : UpdateElectricityAgreementPackageProductionPriceFormProps
) {
    
    return (
        <>
            <div className="row">
                <div className="col-12 col-md-6">
                    <Form.Label>Tootmise marginaal KM-ta (s/kWh)*</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        value={form.productionMarginal}
                        step={0.001}
                        onChange={(e: any) => setForm({
                            ...form,
                            productionMarginal: e.target.value
                        })}
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
            </div>
        </>
    );
}
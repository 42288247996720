
export enum ContactRole {
    AUTHENTICATED_USER = 'AUTHENTICATED_USER', // "Autentitud kasutaja",
    ADMIN = 'ADMIN', // "Peakasutaja",
    SUPPLIER = 'SUPPLIER', // "Tarnija",
    DISPENSER = 'DISPENSER', // "Väljastaja",
    CARRIER = 'CARRIER', // "Vedaja",
    BOOKER = 'BOOKER', // "Broneerija"
    VIEW_AGREEMENTS = 'VIEW_AGREEMENTS',
    VIEW_DOCUMENTS = 'VIEW_DOCUMENTS',
    VIEW_DOCUMENT_ROWS = 'VIEW_DOCUMENT_ROWS',
    VIEW_CARDS = 'VIEW_CARDS',
    VIEW_SALE_ORDERS = 'VIEW_SALE_ORDERS'
}

export const ADMIN_ROLES = [
    ContactRole.VIEW_AGREEMENTS,
    ContactRole.VIEW_CARDS,
    ContactRole.VIEW_DOCUMENTS,
    ContactRole.VIEW_DOCUMENT_ROWS,
    ContactRole.VIEW_SALE_ORDERS
]
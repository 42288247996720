import axios from 'axios';
import {apiService} from "./api.service";
import {convertToPageable, handleApiException, handleResponse} from "../helpers/apiResponseHelper";
import { PostPutTankRequest } from 'components/Tanks/models';



async function getAllTanks(pageRequest: any) {
    return axios.get(apiService.getApiUrl() + '/tanks/admin', {
        params: pageRequest
    }).then(handleResponse).then(convertToPageable);
}

async function postTank(request: PostPutTankRequest) {
    return axios.post(apiService.getApiUrl() + '/tanks/admin', request)
        .then(handleResponse).catch(handleApiException);
}

async function putTank(tankId: number, request: PostPutTankRequest) {
    return axios.put(apiService.getApiUrl() + '/tanks/admin/' + tankId, request)
        .then(handleResponse).catch(handleApiException);
}

async function deleteTank(tankId: number) {
    return axios.delete(apiService.getApiUrl() + '/tanks/admin/' + tankId)
        .then(handleResponse).catch(handleApiException);
}

const TanksService = {
    getAllTanks,
    postTank,
    putTank,
    deleteTank
};

export default TanksService;

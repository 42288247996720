import axios from 'axios';
import {apiService} from "../../services/api.service";
import {convertToPageable, extractData, handleApiException, handleResponse} from "../../helpers/apiResponseHelper";
import {IElectricityPackageForm} from "./models";

async function getElectricityPackages(pageRequest: any) {
    return axios.get(apiService.getApiUrl() + '/electricity/packages/admin', {
        params: pageRequest
    }).then(handleResponse).then(convertToPageable);
}

async function postElectricityPackage(request: IElectricityPackageForm) {
    return axios.post(apiService.getApiUrl() + '/electricity/packages/admin',
        {
            ...request
        }).then(handleResponse).then(extractData);
}

async function putElectricityPackage(electricityPackageId: number, request: IElectricityPackageForm) {
    return axios.put(apiService.getApiUrl() + '/electricity/packages/admin/' + electricityPackageId,
        {
            ...request
        }).then(handleResponse).then(extractData);
}

async function deleteElectricityPackage(electricityPackageId: number) {
    return axios.delete(apiService.getApiUrl() + '/electricity/packages/admin/' + electricityPackageId)
        .catch(handleApiException);
}

async function getElectricityPackage(electricityPackageId: number, includeDeleted?: boolean) {
    return axios.get(apiService.getApiUrl() + '/electricity/packages/admin/' + electricityPackageId + '?includeDeleted=' + includeDeleted)
        .then(handleResponse).then(extractData);
}

async function getElectricityPackageNames() {
    return axios.get(apiService.getApiUrl() + '/electricity/packages/admin/names')
        .then(handleResponse).then(extractData);
}

export const ElectricityPackagesService = {
    getElectricityPackages,
    postElectricityPackage,
    putElectricityPackage,
    deleteElectricityPackage,
    getElectricityPackageNames,
    getElectricityPackage
};
import { IPageRequest } from "common/interfaces/IPageRequest";
import { IPageResponse } from "common/interfaces/IPageResponse";

export interface ISpecialOffer {
  id: number;
  name: string;
  titleEt?: string;
  titleEn?: string;
  type?: SpecialOfferType;
  comment?: string;
  amount: number;
  startDate: string;
  endDate: string;
  active: boolean;
  fileName?: string;
  originalFileName?: string;
  allowedGroups?: string[];
}

export enum SpecialOfferType {
  FOOD = 'FOOD',
  UNCATEGORIZED = 'UNCATEGORIZED'
}

export const SPECIAL_OFFER_TYPE_MAP: Record<SpecialOfferType, string> = {
  FOOD: 'Toit',
  UNCATEGORIZED : 'Kategooriata'
};

export interface SpecialOffersState {
  specialOffers: IPageResponse<ISpecialOffer> | null;
  pageRequest: IPageRequest<SpecialOfferSortFields>;
  filters: SpecialOfferFilters;
  isRequesting: boolean;
  isUpdateModalVisible: boolean;
  activeSpecialOffer?: ISpecialOffer;
  isSyncing: boolean;
}

export enum SpecialOfferSortFields {
  ID = "ID",
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE',
  DATE_CREATED = 'DATE_CREATED',
  NAME = 'NAME',
  COMMENT = 'COMMENT',
  AMOUNT = 'AMOUNT',
  ACTIVE = 'ACTIVE',
  TYPE = 'TYPE'
}

export interface SpecialOfferFilters {
  searchQuery?: string;
  type?: SpecialOfferType;
  active?: boolean;
}
import React, {useState} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';

import {Header} from 'components/Header/Header';
import {Footer} from 'components/Footer/Footer';

import 'App.scss';
import {Pages} from 'pages/pages';
import AuthContext from "./services/context/authentication.context";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {pdfjs} from "react-pdf";
import { axiosInterceptor } from 'helpers/axiosInterceptor';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

declare global {
    interface Window { opera: any; }
}

window.opera = window.opera || undefined;

export default function App() {

    axiosInterceptor();

    const [isUtilityPage, setUtilityPage] = useState(true);
    const [currentPage, setCurrentPage] = useState({path: '/login', isPublic: true});

    const populateMain = Pages.map((page) =>
        <Route
            exact
            key={page.title}
            path={'/' + page.title}
            render={
                (props: any) =>
                    (<page.component
                        {...props} onLoadValidatePage={() => onLoadValidatePage(setUtilityPage, setCurrentPage,
                        page.isUtility, page.isPublic, props.match.path)}/>)
            }
        />);

    return (
        <BrowserRouter basename=''>
            <Route exact path='/'>
                <Redirect to='/tanklad'/>
            </Route>
            <AuthContext currentPage={currentPage}>
                <Header utilityPageState={isUtilityPage}/>
                <main className={isUtilityPage ? 'utilityPage' : ''}>
                    <Switch>
                        {
                            populateMain
                        }
                    </Switch>
                </main>
                <Footer utilityPageState={isUtilityPage}/>
                <ToastContainer />
            </AuthContext>
        </BrowserRouter>
    );
}

function onLoadValidatePage(
    setUtilityPage: React.Dispatch<React.SetStateAction<boolean>>,
    setCurrentPage: React.Dispatch<React.SetStateAction<{ path: string; isPublic: boolean }>>,
    isUtility: boolean, isPublic: boolean, currentLocation: string) {

    setUtilityPage(isUtility);
    setCurrentPage({path: currentLocation, isPublic: isPublic});
}
import { LibraryAuthenticateResponse } from '@web-eid/web-eid-library/models/message/LibraryResponse';
import axios, {AxiosResponse} from 'axios';
import { isMobileDevice, redirectToLogin } from 'helpers';

const baseUrl = process.env.REACT_APP_API_URL;

export const POLL_MAX_RETRY_COUNT_ON_FAILED_REQUEST = 3;

async function loginWithFakeAuth(code: string, password: string): Promise<string> {
    return axios.post(
        `${baseUrl}/v1/auth/fake/login`, {
            code,
            password
        },
        {
            withCredentials: true,
            headers: {
                "content-type": "application/json",
                "x-with-app": isMobileDevice()
            },
        });
}


async function loginWithSmartId(code: string): Promise<string> {
    return new Promise((resolve, reject) => {
        postSmartIdLogin(code).then(
            response => {
                resolve(response.data.data)
            }
        ).catch(error => {
            if (error.response) {
                reject(error.response.status)
            } else {
                reject(500)
            }
        });
    });
}

async function loginWithMobileId(code: string, phoneNumber: string): Promise<string> {
    return new Promise((resolve, reject) => {
        postMobileIdLogin(code, phoneNumber).then(
            response => {
                resolve(response.data.data)
            }
        ).catch(error => {
            if (error.response) {
                reject(error.response.status)
            } else {
                reject(500)
            }
        });
    });
}

async function pollSmartOrMobileIdLogin(personalIdentityCode: string, verificationCode: string, type: 'smart-id' | 'mobile-id'): Promise<string> {
    return new Promise((resolve, reject) => {
        postSmartOrMobileIdLoginPoll(personalIdentityCode, verificationCode, type).then(
            response => {
                resolve(response.data.data)
            }
        ).catch(error => {
            if (error.response) {
                reject(error.response.status)
            } else {
                reject(500)
            }
        });
    });
}

function postSmartIdLogin(personalIdentityCode: string): Promise<AxiosResponse> {
    return axios.post(
        `${baseUrl}/v1/auth/smart-id/login`, {
            personalIdentityCode
        },
        {
            headers: {
                "content-type": "application/json",
                "x-with-app": isMobileDevice()
            },
        });
}

function postMobileIdLogin(personalIdentityCode: string, phoneNumber: string): Promise<AxiosResponse> {
    return axios.post(
        `${baseUrl}/v1/auth/mobile-id/login`, {
            personalIdentityCode,
            phoneNumber
        },
        {
            headers: {
                "content-type": "application/json",
                "x-with-app": isMobileDevice()
            },
        });
}

function postSmartOrMobileIdLoginPoll(personalIdentityCode: string, verificationCode: string, type: 'smart-id' | 'mobile-id'): Promise<AxiosResponse> {
    return axios.post(
        `${baseUrl}/v1/auth/${type}/poll`, {
            "personalIdentityCode": personalIdentityCode,
            "verificationCode": verificationCode
        },
        {
            withCredentials: true,
            headers: {
                "content-type": "application/json"
            },
        });
}

async function getIdCardChallenge(): Promise<string> {
    return new Promise((resolve, reject) => {
        axios.get(`${baseUrl}/v1/auth/id-card/challenge`, {
            withCredentials: true
        }).then(
            response => {
                resolve(response.data.data)
            }
        ).catch(error => {
            if (error.response) {
                reject(error.response.status)
            } else {
                reject(500)
            }
        });
    });
}

async function postIdCardLogin(token : LibraryAuthenticateResponse) {
    return axios.post(
        `${baseUrl}/v1/auth/id-card`,
        {
            token
        },
        {
          withCredentials: true,
          headers: {
            'content-type': 'application/json'
          },
        }
    );
}

function refresh(){
    return axios.post(
        `${baseUrl}/v1/admin/auth/refresh`,
        {},
        {
          withCredentials: true,
          headers: {
            "content-type": "application/json",
          },
        }
      );
}

async function logoutAxios(){
    return axios.post(
        `${baseUrl}/v1/auth/logout`,
        {},
        {
          withCredentials: true,
        }
      );
}

function logout() {
    logoutAxios()
        .then(() => {
            localStorage.clear();

            if (window.location.href.indexOf('login') === -1) {
                redirectToLogin()
            }
        });
}

export const AuthenticationService = {
    getIdCardChallenge,
    postIdCardLogin,
    loginWithSmartId,
    loginWithMobileId,
    loginWithFakeAuth,
    pollSmartOrMobileIdLogin,
    logout,
    refresh
};
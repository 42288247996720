import React, {PureComponent} from 'react';

import {CustomerContactPersonsSearchBar} from '../components/CustomerContactPersons/CustomerContactPersonsSearchBar';
import {IPageResponse, SortDirection} from "../common/interfaces/IPageResponse";
import CustomerContactPersonsService from "../services/customer-contact-persons.service";
import {
    ICustomerContactPerson,
    ICustomerContactPersonSortFields
} from "../components/CustomerContactPersons/models";
import {CustomerContactPersonsTable} from "../components/CustomerContactPersons/CustomerContactPersonsTable";
import { IPageRequest } from 'common/interfaces/IPageRequest';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { UsersService } from 'components/Users/Users.service';
import { EditCustomerContactModal } from 'components/CustomerContactPersons/modals/EditCustomerContactModal';


export interface ICustomerContactPersonsState {
    customerContacts: IPageResponse<ICustomerContactPerson> | null;
    pageRequest: IPageRequest<ICustomerContactPersonSortFields>;
    selectedRows: ICustomerContactPerson[];
    isRequesting: boolean;
    showSyncModal: boolean;
    syncActive: boolean;
    syncResult: string[];
    isEditCustomerModalVisible: boolean;
    selectedContact?: ICustomerContactPerson 
}

const searchApi = AwesomeDebouncePromise(
    request => CustomerContactPersonsService.getCustomerContactPersons(request),
    300
);

class CustomerContactPersons extends PureComponent<any, ICustomerContactPersonsState> {
    constructor(props: any) {
        super(props);
        this.state = {
            customerContacts: null,
            isRequesting: false,
            pageRequest: {
                page: 0,
                size: 20,
                sortDirection: SortDirection.ASC,
                sortField: ICustomerContactPersonSortFields.USER_LAST_LOGIN,
                searchQuery: null
            },
            selectedRows: [],
            showSyncModal: false,
            syncActive: false,
            syncResult: [],
            isEditCustomerModalVisible: false
        };
        this.props.onLoadValidatePage();
    }

    getCustomerContactPersons = () => {
        this.setState({
            isRequesting: true
        });
        CustomerContactPersonsService.getCustomerContactPersons(this.state.pageRequest).then((response: IPageResponse<ICustomerContactPerson>) => {
            this.setState({
                customerContacts: response,
                isRequesting: false
            });
        });
    };
    searchCustomerContactPersons = (request: any) => {
        this.setState({
            isRequesting: true
        });
        searchApi(request).then((response: IPageResponse<ICustomerContactPerson>) => {
            this.setState({
                customerContacts: response, isRequesting: false
            });
        })
        
    };

    onPageChange = (targetPage: number) => {
        if (this.state.pageRequest.page + 1 !== targetPage) {
            this.setState({
                selectedRows: [],
                pageRequest: {
                    ...this.state.pageRequest,
                    page: targetPage - 1
                }
            }, () => this.getCustomerContactPersons());
        }
    };

    onRowSelect = (contact: ICustomerContactPerson, state: boolean) => {
        if (state === false) {
            const filtered = this.state.selectedRows.filter(cp => cp.id !== contact.id);
            this.setState({
                ...this.state,
                selectedRows: [...filtered]
            });
        } else {
            this.setState({
                ...this.state,
                selectedRows: [...this.state.selectedRows, contact]
            })
        }
    };

    handleSearchChange = (change: any) => {
        this.setState({
            customerContacts: null,
            pageRequest: {
                page: 0,
                size: this.state.pageRequest.size,
                sortDirection: this.state.pageRequest.sortDirection,
                sortField: this.state.pageRequest.sortField,
                searchQuery: change.searchQuery
            },
            selectedRows: []
        });
        this.searchCustomerContactPersons({
                page: 0,
                size: this.state.pageRequest.size,
                sortDirection: this.state.pageRequest.sortDirection,
                sortField: this.state.pageRequest.sortField,
                searchQuery: change.searchQuery
        });
    };

    componentDidMount(): void {
        this.getCustomerContactPersons();
    }

    handleCustomerEdit = (contact : ICustomerContactPerson) => {
        this.setState({
            isEditCustomerModalVisible: true,
            selectedContact: contact
        })
    }

    handleSyncUserData = (userIds: number[]): void => {
        if (!userIds.length) return;

        Promise.all(userIds.map(userId => UsersService.syncUserData(userId)))
            .then(() =>
                this.setState({
                    syncActive: false,
                    syncResult: ['Andmete uuendamine õnnestus']
                })
            )
            .catch((reason: any) => 
                this.setState({
                    syncActive: false,
                    syncResult: ['Andmete uuendamisel tekkis viga:', String(reason)]
                })
            );
        
        this.setState({ showSyncModal: true, syncActive: true });
    }

    hideSyncModal = (): void => {
        if (this.state.syncActive) return;

        this.setState({ showSyncModal: false });
    }

    render() {
        const selectedContacts = this.state.customerContacts ?
            this.state.customerContacts.content.filter(contact => this.state.selectedRows.some(c => c.id === contact.id))
    : [];
        return (
            <div className="container">
                <div className="row">
                    <div className="col">
                        <CustomerContactPersonsSearchBar 
                            onFilter={this.handleSearchChange}
                            selectedRows={this.state.selectedRows}
                            selectedCustomerContactPersons={selectedContacts}
                            getCustomerContactPersons={this.getCustomerContactPersons}
                            onCustomerSync={this.handleSyncUserData}
                        />
                        <CustomerContactPersonsTable
                            customerContacts={this.state.customerContacts}
                            isRequesting={this.state.isRequesting}
                            selectedRows={this.state.selectedRows}
                            onPageChange={this.onPageChange}
                            onRowSelect={this.onRowSelect}
                            onCustomerSync={this.handleSyncUserData}
                            onCustomerEdit={this.handleCustomerEdit}
                        />
                    </div>
                </div>
                <Modal size="sm" show={this.state.showSyncModal} onHide={this.hideSyncModal}>
                    <Modal.Header>
                        <Modal.Title>
                            Kasutaja andmete uuendamine
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-flex justify-content-center">
                            {
                                this.state.syncActive 
                                    ? <Spinner animation="grow" />
                                    : 
                                        <div>
                                            <h6 className="m-2 text-center">{this.state.syncResult[0]}</h6>
                                            <p>{this.state.syncResult[1]}</p>
                                        </div>
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="success" 
                            onClick={this.hideSyncModal}
                            type="button"
                            disabled={this.state.syncActive}
                        >
                            <span className='px-3'>OK</span>
                        </Button>
                    </Modal.Footer>
                </Modal>           
                {
                    (this.state.isEditCustomerModalVisible && this.state.selectedContact) && (
                        <EditCustomerContactModal 
                            rejectCloseHandler={() => {
                                this.setState({
                                    isEditCustomerModalVisible: false,
                                    selectedContact: undefined
                                })
                            }}
                            submitCloseHandler={() => {
                                this.setState({
                                    isEditCustomerModalVisible: false,
                                    selectedContact: undefined
                                })
                                this.getCustomerContactPersons()

                            }}
                            contactPerson={this.state.selectedContact}
                        />
                    )
                }     
            </div>
        );
    }
}

export default CustomerContactPersons;
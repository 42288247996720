import { ModalProps } from 'common/common-models'
import { dateFormatOptions } from 'common/dateFormatterConfig'
import { IAgreement } from 'models/agreements'
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import AgreementsService from 'services/agreements.service'


interface AgreementSignaturesModalProps extends ModalProps {
  agreement: IAgreement | null;
}

export default function AgreementSignaturesModal(props: AgreementSignaturesModalProps) {

  const [signatures, setSignatures] = useState<any[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (props.agreement) {
      setLoading(true);
      AgreementsService.getAgreementSignatures(props.agreement.id).then(res => {
        setLoading(false);
        setSignatures(res.signatures);
      });
    }
  }, [props.agreement]);


  return (
      <Modal
        show={true}
        size={"lg"}
        onHide={props.handleModalHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Allkirjad
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            isLoading &&
            <div className="text-center"><Spinner animation={"grow"}/></div>
          }
          { signatures.map((s, i) => (
            <div className="row" key={i}>
              <div className="col-12">
                {s.signer} - {new Intl.DateTimeFormat('et', dateFormatOptions).format(new Date(s.dateSigned))}
              </div>
            </div>
          ))
          }
        </Modal.Body>
      </Modal>    
  )

}
import { dateFormatOptions } from "common/dateFormatterConfig";
import { IElectricityAgreement, MeteringPoint } from "components/ElectricityAgreements/models";
import { roundNumber } from "helpers";
import React, { Fragment, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

interface CustomersAgreementsTableProps {
  agreements: IElectricityAgreement[];
  handleEdit : (agreement: IElectricityAgreement) => void 
}

export function CustomersAgreementsTable({agreements, handleEdit } : CustomersAgreementsTableProps){
  const [activeAgreementId, setActiveAgreementId] = useState<number | undefined>();

  const tableRows = agreements.map((agreement: IElectricityAgreement) => {
    const {id, contractNumber, startDate, endDate, consumptionPackage, consumptionMarginal, productionPackage, productionMarginal, meteringPoints} = agreement;

    return (
        <Fragment key={id}>
          <tr className="cursor-pointer-hover" onClick={() => {
            if(activeAgreementId === id){
              setActiveAgreementId(undefined)
            }else{
              setActiveAgreementId(id)
            }
          }}>
            <td>{contractNumber}</td>
            <td>{startDate}</td>
            <td>
              {
                endDate ? new Intl.DateTimeFormat('et', dateFormatOptions).format(new Date(endDate)) : 'Tähtajatu'
              }
            </td>
            <td>
                <div className="line-height-1">{ !!productionMarginal && 'Tarbimine:'} { roundNumber(consumptionMarginal, 4) }</div>
                { !!productionMarginal && <div className="line-height-1">Tootmine: { roundNumber(productionMarginal, 4)}</div>}
            </td>
            <td>
              {consumptionPackage.nameEt}{!!productionPackage ? '/ost' : ''}
            </td>
            <td>
              <OverlayTrigger
                delay={250}
                overlay={
                    <Tooltip id={`edit-electricity-agreemnent-tooltip-${id}`}>
                        <p style={{margin: '3px'}}>Muuda lepingut</p>
                    </Tooltip>
                } 
                placement={"left"}
              >
                <Button 
                    size="sm"                             
                    variant="secondary"
                    className="d-flex align-items-center btn-outline-icon mr-2" 
                    onClick={() => handleEdit(agreement)}
                    >
                        <i className="icon--16 my-2 icon__edit--outline-mask"/>
                </Button>
              </OverlayTrigger>
            </td>
          </tr>
          {activeAgreementId === id && (
            <RowSubMenu meteringPoints={meteringPoints} />
          )}
        </Fragment>
    )
  })

  if(!agreements.length){
    return <h2 className="text-center w-full">Aktiivsed lepingud puuduvad...</h2>
  }

  return (
    <table className='generic-table table table-responsive-md table-hover'>
      <thead>
        <tr>
          <th>Lepingu number</th>
          <th>Alguskuupäev</th>
          <th>Lõppkuupäev</th>
          <th className="text-left">Marginaal s/kWh</th>
          <th className="text-left">Pakett</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {tableRows}
      </tbody>
    </table>
  )
}

function RowSubMenu({meteringPoints} : {meteringPoints : MeteringPoint[]}){
  return (
    <tr className="sub-menu">
        <td colSpan={5}>
          <div className="sub-menu__container">
            {meteringPoints.map(mp => (
              <div key={mp.id} className="sub-menu__metering-point d-flex">
                <div className="font-weight-bold">{mp.eic}</div>
                <div>{mp.address}</div>
                <div>{mp.productionCapacity}</div>
              </div>
            ))}
          </div>
        </td>
    </tr>
  )
}
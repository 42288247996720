import {IPageResponse} from "../../common/interfaces/IPageResponse";
import {ModalProps, ModalType} from "../../common/common-models";
import {IPageRequest} from "../../common/interfaces/IPageRequest";

export interface ITermsAndConditionsState {
    termsAndConditions: IPageResponse<ITermsAndCondition> | null;
    pageRequest: IPageRequest<ITermsAndConditionsSortFields>;
    isEditModalVisible: boolean;
    modalType: ModalType;
    termsAndCondition?: ITermsAndCondition;
}

export interface ITermsAndCondition {
    id: number;
    name: string;
    confirmationText: string;
    latestVersion: ITermsAndConditionsVersion;
}

export interface ITermsAndConditionsVersion {
    id: number;
    conditionsId: number;
    privateClientLink: string;
    businessClientLink: string;
    version: number;
    agreementWithdrawalConditions: string;
    dateModified: string | null;
    privateClientLinkUpdateSummary?: string;
    businessClientLinkUpdateSummary?: string;
}

export interface ITermsAndConditionsTableProps {
    termsAndConditions: IPageResponse<ITermsAndCondition>;
    openTermsAndConditionsModal: (modalType: ModalType, termsAndConditions?: ITermsAndCondition) => void;
}

export enum ITermsAndConditionsSortFields {
    ID = "ID",
    DATE_MODIFIED = "DATE_MODIFIED"
}

export interface ITermsAndConditionsSearchBarProps {
    openTermsAndConditionsModal: (modalType: ModalType, termsAndCondition?: ITermsAndCondition) => void;    
}

export interface ITermsAndConditionsConfirmModalProps extends ModalProps {
    editingTermsAndConditions?: ITermsAndCondition;
    loadTermsAndConditions: () => void;
}

export interface ITermsAndConditionsForm {
    name: string;
    confirmationText: string;
    latestVersion: {
        id?: number;
        privateClientLink?: string;
        businessClientLink?: string;
        version?: number;
        agreementWithdrawalConditions?: string;
        privateClientLinkUpdateSummary?: string;
        businessClientLinkUpdateSummary?: string;
    };
}

export interface Terms {
    id: number;
    name: string;
}

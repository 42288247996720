import React, {PureComponent} from "react";
import {IPageResponse, SortDirection} from "../common/interfaces/IPageResponse";
import {
    ITermsAndCondition,
    ITermsAndConditionsSortFields,
    ITermsAndConditionsState
} from "../components/TermsAndConditions/models";
import { EditTermsAndConditionsModal } from "components/TermsAndConditions/modals/EditTermsAndConditionsModal";
import {ModalType} from "../common/common-models";
import {TermsAndConditionsService} from "../components/TermsAndConditions/TermsAndConditions.service";
import { TermsAndConditionsTable } from "components/TermsAndConditions/TermsAndConditionsTable";
import { TermsAndConditionsSearchBar } from "components/TermsAndConditions/TermsAndConditionsSearchBar";

export default class TermsAndConditions extends PureComponent<any,ITermsAndConditionsState> {
    constructor(props: any) {
        super(props);
        this.state = {
            termsAndConditions: null,
            pageRequest: {
                page: 0,
                size: 20,
                sortDirection: SortDirection.ASC,
                sortField: ITermsAndConditionsSortFields.ID,
                searchQuery: null
            },
            modalType: ModalType.CREATE,
            isEditModalVisible: false
        };
        this.props.onLoadValidatePage();
    }

    getTermsAndConditions = () => {
        TermsAndConditionsService.getTermsAndConditions(this.state.pageRequest).then((response: IPageResponse<ITermsAndCondition>) => {
            this.setState({
                termsAndConditions: response
            })
        });
    };

    onPageChange = (targetPage: number) => {
        if (this.state.pageRequest.page + 1 !== targetPage) {
            this.setState({
                pageRequest: {
                    ...this.state.pageRequest,
                    page: targetPage - 1
                }
            }, () => this.getTermsAndConditions());
        }
    };

    componentDidMount(): void {
        this.getTermsAndConditions();
    }


    openTermsAndConditionsModal = (modalType: ModalType, termsAndConditions?: ITermsAndCondition) => {
        this.setState({
            isEditModalVisible: true,
            modalType: modalType,
            termsAndCondition: termsAndConditions
        })
    };

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col">
                    <TermsAndConditionsSearchBar openTermsAndConditionsModal={this.openTermsAndConditionsModal}/>
                            {this.state.termsAndConditions !== null ?
                            <TermsAndConditionsTable
                                termsAndConditions={this.state.termsAndConditions}
                                openTermsAndConditionsModal={this.openTermsAndConditionsModal}
                            /> :
                            null
                        }
                    </div>
                </div>
                {
                    this.state.isEditModalVisible &&
                    <EditTermsAndConditionsModal
                        handleModalHide={() => {this.setState({isEditModalVisible: false})}}
                        modalType={this.state.modalType}
                        editingTermsAndConditions={this.state.termsAndCondition}
                        loadTermsAndConditions={this.getTermsAndConditions}
                    />
                }
            </div>
        );
    }
}
/* eslint-disable jsx-a11y/alt-text */
import React from "react";

interface IconProps {
  src: string;
  file?: never;
}

interface FileProps {
  file: File;
  src?: never;
}

type FilePreviewProps = (IconProps | FileProps) & {
  className?: string
}

export function FilePreview({file, src, className} : FilePreviewProps){

  let fileContainer;

  if(file){
    fileContainer = <img src={URL.createObjectURL(file)} />
  }else{
    fileContainer = <img src={src} />
  }

 return (
    <div className={`file-preview ${className}`}>
        {fileContainer}
    </div>
  )
}
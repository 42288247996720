import { IPageRequest } from "common/interfaces/IPageRequest";
import { IPageResponse, SortDirection } from "common/interfaces/IPageResponse";
import { UploadedFile } from "components/FileUpload/FileUploadFormGroup";

export interface LoyaltyCampaignsState {
  campaigns: IPageResponse<LoyaltyCampaign> | null;
  pageRequest: IPageRequest<LoyaltyCampaignSortFields>;
  filters: LoyaltyCampaignFilters;
  isRequesting: boolean;
  isModalVisible: boolean;
  activeCampaign?: LoyaltyCampaign;
  isSyncing: boolean;
}

export enum LoyaltyCampaignSortFields {
  ID = "ID",
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE',
  DATE_CREATED = 'DATE_CREATED',
  NAME = 'NAME',
  CALCULATION_TYPE = 'CALCULATION_TYPE',
  TYPE = 'TYPE',
  ACTIVE = 'ACTIVE',
  TARGET_POINTS = 'TARGET_POINTS'
}

export interface LoyaltyCampaignFilters {
  searchQuery?: string;
  calculationType?: LoyaltyCampaignCalculationType;
  campaignType?: LoyaltyCampaignType;
  active?: boolean;
}

export enum LoyaltyCampaignIcon {
  BURGER = "burger",
  COFFEE = "coffee",
  HOT_DOG = "hot-dog",
  STAR = "star"
}

export interface LoyaltyCampaign {
  id: number;
  name: string;
  titleEt?: string;
  titleEn?: string; 
  targetPoints: number;
  startDate: string;
  endDate: string;
  calculationType?: LoyaltyCampaignCalculationType
  type?: LoyaltyCampaignType;
  active: boolean;
  icon?: LoyaltyCampaignIcon;
  fileNameEt?: string;
  originalFileNameEt?: string;
  fileNameEn?: string;
  originalFileNameEn?: string;
  allowedGroups?: string[];
  participatable: boolean;
}

export enum LoyaltyCampaignCalculationType {
  QUANTITY = "QUANTITY",
  AMOUNT = "AMOUNT"
}

export enum LoyaltyCampaignType {
  FOOD = "FOOD",
  COFFEE = "COFFEE",
  MONEY = "MONEY",
  BONUS_MONEY = "BONUS_MONEY",
  UNCATEGORIZED = "UNCATEGORIZED"
}

export const LOYALTY_CAMPAIGN_CALCULATION_TYPE_MAP: Record<LoyaltyCampaignCalculationType, string> = {
  QUANTITY: 'Kogus',
  AMOUNT: 'Summa'
};

export const LOYALTY_CAMPAIGN_TYPE_MAP: Record<LoyaltyCampaignType, string> = {
  FOOD: 'Toit',
  COFFEE: 'Kohvi',
  MONEY: "Raha",
  BONUS_MONEY: "Boonusraha",
  UNCATEGORIZED: "Kategooriata"
};

export interface LoyaltyCampaignsTableProps {
  campaigns: IPageResponse<LoyaltyCampaign>;
  onPageChange: (page: number, size: number) => void;
  onSort: (sortField: LoyaltyCampaignSortFields) => void;
  sortField: LoyaltyCampaignSortFields;
  sortDirection: SortDirection;
  updateLoyaltyCampaign: (campaign: LoyaltyCampaign) => void;
}

export interface LoyaltyCampaignsSearchBarProps {
  filters: LoyaltyCampaignFilters;
  onFilter: (filter: LoyaltyCampaignFilters) => void;
  isSyncing: boolean;
  syncCampaigns: () => void;
}

export interface UpdateLoyaltyCampaignModalForm {
  validated: boolean;
  campaign: {
    id: number;
    type?: LoyaltyCampaignType;
    active: boolean;
    titleEt?: string;
    titleEn?: string;
    participatable: boolean
  },
  icon?: string;
  imageEt?: UploadedFile,
  imageEn?: UploadedFile
}
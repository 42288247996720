export interface SynchronizationData {
  id: number;
  version: number;
  startedAt: string;
  finishedAt: string;
  status: SynchronizationStatus;
  type: SynchronizationType;
}

export interface SynchronizationErrorData {
  property: string;
  message: string;
  timestamp: string;
}

export enum SynchronizationStatus {
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
  FAILED = "FAILED"
}

export enum SynchronizationType {
  INVOICES = "INVOICES",
  PRIVATE_INVOICES = "PRIVATE_INVOICES",
  BUSINESS_INVOICES = "BUSINESS_INVOICES"
}
import React from 'react';
import Pagination from "rc-pagination";
import {dateFormatOptions} from "../../common/dateFormatterConfig";
import {locale} from "../../common/table/paginatorConfig";
import {IPetrolStation, IPetrolStationTableProps} from "./models";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {ModalType} from "../../common/common-models";

export function PetrolStationsTable(props: IPetrolStationTableProps) {
    const petrolStations: Array<IPetrolStation> = props.petrolStations.content;
    const pageResponse = props.petrolStations;

    const petrolStationTableRows = petrolStations.map((petrolStation: IPetrolStation) =>
        <tr key={'row-' + petrolStation.id}>
            <td>
                {petrolStation.id}
            </td>
            <td>
                {petrolStation.nameEt}
            </td>
            <td>
                {petrolStation.latitude !== null && <span>{petrolStation.latitude}</span>}
                {petrolStation.latitude !== null && petrolStation.longitude !== null && <span> , </span>}
                {petrolStation.longitude !== null && <span>{petrolStation.longitude}</span>}
            </td>
            <td>
                {petrolStation.externalId !== null && <span>{petrolStation.externalId}</span>}
            </td>
            <td>
                {petrolStation.dateModified ? new Intl.DateTimeFormat('et', dateFormatOptions).format(new Date(petrolStation.dateModified)) : ""}
            </td>
            <td>
                <div className="row__actions">
                    <OverlayTrigger delay={250}
                                    overlay={
                                        <Tooltip id={`tooltip-${petrolStation.id}`}>
                                            <p style={{margin: '3px'}}>Muuda</p>
                                        </Tooltip>} placement={"left"}>
                        <Button size="sm" variant="outline-dark"
                                className="d-flex align-items-center border-color-background border-radius-circle btn-outline-icon"
                                onClick={(e: any) => {
                                    props.openPetrolStationModal(ModalType.EDIT, petrolStation);
                                }}>
                            <i className="icon--16 my-2 icon__edit--outline-mask"/>
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger delay={250}
                                    overlay={
                                        <Tooltip id={`tooltip-${petrolStation.id}`}>
                                            <p style={{margin: '3px'}}>Kustuta</p>
                                        </Tooltip>} placement={"left"}>
                        <Button size="sm" variant="outline-danger"
                                className="d-flex align-items-center border-color-background border-radius-circle btn-outline-icon"
                                onClick={(e: any) => {
                                    props.openDeletePetrolStationModal(petrolStation);
                                }}>
                            <i className="icon--16 my-2 icon__delete--outline-mask"/>
                        </Button>
                    </OverlayTrigger>
                </div>
            </td>
        </tr>
    );

    return (
        <table className='generic-table table table-responsive-md table-hover'>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Nimi</th>
                    <th>Asukoht</th>
                    <th>Noom ID</th>
                    <th>Viimati muudetud</th>
                    <th>Tegevused</th>
                </tr>
            </thead>
            <tbody>
                {petrolStationTableRows}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan={5}>
                        <div className="pagination-wrapper">
                            <Pagination
                                locale={locale}
                                current={pageResponse.number + 1}
                                total={pageResponse.totalElements}
                                pageSize={pageResponse.size}
                                prefixCls={"pagination"}
                                onChange={props.onPageChange}
                                showSizeChanger={false}
                                hideOnSinglePage={true}
                            />
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>
    )
}
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

interface ElectricityCustomersSearchBarProps {
  onFilter: (searchQuery: string) => void;
  createInvoices: (isPerson: boolean) => void;
}

export function ElectricityCustomersSearchBar(props: ElectricityCustomersSearchBarProps){
    const [searchQuery, setSearchQuery] = useState("");

    const onInputChange = (inputEvent: any) => {
        setSearchQuery(inputEvent.target.value);
        props.onFilter(inputEvent.target.value);
    };

    return (
        <div className="bar-position">
            <h2 className='text-light'>
                Elektrikliendid
            </h2>
            <div className="card mb-5 shadow">
                <div className="card-body">
                    <div className='d-flex flex-wrap flex-md-nowrap justify-content-between'>
                        <input
                            placeholder="EIC / Kood / Nimi"
                            className='search-bar__input p-2'
                            onChange={onInputChange}
                            value={searchQuery ?? ""}
                        />         
                        <div className='d-flex flex-row'>
                            <Button className="d-flex align-items-center"
                                onClick={() => props.createInvoices(true)}
                                variant={"primary"}
                            >
                                <span>Eraklientide arvete koostamine</span>
                            </Button>     
                            <Button className="ml-3 d-flex align-items-center"
                                onClick={() => props.createInvoices(false)}
                                variant={"primary"}
                            >
                                <span>Äriklientide arvete koostamine</span>
                            </Button>  
                        </div>           
                    </div>
                </div>
            </div>
        </div>
    )
}
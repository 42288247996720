import {IPageRequest} from "../common/interfaces/IPageRequest";

export interface IAgreement {
    id: number;
    customerId: number;
    customerName: number;
    remoteCustomerId: number;
    remoteAgreementId: number;
    fileName: string;
    contractNumber: string;
    type: IAgreementType;
    dateModified: string | null;
    isSignedByVendor: boolean;
    isSignedByCustomer: boolean;
    isExpired: boolean;
}


export enum IAgreementsSortFields {
    ID = "ID",
    CONTRACT_NUMBER = "CONTRACT_NUMBER",
    TYPE = "TYPE",
    DATE_MODIFIED = "DATE_MODIFIED",

    // ADMIN ONLY
    CUSTOMER_ID = "CUSTOMER_ID",
    CUSTOMER_NAME = "CUSTOMER_NAME"
}

export interface IAgreementRequest extends IPageRequest<IAgreementsSortFields> {
  statuses?: string | string[];
}

export enum IAgreementType {
    PURCHASE_AND_SALE = "PURCHASE_AND_SALE",
    ADDITIONAL_ADDENDUM = "ADDITIONAL_ADDENDUM",
    SURETY = "SURETY",
    CREDIT_CARD = "CREDIT_CARD",
    PRIVATE_CLIENT_CREDIT = "PRIVATE_CLIENT_CREDIT",
    CREDIT_CARD_ADDENDUM = "CREDIT_CARD_ADDENDUM"
}

export enum AgreementFilterType {
    STATUS = 'STATUS',
    SEARCH = 'SEARCH'
}

export enum AgreementSigningStatus {
    PREPARED = 'PREPARED',
    PENDING_CUSTOMER = 'PENDING_CUSTOMER',
    PENDING_VENDOR = 'PENDING_VENDOR',
    COMPLETED = 'COMPLETED',
    EXPIRED = 'EXPIRED'
}

export const AGREEMENT_TYPE_FILTER_OPTIONS = [   
    {
        label: 'Allkirjad puuduvad',
        value: AgreementSigningStatus.PREPARED,
    },
    {
        label: 'Kliendi poolt allkirjastatud',
        value: AgreementSigningStatus.PENDING_VENDOR,
    }, 
    {
        label: 'Kliendi allkirja ootel',
        value: AgreementSigningStatus.PENDING_CUSTOMER,
    },
    {
        label: 'Allkirjastatud',
        value: AgreementSigningStatus.COMPLETED,
    },
    {
        label: 'Aegunud',
        value: AgreementSigningStatus.EXPIRED,
    }
];

import axios from "axios";
import { extractData, handleResponse } from "helpers/apiResponseHelper";
import { apiService } from "services/api.service";

async function findInvoiceGroupsForCustomer(customerId: number) {
  return axios.get(apiService.getApiUrl() + `/electricity/invoice-groups/admin/customer/${customerId}`)
    .then(handleResponse).then(extractData);
}

interface CreateInvoiceGroupRequest {
  name?: string;
  referenceNumber?: string;
  invoiceRecipientEmail?: string;
  customerId: number;
  meteringPointIds: number[];
  paidFromCustomersAdvance: boolean;
}

async function createInvoiceGroupForCustomer(request: CreateInvoiceGroupRequest) {
  return axios.post(apiService.getApiUrl() + '/electricity/invoice-groups/admin', request)
    .then(handleResponse).then(extractData);
}

interface UpdateInvoiceGroupRequest {
  name?: string;
  referenceNumber?: string;
  invoiceRecipientEmail?: string;
  meteringPointIds: number[];
  paidFromCustomersAdvance: boolean;
}

async function updateInvoiceGroup(invoiceGroupId: number, request: UpdateInvoiceGroupRequest) {
  return axios.put(apiService.getApiUrl() + `/electricity/invoice-groups/admin/${invoiceGroupId}`, request)
    .then(handleResponse).then(extractData);
}

async function deleteInvoiceGroup(invoiceGroupId: number) {
  return axios.delete(apiService.getApiUrl() + `/electricity/invoice-groups/admin/${invoiceGroupId}`)
    .then(handleResponse);
}

export const ElectricityInvoiceGroupsService = {
  findInvoiceGroupsForCustomer,
  createInvoiceGroupForCustomer,
  updateInvoiceGroup,
  deleteInvoiceGroup
};
import axios from 'axios';
import {apiService} from "./api.service";
import {convertToPageable, extractData, handleApiException, handleResponse} from "../helpers/apiResponseHelper";
import { IPageResponse } from 'common/interfaces/IPageResponse';
import { ICustomer, ISimplifiedCustomer } from 'components/ElectrcityCustomers/model';

async function getCustomers(search: string) : Promise<ISimplifiedCustomer[]> {
  return axios.get(apiService.getApiUrl() + '/customers/admin', {
      params: { search }
  }).then(handleResponse).then(extractData).catch(handleApiException);
}

async function getCustomersWithActiveAgreements(pageRequest: any) : Promise<IPageResponse<ICustomer>>{
  return axios.get(apiService.getApiUrl() + '/customers/admin/active-agreements', {
      params: pageRequest
  }).then(handleResponse).then(convertToPageable).catch(handleApiException);
}

interface UpdateCustomerRequest {
  hasGroupInvoicingEnabled: boolean;
  isFactoringClient: boolean;
  invoiceRecipientEmail?: string;
  bankAccountNumber?: string;
  receiveInvoiceByEmail: boolean;
}

async function updateCustomer(request: UpdateCustomerRequest, customerId : number) {
  return axios.put(apiService.getApiUrl() + `/customers/admin/${customerId}`,
  { 
    ...request
  }).then(handleResponse).then(extractData);
}

const CustomerService = {
  getCustomers,
  getCustomersWithActiveAgreements,
  updateCustomer
};

export default CustomerService;
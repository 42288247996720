/* eslint-disable react-hooks/exhaustive-deps */
import { ElectricityCustomerSettingsModalFormState, SettingsModalNavState } from "components/ElectrcityCustomers/modals/ElectricityCustomerSettingsModal";
import ElectricityContentBlock from "components/ElectricityAgreements/components/ElectricityAgreementContentBlock";
import { SimplifiedMeteringPoint } from "components/ElectricityAgreements/models";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import AsyncSelect from 'react-select/async';
import { OptionTypeBase } from "react-select/src/types";
import MeteringPointService from "services/electricity/electricity-metering-points.service";

interface InvoiceGroupFormProps {
  form: ElectricityCustomerSettingsModalFormState;
  setForm: Dispatch<SetStateAction<ElectricityCustomerSettingsModalFormState>>;
  navigate: (nextState: SettingsModalNavState) => void;
  customerId: number;
  invoiceRecipientIsInvalid: boolean;
}

export function InvoiceGroupForm({customerId, form, setForm, invoiceRecipientIsInvalid} : InvoiceGroupFormProps){
  const [selectedMeteringPoint, setSelectedMeteringPoint] = useState<SimplifiedMeteringPoint | null>()
  const [meteringPoints, setMeteringPoints] = useState<SimplifiedMeteringPoint[]>([]);

  useEffect(() => {
    findMeteringPoints();
  }, [])

  const findMeteringPoints = async () => {
    try{
        const response = await MeteringPointService.findMeteringPointsForCustomer(customerId, false);
        setMeteringPoints(response);
    }catch(e){
      console.error(e)
    }
  }
  
  const getFilteredMeteringPoints = (searchTerm: string, callback: Function) => {
    if(!meteringPoints.length){
      return callback([]);
    }

    let filteredMeteringPoints = meteringPoints.filter(mp => 
      !form.invoiceGroup?.meteringPoints?.some(meteringPoint => meteringPoint.id === mp.id)  
    );

    if (!searchTerm) {
      return filteredMeteringPoints;
    }

    filteredMeteringPoints = filteredMeteringPoints.filter(mp => 
      mp.eic.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
    );

    callback(
      filteredMeteringPoints.map(mp => ({ label: mp.eic, value: mp }))
    )
  }

  const addMeteringPoint = (meteringPoint: SimplifiedMeteringPoint) => {
    setForm({
      ...form,
      invoiceGroup: {
        ...form.invoiceGroup!,
        meteringPoints: [...(form?.invoiceGroup?.meteringPoints ?? []), meteringPoint]
      }
    });
    setSelectedMeteringPoint(null);
  }

  const removeMeteringPoint = (meteringPoint: SimplifiedMeteringPoint) => {
    setForm({
      ...form,
      invoiceGroup: {
        ...form.invoiceGroup!,
        meteringPoints: form.invoiceGroup?.meteringPoints?.filter(mp => mp.id !== meteringPoint.id)
      }
    })
    if(!meteringPoints.some(mp => mp.id === meteringPoint.id)){
      setMeteringPoints([...meteringPoints, meteringPoint])
    }
  }

  const currentOption = selectedMeteringPoint ? {label : selectedMeteringPoint.eic, value: selectedMeteringPoint} : null
  const initialOptions = meteringPoints.filter(mp => 
    !form.invoiceGroup?.meteringPoints?.some(meteringPoint => meteringPoint.id === mp.id)  
  )?.map(mp => ({ label: mp.eic, value: mp }))

  return (
    <Form noValidate validated={form.validated} className="invoice-group-form">
      <ElectricityContentBlock title="Arve grupp">
        <div className="col-12 col-md-6">
            <Form.Label>Nimi</Form.Label>
            <Form.Control
                value={form?.invoiceGroup?.name ?? ''}
                onChange={(e: any) => setForm({
                    ...form, 
                    invoiceGroup: {
                      ...form.invoiceGroup!,
                      name: e.target.value
                    }
                })}
                required={!!form.invoiceGroup?.id}
            />
        </div>
        <div className="col-12 col-md-6 mt-3">
            <Form.Label>Viitenumber</Form.Label>
            <Form.Control
                value={form?.invoiceGroup?.referenceNumber ?? ''}
                onChange={(e: any) => setForm({
                    ...form, 
                    invoiceGroup: {
                      ...form.invoiceGroup!,
                      referenceNumber: e.target.value
                    }
                })}
            />
        </div>
        <div className="col-12 col-md-6 mt-3">
            <Form.Label>Arve saaja e-post</Form.Label>
            <Form.Control
                value={form?.invoiceGroup?.invoiceRecipientEmail ?? ''}
                onChange={(e: any) => setForm({
                    ...form, 
                    invoiceGroup: {
                      ...form.invoiceGroup!,
                      invoiceRecipientEmail: e.target.value
                    }
                })}
                as="textarea"
                rows={1}
                isInvalid={invoiceRecipientIsInvalid}
            />
            <Form.Control.Feedback className={'text-right'} type="invalid">Vigane e-post!</Form.Control.Feedback>
        </div>
        <div className="col-12 col-md-6 mt-3">
            <Form.Check
              id='check-paid-from-customers-advance'
              custom
              type="checkbox"
              className='checkbox-large'
              label='Tasutakse kliendi ettemaksust'
              onChange={() => setForm({
                ...form, 
                invoiceGroup : {
                  ...form.invoiceGroup,
                  paidFromCustomersAdvance: !form.invoiceGroup?.paidFromCustomersAdvance
                }
              })}
              checked={form.invoiceGroup?.paidFromCustomersAdvance}
            />
          </div>
      </ElectricityContentBlock>
      <ElectricityContentBlock title="Mõõtepunktid">
          <div className="d-flex align-items-start">
            <div className={`col-12 col-md-6 ${(form.validated && !form.invoiceGroup?.meteringPoints?.length) && "invoice-group-form--invalid"}`}>
              <AsyncSelect 
                  loadOptions={getFilteredMeteringPoints}
                  defaultOptions={initialOptions}
                  noOptionsMessage={() => "Valikud puuduvad"}
                  isClearable
                  onChange={(e: any) => setSelectedMeteringPoint(e?.value ?? null)}
                  loadingMessage={() => "..."}
                  placeholder={"Otsi mõõtepunkte..."}
                  value={currentOption}
                  components={{Option: MeteringPointSelectOption}}
              /> 
              {form.validated && !form.invoiceGroup?.meteringPoints?.length && (
                <span className="float-right">Mõõtepunktid on kohustlikud!</span>
              )}
            </div>
            {!!selectedMeteringPoint && (
              <Button 
                variant="secondary"
                onClick={() => {
                  addMeteringPoint(selectedMeteringPoint);
                }}
                type="button"
                className={"d-flex align-items-center"}
              >
                  <span className='px-2'>Lisa</span>
              </Button>
            )}
          </div>
          <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
          <div className="col mt-3">
            {!form.invoiceGroup?.meteringPoints?.length ? (
              <h2>Mõõtepunktid puuduvad...</h2>
            ) : (
              <>
                <div className="row d-flex align-items-center">
                  <div className="col-3 text-gray-700">EIC</div>
                  <div className="col-6 text-gray-700">AADRESS</div>
                  <div className="col text-gray-700">TOOTMISVÕIMSUS</div>
                </div>
                {form.invoiceGroup?.meteringPoints?.map((meteringPoint) => {
                  const { id, eic, address, productionCapacity } = meteringPoint;
                  return (
                    <div className="invoice-group-form__metering-point row d-flex align-items-center my-2 py-2" key={id}>
                      <div className="col-3">{eic}</div>
                      <div className="col-6">{address}</div>
                      <div className="col">{productionCapacity}</div>
                      <OverlayTrigger
                        delay={250}
                        overlay={
                            <Tooltip id={`delete-invoice-group-metering-point-tooltip-${id}`}>
                                <p style={{margin: '3px'}}>Kustuta mõõtepunkt</p>
                            </Tooltip>
                        } 
                        placement={"left"}
                      >
                        <Button 
                            size="sm"                             
                            variant="secondary"
                            className="d-flex align-items-center btn-outline-icon mr-2" 
                            onClick={() => removeMeteringPoint(meteringPoint)}
                            >
                                <i className="icon--16 my-2 icon__delete--outline-mask"/>
                        </Button>
                      </OverlayTrigger>
                  </div>
                )
                })}
              </>
            )}
          </div>
      </ElectricityContentBlock>
    </Form>
  )
}

function MeteringPointSelectOption(props: OptionTypeBase){

  return (
    <div 
      className="invoice-group-form__metering-point-select"
      onClick={() => props.setValue(props.data)}
    >
      <div>{props.label}</div>
      {
        props.value.address ? (
          <div>{props.value.address}</div>
        ) : null
      }
    </div>
  )
}
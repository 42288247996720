import axios from 'axios';
import {apiService} from "./api.service";
import {convertToPageable, extractData, handleApiException, handleResponse} from "../helpers/apiResponseHelper";

const baseUrl = process.env.REACT_APP_API_URL;

async function getCustomerContactPersons(pageRequest: any) {
    return axios.get(apiService.getApiUrl() + '/customer-contact-persons/admin', {
        params: pageRequest
    }).then(handleResponse).then(convertToPageable).catch(handleApiException);
}

interface UpdateCustomerContactPersonRequest {
    email?: string;
    address?: string;
    phone?: string;
}

async function updateCustomerContactPerson(contactId: number, request: UpdateCustomerContactPersonRequest) {
    return axios.patch(`${apiService.getApiUrl()}/customer-contact-persons/admin/${contactId}`, {
        ...request,
        phone: request.phone?.replace(/^(\+)?372/g, '').trim()
    }, {}).then(handleResponse).then(extractData);
}

async function getReferralToken(customerId: number, userId: number): Promise<string> {
    return new Promise((resolve, reject) => {
        axios.post(
            baseUrl + "/v1/admin/auth/admin-as-user", {
                "customerId": customerId,
                "userId": userId
            }).then(
            response => {
                resolve(response.data.data)
            }
        ).catch(error => {
            if (error.response) {
                reject(error.response.status)
            } else {
                reject(500)
            }
        });
    });
}

const CustomerContactPersonsService = {
    getCustomerContactPersons,
    getReferralToken,
    updateCustomerContactPerson
};

export default CustomerContactPersonsService;

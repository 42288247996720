import React, {useState} from 'react';
import Pagination from "rc-pagination";
import {dateFormatWithTimeOptions} from "../../common/dateFormatterConfig";
import {locale} from "../../common/table/paginatorConfig";
import {ICustomerContactPerson} from "./models";
import {Button, OverlayTrigger, Tooltip, Spinner} from "react-bootstrap";
import CustomerContactPersonsService from "../../services/customer-contact-persons.service";

const privateClientAppUrl = process.env.REACT_APP_URL;
const businessClientAppUrl = process.env.REACT_APP_LEGACY_URL;

export function CustomerContactPersonsTable(props: any) {
    const customerContactPersons: Array<ICustomerContactPerson> = props.customerContacts ? props.customerContacts.content : [];
    const pageResponse = props.customerContacts;
    const [loggingInAsUser, setLoggingInAsUser] = useState<number | undefined>();

    const handleLogInAsEvent = (contactPerson : ICustomerContactPerson) => {
        if (!contactPerson.customerId || !contactPerson.userId)
            return;

        setLoggingInAsUser(contactPerson.id);
        const url = contactPerson.isPerson ? privateClientAppUrl : businessClientAppUrl;

        CustomerContactPersonsService.getReferralToken(contactPerson.customerId, contactPerson.userId)
            .then((response: any) => {
                window.open(url + '/redirect?token=' + response, '_blank');
                setLoggingInAsUser(undefined);
            }).catch(() => setLoggingInAsUser(undefined));
    };

    const contactsTableRows = customerContactPersons.map((contactPerson: ICustomerContactPerson) => {
            return <tr key={'row-' + contactPerson.id} className={props.selectedRows.some((c: ICustomerContactPerson) => c.id === contactPerson.id) ? 'selected-row' : ''}>
                <td className="text-center p-0">
                    <label htmlFor={`permissions-check-${contactPerson.id}`} className="m-0 px-3 cursor-pointer-hover">
                        <input id={`permissions-check-${contactPerson.id}`} className="cursor-pointer-hover"
                               checked={props.selectedRows.some((c: ICustomerContactPerson) => c.id === contactPerson.id)}
                               type="checkbox" onChange={(event => {
                                    props.onRowSelect(contactPerson, event.target.checked);
                                })}
                        />
                    </label>
                </td>
                <td>
                    {contactPerson.id}
                </td>
                <td>
                    {contactPerson.remoteCustomerId}
                </td>
                <td>
                    {contactPerson.personalIdentificationCode}
                </td>
                <td>
                    {contactPerson.contactName}
                </td>
                <td>
                    {contactPerson.customerName}
                </td>
                <td>
                    {contactPerson.userLastLogin ? new Intl.DateTimeFormat('et', dateFormatWithTimeOptions).format(new Date(contactPerson.userLastLogin)) : ""}
                </td>
                <td>
                    {contactPerson.webAccess ? 'Jah' : 'Ei'}
                </td>
                <td>
                    {contactPerson.webAdmin ? 'Jah' : 'Ei'}
                </td>
                <td>
                    <div className="row__actions">
                        <OverlayTrigger
                                delay={350}
                                overlay={
                                    <Tooltip id={`tooltip-edit-${contactPerson.id}`}>
                                        <p style={{margin: '3px'}}>Muuda kliendi andmeid</p>
                                    </Tooltip>}
                                placement={"left"}>
                            <Button size="sm" variant="secondary" className="d-flex align-items-center btn-outline-icon"
                                    onClick={() => props.onCustomerEdit(contactPerson)}>
                                <i className="icon--16 my-2 icon__edit"/>
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                                delay={350}
                                overlay={
                                    <Tooltip id={`tooltip-sync-${contactPerson.id}`}>
                                        <p style={{margin: '3px'}}>Uuenda kasutaja andmeid</p>
                                    </Tooltip>}
                                placement={"left"}>
                            <Button size="sm" variant="secondary" className="d-flex align-items-center btn-outline-icon"
                                    onClick={() => props.onCustomerSync([ contactPerson.userId ])}>
                                <i className="icon--16 my-2 icon__refresh"/>
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger delay={350}
                                        overlay={
                                            <Tooltip id={`tooltip-log-in-as-${contactPerson.id}`}>
                                                <p style={{margin: '3px'}}>Sisene kasutajana</p>
                                            </Tooltip>} placement={"left"}>
                            <Button size="sm" variant="secondary" className="d-flex align-items-center btn-outline-icon"
                                    onClick={() => handleLogInAsEvent(contactPerson)}
                                    disabled={!!loggingInAsUser}
                            >
                                {
                                    loggingInAsUser === contactPerson.id ?
                                    <Spinner size="sm" animation="border" /> :
                                    <i className="icon--16 my-2 icon__login"/>
                                }
                            </Button>
                        </OverlayTrigger>
                    </div>
                </td>
            </tr>;
        }
    );

    return (
        <>
            <table className='customer-contact-persons__table table table-responsive-lg table-hover'>
                <thead>
                <tr>
                    <th></th>
                    <th>Id</th>
                    <th>Noom Id</th>
                    <th>Isikukood</th>
                    <th>Nimi</th>
                    <th>Klient</th>
                    <th>Viimane sisselogimine</th>
                    <th>Veebi ligipääs</th>
                    <th>Admin ligipääs</th>
                    <th>Tegevused</th>
                </tr>
                </thead>
                <tbody>
                    {props.isRequesting ?
                    <tr>
                        <td colSpan={10} className="spinner-cell">
                            <div className="p-4 w-100 d-flex align-items-center justify-content-center">
                                <Spinner animation="grow" />
                            </div>
                        </td>
                    </tr> :
                    contactsTableRows}
                </tbody>
                {pageResponse ? <tfoot>
                <tr>
                    <td colSpan={10}>
                        <div className="pagination-wrapper">
                            <Pagination
                                locale={locale}
                                current={pageResponse.number + 1}
                                total={pageResponse.totalElements}
                                pageSize={pageResponse.size}
                                prefixCls={"pagination"}
                                onChange={props.onPageChange}
                                showSizeChanger={false}
                                hideOnSinglePage={true}
                            />
                        </div>
                    </td>
                </tr>
                </tfoot> : null}
            </table>
        </>
    )
}